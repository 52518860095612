import {
  TEST_LIST,
} from "../../actions/testList";

const _nullTestlist = []
                 
export default (state = _nullTestlist, { type, list}) => {
  Object.freeze(state);
  switch (type) {
    case TEST_LIST:
      return list;
    default:
      return state;
  }
};