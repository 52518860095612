import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { useState, useEffect, useRef, useCallback} from "react";
import Badge from 'react-bootstrap/Badge';
import {useSelector, useDispatch} from "react-redux";
import {updateRemarks } from "../../actions/report.js"


import { updateTargetElement, resetUpdatedValue }  from "../../actions/NumberPad"


export default function CommentsRemarks(){
	const [so1, setSo1] = useState(false)
	const [so2, setSo2] = useState(false)
	const [upstream, setUpstream ] = useState(false)
	const [restored, setRestored] = useState(false)
	const [comment, setCommnet] = useState("")
	const dispatch = useDispatch()
	const report = useSelector((state) => state.report) 

	function update(){
		let robj = {}; 
		robj.so1 = document.querySelector("#so1").checked; 
		robj.so2 = document.querySelector("#so2").checked; 
		robj.upstream = document.querySelector("#upstream").checked; 
		robj.restored = document.querySelector("#restored").checked; 
		dispatch( updateRemarks(report.id, robj)).then((data, err)=>{
			console.log("Updated Remarks")
		})
	}
	/*get initial values*/
	useEffect( ()=>{
		if(report != null){
			setSo1(report.so1); 
			setSo2(report.so2); 
			setUpstream(report.upstream); 
			setRestored(report.restored); 

		}
	}, [report])

	useEffect(()=>{
		update()
	}, [so1, so2, upstream, restored])

	const redirectKeyboard = (event)=>{
		dispatch( updateTargetElement(null) ).then((data, err) =>{
			if(err){
				console.log(err)
			}
		})
	}



	return(
		<Container>
			<Row>
				<Col>
					<strong> Remarks </strong>
				</Col>
			</Row>
			<Row xs={1} sm={1} md={1} lg= {1}>
				<Col>
					<Row> 
						<Col md={4}>
							<Form.Label>Shut Off #1</Form.Label>
						</Col>
						<Col md={1}>
							
							<Form.Check 
								id="so1"
								type="radio" 
								name="shutoff1" 
								label="On" 
								checked={so1}
								onClick = {()=> setSo1(true)}
								onFocus = {redirectKeyboard}
							/>
						</Col>
						<Col md={1}>
							<Form.Check 
								id="so1_no"
								type="radio" 
								name="shutoff1" 
								label="Off" 
								checked ={!so1} 
								onClick = {()=> setSo1(false)}
								onFocus = {redirectKeyboard}
							/>
						</Col>
					</Row>
				</Col>
				<Col>
					<Row>
						<Col md = {3}>
							<Form.Label>Shut Off #2</Form.Label>
						</Col>
						<Col md ={1}>
							<Form.Check 
								id="so2"
								type="radio" 
								name="shutoff2" 
								label="On"
								checked={so2}
								onClick = {()=> setSo2(true)}
								onFocus = {redirectKeyboard}
							/>
						</Col>
						<Col>
							<Form.Check 
								id="so2_no"
								type="radio" 
								name="shutoff2" 
								label="Off"
								checked ={!so2} 
								onClick = {()=> setSo2(false)}
								onFocus = {redirectKeyboard}
							/>
						</Col>
					</Row>
				</Col>
				<Col>
					<Row>
						<Col md = {3}>
							<Form.Label>Upstream</Form.Label>
						</Col>
						<Col md ={1}>
							 <Form.Check 
								id = "upstream"
								type="radio" 
								name="upstream" 
								label="On"
								checked={upstream}
								onClick = {()=> setUpstream(true)}
								onFocus = {redirectKeyboard}
							/>
						</Col>
						<Col>
							<Form.Check 
								id="upstream_no"
								type="radio" 
								name="upstream" 
								label="Off"
								checked={!upstream}
								onClick = {()=> setUpstream(false)}
								onFocus = {redirectKeyboard}
							/>
						</Col>
					</Row>
				</Col>
				<Col>
					<Row>
						<Col md= {3}>
							<Form.Label> System Restored?</Form.Label>
						</Col>
						<Col  md ={1}>
							<Form.Check 
								id="restored"
								type="radio" 
								name="restored" 
								label="Yes"
								checked={restored}
								onClick = {()=> setRestored(true)}
								onFocus = {redirectKeyboard}
							/>
						</Col>
						<Col>
							<Form.Check 
								type="radio" 
								name="restored"
								label="No"
								checked={!restored}
								onClick = {()=> setRestored(false)}
								onFocus = {redirectKeyboard}
							/>
						</Col>
					</Row>
				</Col>
			</Row>
		</Container>
	)
}