import * as server from "../util/alternativeServer";

export const TESTERS_LIST = "TESTERS_LIST"
const receiveTesterList =(list)=> ({
	type: TESTERS_LIST, 
	list
})

export const requestTesters = () => dispatch =>{
	return new Promise( async(resolve, reject) =>{
		const response = await server.requestTesterList(); 
		const data = await response.json(); 
		if(response.ok){
			dispatch( receiveTesterList (JSON.parse(data).list) ); 
			resolve(); 
		}
	})
}