import {
  RECEIVE_ROUTE, 
  CLEAR_ROUTE
} from "../../actions/testReport";

const _nullRoute = { 
  path: null,
  orderedStops: []
}
                
export default (state = _nullRoute, { type, route }) => {
  Object.freeze(state);
  switch (type) {
    case RECEIVE_ROUTE:
      return route;
    case CLEAR_ROUTE:
      return _nullRoute; 
    default:
      return state;
  }
};