import Assembly from "./Assembly"
import Results from "./Results"
import {useSelector, useDispatch} from "react-redux";
import Modal from 'react-bootstrap/Modal';
import Badge from 'react-bootstrap/Badge';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Spinner from 'react-bootstrap/Spinner';
import { useState, useEffect, useRef, useCallback} from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import FinalResults from "./FinalResults"

import { 
	getTestReport, 
	updateSystem, updateRemarks,
	 updateDoubleCheck, updateApproved,
	  updateRepairs, updateFDoubleCheck,
	   updateReducedPressure,  updateFRP, updateXVBA, 
	   updateFXVBA, updateAG, sendTestReportToWaterDistrict, 
	   clearMeUp, 	notTested
 } from "../actions/report"

export default function SimpledDown(props){
	const [modalShow, setModalShow] = useState(true)
	const reportID = useSelector( (state) => state.report_id)
	const [whynot, setWhyNot] = useState(false)
	const report_type = useSelector((state) => state.assembly_type )
	const systemPSI = useSelector((state) => state.system_psi)
	const dmr = useSelector((state) => state.dmr)
	const check1 = useSelector((state) => state.check1_psid); 
	const check2 = useSelector((state) => state.check2_psid); 
	const final_check1 = useSelector((state) => state.final_check1); 
	const final_check2 = useSelector( (state) => state.final_check2); 

	const rv_opened = useSelector((state) => state.rv_opened)
	const rv_pressdrop = useSelector((state) => state.rv_pressdrop)
	const final_rvOpened = useSelector( (state) => state.final_rvOpened); 
	const final_rvPressdrop = useSelector( (state) => state.final_rvPressdrop )


	const air_opened = useSelector((state) => state.air_opened); 
	const check_pressdrop = useSelector((state) => state.check_pressdrop)
	const final_air_opened = useSelector((state) => state.final_air_opened)
	const final_check_pressdrop = useSelector((state) => state.final_check_pressdrop )

	const pipesize = useSelector( (state) => state.pipesize)
	const physical_separation = useSelector( (state) => state.physical_separation)
	const final_pipesize = useSelector((state) => state.final_pipesize)
	const final_physical_separation = useSelector( (state) => state.final_physical_separation )

	const [myKey, setMyKey] = useState("device")
	const dispatch = useDispatch(); 
	const [loader, setLoader] = useState(false)
	const [saving, setSaving] = useState(false)
	const [savingMessage, setSavingMessage] = useState("")
	const [reason, setReason] = useState(null)

	


	useEffect(()=>{
		if(reportID != null){
			setLoader(true)
			dispatch(getTestReport(reportID) ).then( (data, err) =>{
				setLoader(false)
			})
		}	
	}, [reportID])


	const repairs = () =>{
		return new Promise( async( resolve, reject) =>{
			let obj = {}; 
			obj.cleaned = document.querySelector("#cleaned").checked; 
			obj.repaired = document.querySelector("#repaired").checked;; 
			obj.disc =	document.querySelector("#disc").checked;; 
			obj.spring = document.querySelector("#spring").checked;
			obj.guide = document.querySelector("#guide").checked;
			obj.diaphragm = document.querySelector("#diaph").checked;
			obj.seat =	document.querySelector("#seat").checked;
			obj.o_ring = document.querySelector("#o_ring").checked;
			obj.module = document.querySelector("#module").checked;
			obj.rubber_kit = document.querySelector("#rubber_kit").checked;
			obj.air_disc =  document.querySelector("#air_disc").checked;
			obj.air_spring = document.querySelector("#air_spring").checked;
			obj.check_disc = document.querySelector("#check_disc").checked
			obj.check_spring = document.querySelector("#check_spring").checked
			obj.float = document.querySelector("#float").checked
			setSavingMessage("Saving Repairs")
			let p = await dispatch( updateRepairs( reportID, obj) )
			resolve(p)
		})
			
	}

	const sys = () =>{
		return new Promise( async( resolve, reject) =>{
			let obj = {};
			obj.psi = systemPSI;
			obj.dmr = dmr; 
			obj.pass = document.querySelector("#TEST_PASS").checked; 
			obj.fail = document.querySelector("#TEST_FAIL").checked;  
			setSavingMessage("Saving System PSI & DMR ")
			let p1 = await dispatch( updateSystem(reportID, obj) ); 
			resolve(p1)
		})
	}

	const rem = () =>{
		return new Promise( async( resolve, reject) =>{
			let myobj = {}; 
			myobj.so1 = document.querySelector("#so1").checked; 
			myobj.so2 = document.querySelector("#so2").checked; 
			myobj.upstream = document.querySelector("#upstream").checked; 
			myobj.so1_rusted = document.querySelector("#so1_rusted").checked; 
			myobj.so2_rusted = document.querySelector("#so2_rusted").checked; 
			myobj.upstream_rusted = document.querySelector("#upstream_rusted").checked; 
			myobj.restored = document.querySelector("#restored").checked; 
			myobj.comment = document.querySelector("#comment").value;
			setSavingMessage("Saving Shutoffs") 
			let  p = await  dispatch(updateRemarks(reportID, myobj))
			resolve(p)
		})
	}


	const dc = () =>{
		return new Promise( async( resolve, reject) =>{
			let obj = {};
			obj.check1_psid = check1;
			if(check1 >= 1.0 ){
				obj.check1_tight = true;
				obj.check1_leaked = false; 
			}else{
				obj.check1_tight = false;
				obj.check1_leaked = true; 
			}
				
			obj.check2_psid = check2;
			if(check2 >= 1.0 ){
				obj.check2_tight = true;
				obj.check2_leaked = false; 
			}else{
				obj.check2_tight = false;
				obj.check2_leaked = true; 
			} 
			obj.check1_typeII = document.querySelector("#typeII").checked;
			setSavingMessage("Saving double check initial results")
			let p = await dispatch( updateDoubleCheck(reportID, obj) )
			resolve(p)	
		})
	}

	const finalDC = () =>{
		return new Promise( async( resolve, reject) =>{
			let obj = {};
			obj.check1_psid = final_check1;

			if(final_check1 < 1.0 ){
				obj.check1_tight = false;
				obj.check1_leaked = true; 
			}else{
				obj.check1_tight = true;
				obj.check1_leaked = false; 
			}
				
			obj.check2_psid = final_check2;
			if(final_check2 < 1.0 ){
				obj.check1_tight = false;
				obj.check1_leaked = true; 
			}else{
				obj.check1_tight = true;
				obj.check1_leaked = false; 
			} 
			obj.pass = document.querySelector("#final_pass").checked;
			obj.fail = document.querySelector("#final_fail").checked; 
			setSavingMessage("Saving final test  results")
			let p = await dispatch( updateFDoubleCheck(reportID, obj) )
			resolve(p)	
		})
	} 

	const finalRP = () =>{
		return new Promise( async( resolve, reject) =>{
			let obj = {};
			obj.opened_at = final_rvOpened; 
			obj.pressdrop = final_rvPressdrop; 

			obj.check1_tight  = document.querySelector("#final_rp_check1_tight").checked
			obj.check2_tight = document.querySelector("#final_rp_check2_tight").checked
			if(final_rvOpened >= 2.0 && final_rvPressdrop >= 5.0){
				obj.relief_pass = true; 
				obj.relief_fail = false; 
			}else{
				obj.relief_pass = false; 
				obj.relief_fail = true; 
			}

			obj.pass = document.querySelector("#final_pass").checked;
			obj.fail = document.querySelector("#final_fail").checked; 
			setSavingMessage("Saving final test  results")
			let p = await dispatch( updateFRP(reportID, obj) )
			resolve(p)	
		})

	}

	const rp = () =>{
		return new Promise( async( resolve, reject) =>{
			let obj = {};
			obj.openedAt = rv_opened; 
			obj.pressdrop = rv_pressdrop; 
			if(rv_opened != null && rv_pressdrop != null){
				if( rv_opened >= 2.0 && rv_pressdrop >= 5.0){
					obj.relief_pass = true;
					obj.relief_fail = false;  
				}else{
					obj.relief_pass = false;
					obj.relief_fail = true; 
				}
			}else{
				obj.relief_pass = false;
					obj.relief_fail = false;
			}

			obj.check1_tight = document.querySelector("#rp_check1_tight").checked; 
			obj.check1_leaked = document.querySelector("#rp_check1_leaked").checked; 
			obj.check2_tight = document.querySelector("#rp_check2_tight").checked; 
			obj.check2_leaked = document.querySelector("#rp_check2_leaked").checked; 

				
			setSavingMessage("Saving relief pressure results")
			let p = await dispatch( updateReducedPressure(reportID, obj) )
			resolve(p)	
		})
	}

	const xvba = () =>{
		return new Promise( async( resolve, reject) =>{
			let obj = {};
			obj.opened_at = air_opened; 
			obj.check_pressdrop = Number( check_pressdrop ); 
			if(check_pressdrop >= 1.0){
				obj.check_failed = false
			}else{
				obj.check_failed = true 				
			}
			obj.opened_fully = document.querySelector("#air_opened_fully").checked; 
			obj.did_not_open = document.querySelector("#air_did_not_open").checked; 
			let p = await dispatch( updateXVBA(reportID, obj) )
			resolve(p)	
		})
	}
	const finalXVBA = ()=>{

		return new Promise( async( resolve, reject) =>{
			let obj = {};
			obj.opened_at = final_air_opened; 
			obj.pressdrop = final_check_pressdrop; 
			if(final_check_pressdrop >= 1.0){
				obj.check_pass = true
				obj.check_fail = false
			}else{
				obj.check_fail = true
				obj.check_pass = false
			}
			obj.open_fully = document.querySelector("#final_air_open_fully").checked; 
			obj.pass = document.querySelector("#final_pass").checked;
			obj.fail = document.querySelector("#final_fail").checked; 
			setSavingMessage("Saving final test  results")
			let p = await dispatch( updateFXVBA(reportID, obj) )
			resolve(p)	
		})


	}
	const ag = () =>{
		return new Promise( async( resolve, reject) =>{
			let obj = {};
			obj.pipesize = pipesize;
			obj.physical_separation = physical_separation; 
			setSavingMessage("Saving final test  results")
			let p = await dispatch( updateAG(reportID, obj) )
			resolve(p)	
		})
	}
	const finalAG = ()=> {
		return new Promise((resolve, reject) => {
			resolve()
		})
	}

	const appr = () =>{
		return new Promise( async( resolve, reject) =>{
			let myobj = {}; 
			myobj.approved_assembly = document.querySelector("#ap_assembly").checked; 
			myobj.approved_installation = document.querySelector("#ap_installation").checked; 
			myobj.approved_orientation = document.querySelector("#ap_orientation").checked; 
			myobj.approved_airgap = document.querySelector("#ap_airgap").checked; 
			myobj.approved_usc = document.querySelector("#ap_usc").checked
			setSavingMessage("Saving Approved")
			let p = await dispatch(updateApproved(reportID, myobj))
			resolve(p)
		})
	}
	function updateFinal(){
		return new Promise(async(resolve, reject) =>{
			let rr =  document.querySelector("#repaired")
			let cc = document.querySelector("#cleaned")

			if(rr != null && cc != null){
				let  p1 = await repairs(); 

				let p2 = null; 
				let p3 = null; 

				switch(report_type){
					case 'DC': 
					case 'DCDA': 
					case 'DCDAII': 
						p2 = await finalDC()
						break; 
					case 'RP': 
					case 'RPDA': 
					case 'RPDAII':
						p2 = await finalRP(); 
						break; 
					case 'PVB': 
					case 'SVB': 
					case 'AVB': 
						p2 = await finalXVBA(); 
						break; 
					case 'AG': 
						p2=  await finalAG(); 
						break; 
					default: 
						console.log("No report type available")
				}

				Promise.all([p1, p2, p3]).then((data, err)=>{
					resolve()
				})

			}else{
				resolve()
			}
				
		})
	}

	function updateInitial(){
		return new Promise( async(resolve, reject) =>{
			let p1 = sys(); 
			let p2 = rem(); 
			let p3 = null; 
			let p5 = null; 
			switch(report_type){
				case 'DC': 
				case 'DCDA': 
				case 'DCDAII': 
					p3 = await dc()
					break; 
				case 'RP': 
				case 'RPDA': 
				case 'RPDAII':
					p5 = await dc(); 
					p3 = await rp(); 
					break; 
				case 'PVB': 
				case 'SVB': 
				case 'AVB': 
					p3 = await xvba(); 
					break; 
				case 'AG': 
					p3=  await ag(); 
					break; 
				default: 
					console.log("No report type available")

			}
			let p4 = await appr(); 
			Promise.all([p1, p2, p3, p4, p5]).then((data, err) =>{
				resolve()
			})
		})
	}
	const assemblyType = useSelector((state) => state.assembly_type )

	const markTestReady = () => {
		return new Promise( (resolve, reject) => { 
			sendToWaterDistrict().then(()=>{
				console.log("awesome pawsome")
				resolve()
			})
				
		})
	}

	const [needPassFailAlert, setNeedPassFailAlert] = useState(false)

	function sendToWaterDistrict(){
		return new Promise( (resolve, reject) =>{
			if(!document.querySelector("#TEST_PASS").checked && !document.querySelector("#TEST_FAIL").checked) {
				setNeedPassFailAlert(true)	
			}else{
				dispatch( sendTestReportToWaterDistrict(reportID) ).then(
					resolve()
				)
			}	
		})
	}

	 const markAsNotTested = (event) => {
	 	
	 		console.log("Trying this form...")
	 		const form = event.currentTarget; 
	 		if(form.checkValidity() === false){
	 			event.stopPropagation();
	 			event.preventDefault(); 
	 		}else{
	 			event.preventDefault(); 
	 			dispatch( notTested(reportID, reason) ).then( async() =>{

		 			if(assemblyType != null){
							let init = await updateInitial()
							let final = await updateFinal()
							Promise.all([init, final]).then((data, err) =>{
								if(err){
									console.log(err)
								}else{
									dispatch( clearMeUp() ).then((data, err) =>{
													setSaving(false)
													setModalShow(false)
													props.closeRegular()
													props.refreshServiceList()
													setSavingMessage("Done saving!")
													/*clear values*/
												})
								}
							})
					}else{
							dispatch( clearMeUp() ).then((data, err) =>{
													setSaving(false)
													setModalShow(false)
													props.closeRegular()
													props.refreshServiceList()
													setSavingMessage("Done saving!")
													/*clear values*/
												})
					}		
				})
	 		}
	 	
	 		
	 	
	}


	return(
		<>
		
		<Modal show ={modalShow} fullscreen>
			<Modal.Header closeButton onHide= { 
				async () => {
						setSaving(true)
						if(assemblyType != null   && assemblyType != 'NON'){
							let init = await updateInitial()
							let final = await updateFinal()
							Promise.all([init, final]).then((data, err) =>{
								if(err){
									console.log(err)
								}else{
									dispatch( clearMeUp() ).then((data, err) =>{
													setSaving(false)
													setModalShow(false)
													props.closeRegular()
													props.refreshServiceList()
													setSavingMessage("Done saving!")
													/*clear values*/
												})
								}
							})

						}else{
							dispatch( clearMeUp() ).then((data, err) =>{
													setSaving(false)
													setModalShow(false)
													props.closeRegular()
													props.refreshServiceList()
													setSavingMessage("Done saving!")
													/*clear values*/
												})
						}		
				}
							
			} >
				{
					saving  ? 
						<>
							<Spinner animation = "grow"/>
							<Badge bg ="dark"> {savingMessage}</Badge>
						</>
					: 
						<> </>

				}


			</Modal.Header>

			<Modal.Body>
					
					<Modal show = {whynot} centered backdrop = 'static' size = 'xl'>
						<Modal.Header closeButton onHide = {
							()=>setWhyNot(false) 
						}>
							<Modal.Title> Not Tested </Modal.Title>
						</Modal.Header>
						<Form  onSubmit = {markAsNotTested}>
							<Modal.Body >
								<Form.Label> Why was this assembly not tested? <br/>
								<small>This will help us determine if we need to reschedule or reach out back to the client </small></Form.Label>
								<Form.Control
									type="text"
									value={reason}
									onChange = {(event)=>setReason(event.target.value) }
									required
								 />
							</Modal.Body>
							<Modal.Footer>
								<Button type="submit"> Set As Not Tested</Button>
							</Modal.Footer>
						</Form>
					</Modal>
				
					<Tabs fill justify defaultActiveKey = {myKey} onSelect = { (key) => setMyKey(key)} mountOnEnter = {false} unmountOnExit = {false}> 

						<Tab eventKey="device" title="DEVICE" mountOnEnter = {false} unmountOnExit = {false}>
							<Assembly  />						
						</Tab>
						<Tab eventKey="initial" title="INITIAL"   mountOnEnter = {false} unmountOnExit = {false} >
							{
								loader ? 
									<Spinner />
								: 
									
									<Results />	
							}

							
						</Tab>

						<Tab eventKey="final" title="FINAL" mountOnEnter = {false} unmountOnExit = {false}>
							<FinalResults />
						</Tab>
					</Tabs>

				
			
						
			</Modal.Body>
			<Modal.Footer>
				<Alert show={needPassFailAlert} variant = "danger">
			Please select a pass or fail
		</Alert>


				<ButtonGroup>
					<Button variant = "danger"
						onClick = { () => setWhyNot(true) }


					>Could Not Test</Button>
					<Button variant = "success"
						onClick = {
							async () =>{
								
								if(assemblyType != null){
									
									markTestReady().then( async (data, err)=>{
										setSaving(true)

										let init = await updateInitial()
										let final = await updateFinal()

										Promise.all([init, final]).then((data, err) =>{
											if(err){
												console.log(err)
											}else{
												dispatch( clearMeUp() ).then((data, err) =>{
													setSaving(false)
													setModalShow(false)
													props.closeRegular()
													props.refreshServiceList()
													setSavingMessage("Done saving!")
													/*clear values*/
												})
													
											}
										})
									})

								}else{
									dispatch( clearMeUp() ).then((data, err) =>{
													setSaving(false)
													setModalShow(false)
													props.closeRegular()
													props.refreshServiceList()
													setSavingMessage("Done saving!")
													/*clear values*/
												})
								}		
							}
						}
					> Send To Water District</Button>
				</ButtonGroup>
			</Modal.Footer>
		</Modal>
		</>
	)
}