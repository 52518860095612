import BillingCard from "./BillingCard"; 
import ServiceAddressCard from "./ServiceAddressCard"; 
import LineItemDisplay from "./LineItemDisplay"; 
import InvoiceInformation from "./InvoiceInformation"
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Table from 'react-bootstrap/Table'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import NewItem from "./NewItem"
import SendInvoice from "./SendInvoice"; 
import VoidInvoice from "./VoidInvoice"; 
import { useState, useEffect, useRef, useCallback} from "react";
import {useSelector, useDispatch} from "react-redux";
import { getInvoiceInformation, requestInvoiceItems, requestBillingAddress } from "../actions/invoice"


export default function Invoice(props){
	const lineItemList = useSelector( (state) => state.invoiceItems); 
	const invoice = useSelector(state => state.invoice); 
	const [loadingItems, setLoadingItems] = useState(true);
	const [billingAddressLoading, setBillingAddressLoading] = useState(true); 
	const [totalSpinner, setTotalSpinner] = useState(true); 
	const [modal, setModal] = useState(false) 
	const [modalType, setModalType] = useState("")
	const [modalBody, setModalBody] = useState(<Spinner />); 
	const [total, setTotal] = useState(50)
	const dispatch = useDispatch(); 
	useEffect(() =>{
		if(invoice != null){

			setLoadingItems(true); 
			console.log("requesting line items for ...", invoice.id); 
			dispatch( requestInvoiceItems(invoice.id)).then((data, err) =>{
				console.log("done getting line items... ")
				setLoadingItems(false); 
			})

			//get billing address information 
			setBillingAddressLoading(true)
			dispatch( requestBillingAddress( invoice.billingDetailID) ).then( (data, err)=>{
				setBillingAddressLoading(false)
			})
		}
				
	}, [])

	useEffect(() =>{
		setTotalSpinner(true); 
		let mySum  = 0;
		for(let i = 0; i < lineItemList.length;  i++){
			console.log("line item: ", lineItemList[i])
			mySum = mySum  + lineItemList[i].amount; 
		}
		setTotal(mySum)
		setTotalSpinner(false); 
	}, [lineItemList])


	useEffect(()=>{
		switch(modalType){
			case "NEWITEM": 
				setModalBody( <NewItem 
						invoiceID = { invoice.id} 
						customerID = { invoice.customerID}
						closeModal = { ()=> { 
							setModal(false)
							setModalType("")
						} }
					/>)
				setModal(true)
				break;
			case "SEND":
				setModalBody(<SendInvoice closeModal = { ()=> { 
							setModal(false)
							setModalType("")
							props.closeModal(); 
						}} type={props.type}/>); 
				setModal(true)
				break; 
			case "VOID": 
				setModalBody( <VoidInvoice  closeModal = { ()=> { 
							setModal(false)
							setModalType("")
							props.closeModal(); 
						} } type={props.type}/>); 
				setModal(true)
				break; 
			case "": 
				setModal(false); 
				setModalBody(<Spinner />)
				break; 
			default: 
				break; 
		}
	}, [modalType])

	return(
		<>
		<Modal show = {modal} centered static>
			<Modal.Header closeButton onHide = { ()=> { setModal(false); setModalBody(<Spinner/>); setModalType("") }}>
			</Modal.Header>
			<Modal.Body>
				{modalBody}
			</Modal.Body>
		</Modal>

		<Card>
			<Card.Header>
				<Row>
					<Col>
						<InvoiceInformation />
					</Col>
					<Col>
						{
							billingAddressLoading ?
								<Spinner />
							:
								<BillingCard />
						}
					</Col>
					<Col>
						<ServiceAddressCard stop = { invoice } />
					</Col>
				</Row>
			</Card.Header>
			<Card.Body>
					<Table>
							<thead>
									<tr>
											<th> Description </th>
											<th> Quantity </th>
											<th> Unit Price </th>
											<th> Total </th>
											<th> </th>
									</tr>
							</thead>
							<tbody>
									{
										loadingItems ? 
											<Spinner />
										:
											<LineItemDisplay />
									}
									
									<tr>
										<td></td>
										<td></td>
										<td >
											<strong>Total </strong> 
										</td>
										<td>
											{
												totalSpinner ? 
													<Spinner />
												:
													<> ${total}</>
											}
												
											
										</td>
									</tr>
							</tbody>
					</Table>
			</Card.Body>
			<Card.Footer>
					
					<ButtonGroup>
						<Row>
							<Col>
								<Button variant = "secondary" onClick = { ()=>setModalType("NEWITEM")}>New item </Button>
							</Col>
							<Col>
								<Button variant = "success" onClick = { ()=>setModalType("SEND")}> Send Invoice </Button>
							</Col>	
							<Col>
								<Button variant ="danger" onClick = { ()=>{setModalType("VOID")}}> Void Invoice </Button>
							</Col>					
						</Row>
					</ButtonGroup>
				</Card.Footer>
		</Card>
	</>
	)
}