import * as server from "../util/alternativeServer";

export const TEST_LIST = "TEST_LIST";
export const UPDATE_DATE = "UPDATE_DATE"; 

const receiveDate = (date) => ({
	type: UPDATE_DATE, 
	date
})
const receiveTestList = (list) =>({
	type: TEST_LIST, 
	list
}) 

export const requestTestList = (date) => dispatch =>{
	return new Promise( async (resolve, reject) =>{
		const response = await server.requestStopList(date); 
		const data = await response.json(); 
		if(response.ok){
			dispatch( receiveDate(date) ); 
			dispatch( receiveTestList(JSON.parse(data).list))
			resolve(true)
		}
	})
}

export const requestPlumbingList = (date) => dispatch =>{
	return new Promise( async (resolve, reject) =>{
		const response = await server.requestPlumbingList(date); 
		const data = await response.json(); 
		if(response.ok){
			dispatch( receiveDate(date) ); 
			dispatch( receiveTestList(JSON.parse(data).list))
			resolve(true)
		}
	})
}