import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Alert from 'react-bootstrap/Alert';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useState, useEffect, useRef, useCallback} from "react";
import {useSelector, useDispatch} from "react-redux";
import Draggable from 'react-draggable';
import { pendingUpdatedValue } from "../actions/NumberPad"

export default function NumberPad(){
	const targetElement = useSelector((state) => state.numberPad)
	const dispatch = useDispatch(); 	
	const grabValue = (event) => {
		console.log("TARGET ELEMENT: ", targetElement)
		if ("vibrate" in navigator) {
			// vibration API supported
  			navigator.vibrate(1000);
		}
		if(targetElement != null ){
			let myElement = document.querySelector(targetElement); 
			if(event.target.id == "CL"){
				myElement.value = ""
			}else if( event.target.id == "."){
				//nothing 	
				if ( myElement.value.includes(".") ){
					//nothing
				}else{
					if(targetElement != "#dmr"){
						let newString = String(myElement.value) + String(event.target.id); 
						myElement.value = newString.replace(/^0+/ , "");
					}else{
						let newString = String(myElement.value) + String(event.target.id);
						myElement.value = newString
					}
				}
			}
			else{
				if ( myElement.value.includes(".") ){
					//check length after the period 
					//get the index of the element where the period is stored 
					let periodIndex = myElement.value.indexOf(".");
					//compare to the length of string 
					if(myElement.value.length  - periodIndex <= 2){
						myElement.value = String(myElement.value) + String(event.target.id)
					}
				}else{
					//console.log("setting this value: ", event.target.id); 
					let newString = String(myElement.value) + String(event.target.id); 
					if(targetElement != "#dmr"){
						myElement.value = newString.replace(/^0+/ , "");
					}else{
						let newString = String(myElement.value) + String(event.target.id);
						myElement.value = newString
					}
				
				}
				
			}
		}
		dispatch( pendingUpdatedValue() )
	}	

	return(
		
		<Container fluid id="numberPad">
			<Row  >
				<ButtonGroup vertical>
					<ButtonGroup>
						<Button onClick= { grabValue } bsPrefix="button-pad" id="1" >1 </Button>
						<Button onClick= { grabValue } bsPrefix="button-pad" id="2" >2</Button>
						<Button onClick= { grabValue } bsPrefix="button-pad" id="3" >3 </Button>
					
						<Button onClick= { grabValue } bsPrefix="button-pad" id="4" >4 </Button>
						<Button onClick= { grabValue } bsPrefix="button-pad" id="5" >5</Button>
						<Button onClick= { grabValue } bsPrefix="button-pad" id="6" >6 </Button>
					</ButtonGroup>
					<ButtonGroup>
						<Button onClick= { grabValue } bsPrefix="button-pad" id="7" >7 </Button>
						<Button onClick= { grabValue } bsPrefix="button-pad" id="8" >8</Button>
						<Button onClick= { grabValue } bsPrefix="button-pad" id="9" >9 </Button>
				
						<Button onClick= { grabValue } bsPrefix="button-pad" id="0" >0 </Button>
						<Button onClick= { grabValue } bsPrefix="button-pad" id="." >.</Button>
						<Button onClick= { grabValue } bsPrefix="button-pad" id="CL" >CL </Button>
					</ButtonGroup>
				</ButtonGroup>
			</Row>
		</Container>

	); 
}