import * as apiUtil from "../util/server";

import { RECEIVE_CURRENT_USER} from "./session.js"; 

export const RECEIVE_TEST_REPORT = "RECEIVE_TEST_REPORT"; 
export const FAILED_TEST_REPORT = "FAILED_TEST_REPORT"; 
export const CLEAR_TEST_REPORT = "CLEAR_TEST_REPORT";

export const RECEIVE_ROUTE = "RECEIVE_ROUTE"; 
export const CLEAR_ROUTE = "CLEAR_ROUTE"

export const GET_MESSAGE = "GET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";  


const receiveTestReport = (testReport)=>({
	type: RECEIVE_TEST_REPORT, 
	testReport
})

const failedTestReport = ()=>({
	type: FAILED_TEST_REPORT
})
const clearTestReport = () =>({
	type: CLEAR_TEST_REPORT
})

const receiveUser = user =>({
	type: RECEIVE_CURRENT_USER, 
	user
})

const getErrorMessage= message => ({
	type: GET_MESSAGE, 
	message
})

const clearErrorMessage = () =>({
	type: CLEAR_MESSAGE
})

const getRoute = (route) =>({
	type: RECEIVE_ROUTE, 
	route
}) 


export const clearErrors = () => async dispatch => {
	return dispatch(clearErrorMessage()); 
}
export const getTestReport = (testReportID) => async dispatch =>{
	
	return new Promise (async (resolve, reject) =>{
		console.log("Getting this test report information: ", testReportID); 
		const response = await apiUtil.getTestReport(testReportID); 
		const data  = await response.json(); 
		if(response.ok){
			resolve( dispatch( receiveTestReport(JSON.parse( data) ) ) );  
		}else{
			reject(  dispatch( failedTestReport()) ); 
		}
	})
} 

export const getTestReportRepair = (testReportID) => async dispatch =>{
	return new Promise (async (resolve, reject) =>{
		console.log("Getting this test report information: ", testReportID); 
		const response = await apiUtil.getTestReportRepair(testReportID); 
		const data  = await response.json(); 

		if(response.ok){
			
			resolve(dispatch( receiveTestReport( JSON.parse(data))))
		}else{
			reject( dispatch ( failedTestReport() ) ); 
		}
	})

}

export const submitTestReport = (testReport, type, id) => async dispatch => {
	
	return new Promise ( async (resolve, reject) => {
		
		console.log("Submitting this test report: ", testReport, type, id)
		let obj = {
			type: type, 
			testReportID: id, 
			testReport: testReport
		}
		
		const response =  await apiUtil.updateTestReport(obj); 
		const data = await response.json(); 
		
		if(response.ok){
			if(JSON.parse(data).success){
				dispatch( receiveUser(JSON.parse(data).session) ); 
				dispatch( receiveTestReport( JSON.parse(data).testReport) )
				resolve(true)
			}else{
				resolve(false)
			}
			
		}else{
			reject( dispatch ( failedTestReport() ) ); 
		}
	})	
}

export const submitTestReportRepair = (testReport, type, id) => async dispatch => {
	return new Promise ( async (resolve, reject) => {
		console.log("Submitting this test report: ", testReport, type, id)
		let obj = {
			type: type, 
			testReportID: id, 
			testReport: testReport
		}
		const response =  await apiUtil.submitTestReportRepair(obj); 
		const data = await response.json(); 

		if(response.ok){
			dispatch( receiveUser(JSON.parse(data).session) ); 
			resolve(dispatch( receiveTestReport( JSON.parse(data).testReport)))
		}else{
			reject( dispatch ( failedTestReport() ) ); 
		}
	})	

}

export const clearTestReportSlice = () => async dispatch => {
	return dispatch( clearTestReport() ); 
}


export const cancelJob = (testReportID, notesObj)=> async dispatch =>{
	return new Promise( async (resolve, reject)=>{
		let obj = { testReportID: testReportID, notes: notesObj.notes}; 
		const response = await apiUtil.cancelTest(obj); 
		const data = await response.json(); 
		if( response.ok){
			if(JSON.parse(data).success){
				resolve(dispatch(receiveUser(JSON.parse(data).session))); 
			}else{
				
				reject( dispatch( getErrorMessage( JSON.parse(data).message) ) ) ; 
			}
			
		}else{
			reject( dispatch(getErrorMessage("Something went wrong. Please try again") ) ); 
		}

	})
}
export const updateTestReport = (reportID, reportObj) => async dispatch =>{
	const response  = await apiUtil.updateTestReport(reportID, reportObj); 
	const data = await response.json(); 

	if(response.ok){
		return dispatch(); 
	}else{
		return dispatch(); 
	}
}
export const requestRoute = ()  => async dispatch  => {
	return new Promise( async(resolve, reject) =>{
		const response = await apiUtil.getCodedRoute(); 
		const data = await response.json(); 
		if(response.ok){
			console.log("DATA!:" , data, JSON.parse(data).route[0].polyline.encodedPolyline)
			let obj = { path: JSON.parse(data).route[0].polyline.encodedPolyline, orderedStops: JSON.parse(data).orderedStops }
			dispatch( getRoute( obj))
			resolve(JSON.parse(data).route[0].polyline.encodedPolyline) 
		}
	})
}

