import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Table from 'react-bootstrap/Table'
import { useState, useEffect, useRef, useCallback} from "react";
import {useSelector, useDispatch} from "react-redux";

import { getAddressDetails, getCustomerDetails, getServices  } from "../actions/Address"

export default function ServiceAddressCard(props){
	const dispatch = useDispatch(); 
	const [addressLoader, setAddressLoader] = useState(true); 
	const [addr, setAddr] = useState(null); 
	const [locationName, setLocationName] = useState(""); 
	const [street, setStreet] = useState(""); 
	const [city, setCity] = useState(""); 
	const [state, setState] = useState(""); 
	const [zipcode, setZipcode] = useState(""); 
	const [googleAddressLink, setGoogleAddressLink] = useState("")
	const [appleAddressLink, setAppleAddressLink] = useState("")
 	useEffect(()=>{
		console.log("Service Address: ", props.stop.serviceAddressID);
		setAddressLoader(true) 
		getAddressDetails(props.stop.serviceAddressID).then( (data, err) =>{
			setAddr(data); 
			setAddressLoader(false)
		})
	}, [])

	useEffect(()=>{
		if(addr != null){
			setLocationName(addr.location_name)
			setStreet(addr.street); 
			setCity(addr.city); 
			setState(addr.state); 
			setZipcode(addr.zipcode); 
			setGoogleAddressLink(`http://maps.google.com/?q=${encodeURIComponent(`${addr.street}, ${addr.city}, ${addr.state}, ${addr.zipcode}`)}`)
			setAppleAddressLink(`http://maps.apple.com/?q=${encodeURIComponent(`${addr.street}, ${addr.city}, ${addr.state}, ${addr.zipcode}`)}`)
		}
	}, [addr])

	return(
		<> 
			
			<p>
				<strong> SERVICE ADDRESS: </strong><br/>
					{locationName} <br/>
					{street} <br/>
					{city}, {state} <br/> {zipcode} 

			</p>
			<a href={googleAddressLink} >Google Maps</a> <br/>
			<a href={appleAddressLink} >Apple Maps</a>
		</>
	)
}