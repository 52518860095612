 import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table'
import {useSelector, useDispatch} from "react-redux";
import { getInvoiceInformation, requestInvoiceItems, deleteInvoiceItem } from "../actions/invoice"
import { useState, useEffect, useRef, useCallback} from "react";


export default function LineItemDisplay(){
	const list = useSelector( (state)=> state.invoiceItems); 
	const dispatch = useDispatch(); 
	const [load, setLoad] = useState(false); 
	function deleteLineItem(event, id){
		console.log("deleting this item: ", event.target.id)
		setLoad(true)
		dispatch( deleteInvoiceItem( event.target.id)) .then( (data, err) =>{
			setLoad(false)
			console.log("done!"); 
		})
	}
	const renderList = list.map( (item) =>{
		let myid = item.id
		return(
			<tr>
					<td>		
							<p>{item.description}
							<br/>{item.itemDescription}
							</p>
					</td>
					<td>
							{item.quantity}
					</td>
					<td>
							${item.unitPriceDefined}
					</td>
					<td>
							${item.amount}
					</td>
					<td>	
						{
							load ? 
								<Spinner />
							:
							<Button id = {myid} variant="light" onClick = { ( event )=>{ deleteLineItem(event )} }> X</Button>
						}	
							
					</td>
			</tr>
		)
	}) 

	return(
		<>
			{renderList}
		</>
	)
}