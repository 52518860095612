import StopCard from "./StopCard"
import Spinner from "react-bootstrap/Spinner"
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';
import Pagination from 'react-bootstrap/Pagination'
import { useState, useEffect, useRef, useCallback} from "react";
import {useSelector, useDispatch} from "react-redux";
import { format } from "date-fns"
import { requestTestList } from "../actions/testList"

export default function StopsDisplay(){
	const stoplist = useSelector( (state) => state.testlist )
	const dispatch = useDispatch(); 
	//request the list of stops for the givenndate
	const [pagesTotal, setPagesTotal] = useState(0);
	const [active, setActive] = useState(1); 
	const [toShowTotal, setToShowTotal] = useState(stoplist.length); 
	const [startingIndex, setStartingIndex] = useState(0); 
	const [endingIndex, setEndingIndex] = useState(3); 
	const [dateSelected, setDateSelected] = useState(format( new Date(), "yyyy-MM-dd"))
	const [loading, setLoading]  = useState(false)
	
	useEffect(()=>{
		setLoading(true)
		dispatch( requestTestList(dateSelected) ).then( (data, err) =>{
			console.log("We got an updated list!")
			setLoading(false)
		})  
	}, [dateSelected])

	useEffect( ()=>{
		console.log("new current", active)
		let start =  (active * toShowTotal) - toShowTotal;
		let end =  (active * toShowTotal) -1 ; 
		console.log("currents, start, end", active, start, end)
		setStartingIndex( start ); 
		setEndingIndex( end);   
	}, [active])

	//determine how many pages to show
	useEffect(()=>{
		let division = Math.ceil(stoplist.length / toShowTotal ); 
		setPagesTotal(division); 
	}, [stoplist])

	let renderstops = stoplist.map((stop, index)=>{
		return(
			<StopCard index= {index} stop ={stop} date = {dateSelected}/>
		)
	})


	return(
		<>
			<Form.Group>
				<Form.Label>Select a date: </Form.Label>
				<Form.Control type="date" 
				value={dateSelected}
				onChange = {(event) => setDateSelected(event.target.value)}
				/>
			</Form.Group>

		
				{
					loading ?
						<Spinner />

					: 
						<> 
							<Accordion>
								{renderstops} 
								
								
							</Accordion>
						</>

				}
				
		
			
		</>
	)
}