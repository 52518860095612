import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import Col from 'react-bootstrap/Col';
import { useState, useEffect, useRef, useCallback} from "react";
import Badge from 'react-bootstrap/Badge';
import {useSelector, useDispatch} from "react-redux";
import {updateAirgap } from "../../actions/AlternativeTestReport.js"
import { Link } from 'react-router-dom'
import { updateTargetElement, resetUpdatedValue }  from "../../actions/NumberPad"
import {updateAG} from "../../actions/report"

export default function AG(){
	const dispatch  = useDispatch() 
	const report = useSelector((state) => state.report) 
	const currentPadTarget = useSelector((state) => state.numberPad)
	const updatedValue = useSelector((state) => state.updatedVal) 
	const [testValue, setTestValue] = useState(null);
	const [testReportID, setTestReportID] = useState(null); 
	const [modalSpinner, setModalSpinner] = useState(false); 
	/*value variables*/
	const [pipesize, setPipesize] = useState(null); 
	const[physicalSeparation, setPhysicalSeparation] = useState(null)

	/*set initial values*/
	useEffect( () =>{
		if(report != null){
			setPipesize(report.pipesize); 
			setPhysicalSeparation(report.physical_separation); 
			setTestReportID(report.id)
			/*also html elements*/
			document.querySelector("#pipesize").value = report.pipesize; 
			document.querySelector("#physical").value = report.physical_separation; 
		}
	}, [report])


	useEffect(()=>{
		if(updatedValue){
			setPipesize(document.querySelector("#pipesize").value)
			setPhysicalSeparation(document.querySelector("#physical").value)
			dispatch( resetUpdatedValue())
		}
	}, [updatedValue])

	/*validation variable*/
	const [hasPipesize, setHasPipeSize] = useState(false); 
	const [hasPhysicalSeparation, setHasPhysicalSeparation] = useState(false); 
	/*validation checker*/
	useEffect(()=>{
		if(pipesize == null || pipesize == 0.0){
			setHasPipeSize(false)
		}else{
			setHasPipeSize(true)
		}
		if(physicalSeparation == null || physicalSeparation == 0.0){
			setHasPhysicalSeparation(false)
		}else{
			setHasPhysicalSeparation(true)
		}
	}, [pipesize, physicalSeparation])


	function update() {
		let myResults = {}
		myResults.pipesize = document.querySelector("#pipesize").value;
		myResults.physical_separation = document.querySelector("#physical").value
		dispatch(updateAG(report.id, myResults) ).then((data, err) =>{
			console.log("updated the AG")
		})

	}

	const [watch, setWatch] = useState(false)
	/*checks if both inputs are valid to be sent to the DB*/
	useEffect(()=>{
		if(currentPadTarget == "#pipesize" || currentPadTarget == "#physical"){
			setWatch(true)
		}else{
			setWatch(false)
		}
		if(watch){
			update()
		}
	}, [currentPadTarget])

	const redirectKeyboard = (event)=>{
		dispatch( updateTargetElement(event.target.id) ).then((data, err) =>{
			if(err){
				console.log(err)
			}
		})
	}

	return(
		<>
			<Modal show={modalSpinner}>
				<Modal.Body>
					<Spinner />
				</Modal.Body>
			</Modal>
			<Row >

				<strong> Airgap </strong>
				<Col>
					
						<InputGroup>
							<InputGroup.Text>Pipe size</InputGroup.Text>
								<Form.Control 
									id="pipesize"
									size="sm" 	
									isInvalid={!hasPipesize}
									isValid = { hasPipesize}
									readOnly
									onFocus = {redirectKeyboard}
								/>	
								<InputGroup.Text>in</InputGroup.Text>
							</InputGroup>
							<br/>
							<InputGroup>

								<InputGroup.Text> Physical Separation </InputGroup.Text> 
								<Form.Control 
									size="sm"
									id="physical"
									readOnly
									isInvalid={!hasPhysicalSeparation}
									isValid = { hasPhysicalSeparation}
									onFocus = {redirectKeyboard}
								/>
								<InputGroup.Text>in</InputGroup.Text>
							</InputGroup>
						</Col>
			</Row>
		</>
	)
}