import {
  NUMBERPAD_TARGET, 
  CLEAR_TARGET
} from "../../actions/NumberPad.js";

const _nullTarget = null
                
export default (state = _nullTarget, { type, targetElement }) => {
  Object.freeze(state);
  switch (type) {
    case NUMBERPAD_TARGET:
      return targetElement;
    case CLEAR_TARGET: 
      return _nullTarget; 
    case "":
      return _nullTarget; 
    default:
      return state;
  }
};