import {
  SET_FINAL_PIPESIZE, CLEAR_VALUES
} from "../../actions/report.js";

const _nullVal = null
                
export default (state = _nullVal, { type, psid}) => {
  Object.freeze(state);
  switch (type) {
    case SET_FINAL_PIPESIZE:
      return psid;
     case CLEAR_VALUES:
      return _nullVal; 
    case "":
      return _nullVal; 
    default:
      return state;
  }
};