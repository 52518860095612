export const NUMBERPAD_TARGET = "NUMBERPAD_TARGET"
export const CLEAR_TARGET = "CLEAR_TARGET"; 
export const  VALUE_UPDATED = "VALUE_UPDATED"

const getTarget = (targetElement) => ({
	type: NUMBERPAD_TARGET, 
	targetElement
})
const clearTarget = () =>({
	type: CLEAR_TARGET
})

const resetValue = (boolVal) =>({
	type: VALUE_UPDATED, 
	boolVal
})	

export const resetUpdatedValue = () => dispatch =>{
	dispatch(resetValue(false))
}


export const pendingUpdatedValue = ()=> dispatch =>{
	dispatch( resetValue(true) )
}
export const updateTargetElement = (targetID) => dispatch => { 
	return new Promise( (resolve, reject) =>{
		try{
			if(targetID == null){
				dispatch(clearTarget() )
 				resolve()
 			}else{
				let myElement = `#${targetID}`; 
				dispatch( getTarget(myElement)); 
				resolve("no error")
			}
			
		}catch(e){
			reject(e)
		}
	})
}