import {
  CONFINED_ENTRY
} from "../../actions/Address";

const _nullConfinedEntry =  null
                
export default (state = _nullConfinedEntry, { type, entry }) => {
  Object.freeze(state);
  switch (type) {
    case CONFINED_ENTRY:
      return entry;
    case "":
      return _nullConfinedEntry; 
    default:
      return state;
  }
};