import {
  SET_CHECK2_PSID, CLEAR_VALUES
} from "../../actions/report.js";

const _nullCheck2_psid = null
                
export default (state = _nullCheck2_psid, { type, psid}) => {
  Object.freeze(state);
  switch (type) {
    case SET_CHECK2_PSID:
      return psid;
    case CLEAR_VALUES:
      return _nullCheck2_psid; 
    case "":
      return _nullCheck2_psid; 
    default:
      return state;
  }
};