import {
  SET_FINAL_RV_OPENED, CLEAR_VALUES
} from "../../actions/report.js";

const _nullPsid = null
                
export default (state = _nullPsid, { type, psid}) => {
  Object.freeze(state);
  switch (type) {
    case SET_FINAL_RV_OPENED:
      return psid;
    case CLEAR_VALUES:
      return _nullPsid; 
    case "":
      return _nullPsid; 
    default:
      return state;
  }
};