import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import StopsDisplay from "./StopsDisplay"
import PlumbingApp from "./plumbing/Landing"
import { useState, useEffect, useRef, useCallback} from "react";
import {useSelector, useDispatch} from "react-redux";

export default function DispatchApp(){
	const [appToShow, setAppToShow] = useState(<StopsDisplay />)
	const [currentKey, setCurrentKey] = useState("1"); 

	const handleSelect = (eventKey) =>{
		switch(eventKey.toUpperCase() ){
		case '1':
			setAppToShow( <StopsDisplay />)
			setCurrentKey("1")
			break; 
		case '2': 
			setAppToShow( <PlumbingApp />)
			setCurrentKey("2")
			break; 
		default: 
			setCurrentKey("1")
			break;  
		}
	}	
	return(
		<Container fluid>	
			<Row>
				
					<Tabs variant="tabs" activeKey={currentKey} onSelect={handleSelect} className="flex-row">
						<Tab eventKey="1" title="Backflow" mountOnEnter unmountOnExit>
							
						</Tab>
						<Tab eventKey="2" title="Plumbing" mountOnEnter unmountOnExit>
						</Tab>
					</Tabs>
				
			</Row>
			<Row>
				<Col>
					{appToShow}
				</Col>
			</Row>
		</Container>
	)
}