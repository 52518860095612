import Accordion from 'react-bootstrap/Accordion';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import { useState, useEffect, useRef, useCallback} from "react";
import StopDetails from "./StopDetails"
import {useSelector, useDispatch} from "react-redux";
import { getAddressDetails, getCustomerDetails, getServices, getInvoiceStatus } from "../actions/Address"
import { format } from "date-fns" 
export default function StopCard(props){
	const dispatch = useDispatch(); 
	const [addressLoader, setAddressLoader] = useState(true); 
	const [contactLoader, setContactLoader] = useState(true); 
	const [stateLoader, setStateLoaded] = useState(false)
	const [eventKey, setEventKey] = useState(props.index); 
	const [onClick, setOnClick] = useState(null); 
	const [stopLoaded, setStopLoaded] = useState(false)
	const decoratedOnClick = useAccordionButton(eventKey, onClick);
	const [addressObj, setAddressObj] = useState(null); 
	const [contactObj, setContactObj] = useState(null); 
	const [statusLoader, setStatusLoader] = useState(false); 
	const [invoiceStatus, setInvoiceStatus] = useState(""); 
	const [testerApproved, setTesterApproved] = useState(false)
	const [googleAddressLink, setGoogleAddressLink] = useState("")
	const [appleAddressLink, setAppleAddressLink] = useState("")

	function updateInvoiceStatus(){
		setStatusLoader(true);
		getInvoiceStatus(props.stop.stopID).then( (data, err) =>{
			if(err){
				console.log("error laoding contact information")
			}
			console.log("this is the status: ", data)
			setInvoiceStatus(data.status); 
			setStatusLoader(false); 
		})
	}

	useEffect( ()=>{
		setAddressLoader(true); 
		setContactLoader(true); 
		setStatusLoader(true); 
		console.log("getting address details...", props.stop)
		getAddressDetails( props.stop.addressId).then((data, err)=>{
			if(err){
				console.log("Error loading the address"); 
			}
			setAddressObj(data)
			setAddressLoader(false)
		})

		/*getCustomerDetails(props.stop.customerID).then( (data,err) =>{
			if(err){
				console.log("error laoding contact information")
			}
			setContactObj(data)
			setContactLoader(false); 
		})*/
		console.log("This is the props: ", props.stop)
		getInvoiceStatus(props.stop.stopID).then( (data, err) =>{
			if(err){
				console.log("error laoding contact information")
			}
			console.log("this is the status: ", data)
			setInvoiceStatus(data.status); 
			setStatusLoader(false); 
		})
	}, [])

	function loadStop(){
		setStopLoaded(false)
		dispatch( getServices(props.stop.stopID) )
		.then( (data, err) =>{
			if(err){
				console.log("error at retrieving assembly and test reports..."); 
			}
			setStopLoaded(true)

		})

	}
	const [timeBadge, setTimeBadge] = useState(<></>)

	useEffect( () =>{
		if(props.stop.startTime != "08:00" || props.stop.endTime != "16:00"){
			setTimeBadge(
					<Badge bg="danger">
						{ format( new Date( `1995-06-26T${props.stop.startTime}:00` ) , 'hh:mm aaaa') }  - { format( new Date( `1995-06-26T${props.stop.endTime}:00` ) , 'hh:mm aaaa') }
					</Badge>
			)
		}
	}, [])
		
	useEffect(()=>{
		if(addressObj != null){
			setGoogleAddressLink(`http://maps.google.com/?q=${encodeURIComponent(`${addressObj.street}, ${addressObj.city}, ${addressObj.state}, ${addressObj.zipcode}`)}`)
			setAppleAddressLink(`http://maps.apple.com/?q=${encodeURIComponent(`${addressObj.street}, ${addressObj.city}, ${addressObj.state}, ${addressObj.zipcode}`)}`)
		}
	}, [addressObj])

	return(
		<Accordion.Item eventKey = {props.index} >
			<Accordion.Header>
				<Container>
				<Row xs ={1}sm  = {1} md lg ={3}>
			
					<Col>
						{
							addressLoader ? 
								<Spinner />
							:
								<>
									<h6> Location </h6>
	
									<p> 
										<u>{ addressObj.location_name} </u><br/>
										{addressObj.street }, {addressObj.city} <br/>
									{addressObj.state}, {addressObj.zipcode} </p>
								
									<a href={googleAddressLink} >Google Maps</a> <br/> 
									<a href={appleAddressLink} >Apple Maps</a>
								</>
						}
						<hr/>
						
						
					</Col>
					<Col>
						<>
							<h6> Contact </h6>
							<p>
							{props.stop.requestor}<br/>
							<a href={ `tel:${props.stop.phone}`}>{props.stop.phone}</a></p>
						</>
					</Col>
					<Col>
						
						{
							statusLoader ? 
								<Spinner />
							:
								<>
									<h6> Status </h6> 
									<p>
										{invoiceStatus}
									</p>
								</>
						}
					</Col>
				</Row>
				<Row>
					<Col>
						
						<p>{props.stop.comment} </p>
						{timeBadge}

						<p> <small>Scheduled by: {props.stop.scheduler}</small></p>
					</Col>
				</Row>
				</Container>
			</Accordion.Header>
			<Accordion.Body onEnter = {(event)=>{
				loadStop()
			}}>
				{
					stopLoaded ? 
						<StopDetails stop = {props.stop} refreshInvoiceStatus = { ()=> updateInvoiceStatus() } refreshServiceList = { ()=>loadStop()} />
					: 

						<Spinner />
				}
			</Accordion.Body>
		</Accordion.Item>
	)
}