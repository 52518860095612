import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import { useState, useEffect, useRef, useCallback} from "react";
import Badge from 'react-bootstrap/Badge';
import { updateTargetElement, resetUpdatedValue }  from "../../actions/NumberPad"
import {useSelector, useDispatch} from "react-redux";
import {updateReducedPressure} from "../../actions/report"
export default function ReducedPressure(){
	const report = useSelector( (state) => state.report)
	const [modalSpinner, setModalSpinner] = useState(false)
	/*variables to redirect to the number pad*/
	const dispatch = useDispatch()
	const currentPadTarget = useSelector((state) => state.numberPad)
	const updatedValue = useSelector((state) => state.updatedVal)

	/*value variables*/
	const [pressdrop, setPressdrop] = useState(0.0); 
	const [openedAt, setOpenedAt]=useState(0.0); 
	const [pass, setPass] = useState(false)

	/*validation variables*/
	const [hasPressdrop, setHasPressdrop] = useState(false); 
	const [hasOpenedAt, setHasOpenedAt] = useState(false)

	/*get initial values*/
	useEffect(()=>{
		if(report != null){
			
			setPressdrop(report.RPA1_psid); 
			setOpenedAt(report.RPA_opened_at); 
			document.querySelector("#pressdrop").value = report.RPA1_psid; 
			document.querySelector("#openedAt").value = report.RPA_opened_at; 
		}
	},[report] )

	/*check for pass or fail */
	useEffect(()=>{
		if(pressdrop >=5 && openedAt >=2){
			setPass(true)
		}else{
			setPass(false)
		}
	}, [pressdrop, openedAt])
	/*checks if values are valid*/
	useEffect(()=>{
		if(pressdrop == null || pressdrop ==0){
			setHasPressdrop(false)
		}else{
			setHasPressdrop(true)
		}

		if(openedAt == null || openedAt==0){
			setHasOpenedAt(false)
		}else{
			setHasOpenedAt(true)
		}
	}, [pressdrop, openedAt])

	/*builds and sends objects to the database*/
	function update(){
		let myResults = {}
		myResults.pressdrop = document.querySelector("#pressdrop").value; 
		myResults.openedAt = document.querySelector("#openedAt").value; 
		if(document.querySelector("#pressdrop").value == null || document.querySelector("#openedAt").value == null){
			myResults.relief_pass = false
			myResults.relief_fail = false
		}
		else if(document.querySelector("#pressdrop").value >=5 && document.querySelector("#openedAt").value >=2){
			myResults.relief_pass = true
			myResults.relief_fail = false
		}else{
			myResults.relief_pass = false
			myResults.relief_fail = true
		}
		dispatch (updateReducedPressure(report.id, myResults) ).then((data, err) =>{
			console.log("updated reduced pressure")
		})
	}	

	/*redirects values to html elements*/
	useEffect(()=>{
		if(updatedValue){
			setPressdrop(document.querySelector("#pressdrop").value)
			setOpenedAt(document.querySelector("#openedAt").value)
			dispatch( resetUpdatedValue())
		}
	}, [updatedValue])
	const [watch, setWatch] = useState(false)
	/*checks if both inputs are valid to be sent to the DB*/
	useEffect(()=>{
		if(currentPadTarget == "#pressdrop" || currentPadTarget == "#openedAt"){
			setWatch(true)
		}else{
			setWatch(false)
		}
		if(watch){
			update()
		}
	}, [currentPadTarget])

	const redirectKeyboard = (event)=>{
		dispatch( updateTargetElement(event.target.id) ).then((data, err) =>{
			if(err){
				console.log(err)
			}
		})
	}

	return(
		<>
			<Modal show={modalSpinner}>
				<Modal.Body>
					<Spinner />
				</Modal.Body>
			</Modal>
			<Row xs={1} sm = {1} md= {2}>
				<Col>
					<strong>Relief Valve <br/></strong>
				</Col>
				<Col>		
					{
						
					pass ? 
						<Badge bg="primary">Passed</Badge>
					:
						<Badge bg="secondary"> Failed </Badge>

					}
			
				</Col>
			</Row>

			<Row>
			
				<Col>
					<Form.Label> Check #1 </Form.Label>
					<InputGroup>
					<Form.Control 
						id="pressdrop"
						required
						isInvalid = { ! hasPressdrop} 
						isValid = { hasPressdrop }
						readOnly 
						onFocus = { redirectKeyboard}
					/>
					<InputGroup.Text> psid </InputGroup.Text>
					
				</InputGroup>
				<br/>
				<Form.Label> Opened At</Form.Label>
				<InputGroup>
					
					<Form.Control 
						id="openedAt"
						required
						isInvalid = { !hasOpenedAt}
						isValid= { hasOpenedAt}
						readOnly 
						onFocus = { redirectKeyboard}
					/>
					<InputGroup.Text> psid </InputGroup.Text>
					
				</InputGroup>
				</Col>
			</Row>
		</>
	)
}