import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useState, useEffect, useRef, useCallback} from "react";
import Badge from 'react-bootstrap/Badge';
import { updateTargetElement, resetUpdatedValue }  from "../../actions/NumberPad"
import {useSelector, useDispatch} from "react-redux";
import {updateXVBA} from "../../actions/report"
export default function XVBA(){
	const report = useSelector( (state) => state.report)
	const [modalSpinner, setModalSpinner] = useState(false)
	/*variables to redirect to the number pad*/
	const dispatch = useDispatch()
	const currentPadTarget = useSelector((state) => state.numberPad)
	const updatedValue = useSelector((state) => state.updatedVal)

	const [openedFully, setOpenedFully] = useState(false)
	const [openedAt, setOpenedAt] = useState(0.0);
	const [checkPass, setCheckPass] = useState(false)
	const [pressdrop, setPressdrop] = useState(0.0);

	/*validation checker*/
	const [hasOpenedAt, setHasOpenedAt] = useState(false); 
	const [hasPressdrop, setHasPressdrop] = useState(false)

	useEffect(()=>{
		if(openedAt >= 1.0){
			setOpenedFully(true)
		}else{
			setOpenedFully(false)
		}

		if(pressdrop >= 1.0){
			setCheckPass(true)
		}else{
			setCheckPass(false)
		}
	}, [openedAt, pressdrop])

	/*check and set validation*/
	useEffect(()=>{

		if(openedAt == null || openedAt == 0){
			setHasOpenedAt(false)
		}else{
			setHasOpenedAt(true)
		}

		if(pressdrop == null || pressdrop == 0 ){
			setHasPressdrop(false)
		}else{
			setHasPressdrop(true)
		}
	}, [openedAt, pressdrop])
	/*get initial values*/
	useEffect(()=>{
		if(report != null){
			setOpenedAt(report.VB_air_opened_at); 
			setPressdrop(report.VB_check_pressdrop); 
			document.querySelector("#openedAt").value = report.VB_air_opened_at; 
			document.querySelector("#pressdrop").value = report.VB_check_pressdrop; 
		}
	},[report] )

	function update(){
		let myResults = {}
		myResults.pressdrop = document.querySelector("#pressdrop").value; 
		myResults.openedAt = document.querySelector("#openedAt").value; 
		 dispatch (updateXVBA(report.id, myResults) ).then((data, err) =>{
		 	console.log("Updated XVBA!")
		 })
	}

	/*redirects values to html elements*/
	useEffect(()=>{
		if(updatedValue){
			setOpenedAt(document.querySelector("#openedAt").value)
			setPressdrop(document.querySelector("#pressdrop").value)
			dispatch( resetUpdatedValue())
		}
	}, [updatedValue])
	
	const [watch, setWatch] = useState(false)
	/*checks if both inputs are valid to be sent to the DB*/
	useEffect(()=>{
		if(currentPadTarget == "#pressdrop" || currentPadTarget == "#openedAt"){
			setWatch(true)
		}else{
			setWatch(false)
		}
		if(watch){
			update()
		}
	}, [currentPadTarget])

	const redirectKeyboard = (event)=>{
		dispatch( updateTargetElement(event.target.id) ).then((data, err) =>{
			if(err){
				console.log(err)
			}
		})
	}

	return(
		<>
			<Modal show={modalSpinner}>
				<Modal.Body>
					<Spinner />
				</Modal.Body>
			</Modal>
			<Row>
				<Col>
					<strong>Air Inlet</strong>
				</Col>
				
			</Row>	
			<Row>
				<Col>
					{
						openedFully ?
							<Badge bg="primary"> Opened Fully </Badge>
						:
							<Badge bg="secondary"> Did Not Open </Badge>
					}
				</Col>
			</Row>
			<Row>
				<Col>
					<Form.Label> Opened At</Form.Label>
					<Form.Control 
						id ="openedAt"
						required
						isInvalid = {!hasOpenedAt}
						isValid = { hasOpenedAt}
						onFocus = { redirectKeyboard}
						size="sm"
					/>
					<br/>
				</Col>
				

			</Row>
			<Row>
				<Col>
					<strong>Check Valve</strong>
				</Col>
			</Row>
			
			<Row>
				<Col>
					<Form.Label>Pressdrop</Form.Label>
				</Col>
			</Row>
			<Row>
				<Col>
					
					<Form.Control 
						id = "pressdrop"
						required
						isInvalid = {!hasPressdrop}
						isValid = { hasPressdrop}
						onFocus = { redirectKeyboard }
						size="sm"
					/>
					<br/>
				</Col>
			
			</Row>
			<Row>
				<Col>
					{
				checkPass ?
					<Badge bg="primary"> Pass </Badge>
				:
					<Badge bg="secondary"> Failed </Badge>
				}
				</Col>
			</Row>		

		</>
	)
}