import {
  SET_ASSEMBLY_ID, CLEAR_ASSEMBLY
} from "../../actions/AlternativeDetails.js";

const _nullAssemblyID = null; 
                
export default (state = _nullAssemblyID, { type, id}) => {
  Object.freeze(state);
  switch (type) {
    case SET_ASSEMBLY_ID:
      return id;
    case CLEAR_ASSEMBLY: 
        return _nullAssemblyID; 
    case "":
      return _nullAssemblyID; 
    default:
      return state;
  }
};