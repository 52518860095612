import {
  SET_CHECK_PRESSDROP, CLEAR_VALUES
} from "../../actions/report.js";

const _nullPressdrop= null
                
export default (state = _nullPressdrop, { type, psid}) => {
  Object.freeze(state);
  switch (type) {
    case SET_CHECK_PRESSDROP:
      return psid;
    case CLEAR_VALUES:
      return _nullPressdrop; 
    case "":
      return _nullPressdrop; 
    default:
      return state;
  }
};