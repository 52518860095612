import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useState, useEffect, useRef, useCallback} from "react";
import {useSelector, useDispatch} from "react-redux";
import { sendInvoiceToOffice} from "../actions/invoice"; 
import { requestTestList, requestPlumbingList} from "../actions/testList"; 

export default function SendInvoice(props){
	const [loading, setLoading] = useState(false)
	const invoice  = useSelector( (state) => state.invoice); 
	const date = useSelector( (state) => state.selectedDate); 
	const dispatch = useDispatch(); 

	function sendThisInvoice(){
		setLoading(true); 
		dispatch( sendInvoiceToOffice(invoice.id)).then((data, err) =>{
			if(props.type == "PLUMBING"){
				dispatch( requestPlumbingList (date) ).then( (data, err) =>{
					setLoading(false); 
					props.closeModal(); 
				})
			}else{
				dispatch( requestTestList (date) ).then( (data, err) =>{
					setLoading(false); 
					props.closeModal(); 
				})
			}
				
			
		})
	}
	return(
		<>
		{
			loading ? 
				<Spinner />
			:
			<>
				<Row>
					<Col>
						Send this invoice? 
					</Col>
				</Row>
				<Modal.Footer>
					<ButtonGroup>
						<Button variant = "success" onClick = {()=> sendThisInvoice() } > Yes </Button>
						<Button variant = "secondary"onClick = { ()=>{ props.closeModal() }}> No </Button>	
					</ButtonGroup>
				</Modal.Footer>
			</>
		}
		</>
	)
}