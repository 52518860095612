import {
  UPDATE_DATE
} from "../../actions/testList";

const _nullDate = new Date() 
                
export default (state = _nullDate, { type, date }) => {
  Object.freeze(state);
  switch (type) {
    case UPDATE_DATE:
      return date;
  case "":
    return _nullDate; 

  default:
      return state;
  }
};