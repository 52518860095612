import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import Table from "react-bootstrap/Table"; 
import Spinner from "react-bootstrap/Spinner";
import { useState, useEffect, useRef, useCallback} from "react";
import Modal from 'react-bootstrap/Modal'
import NewAssembly from "./NewAssembly"; 
import NewConfinedEntry from "./NewConfinedEntry"; 
import {useSelector, useDispatch} from "react-redux";
import AssemblyReport from "./AssemblyReport"; 
import Invoice from "./Invoice"
import CancelStop from "./CancelStop"
import { getAddressDetails, getCustomerDetails, getServices, getTestReport, createConfinedEntry, createAssembly} from "../actions/Address"

import {updateReportID, nullifyReportID} from  "../actions/report"
import { updateAssemblyID, nullafyAssembly } from "../actions/AlternativeDetails"
import { getInvoiceInformation } from "../actions/invoice"
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ConfinedEntryTable from "./ConfinedEntryTable"

import SimpledDown from "../secondAlternative/SimpledDown"

export default function StopDetails(props){
	const dispatch = useDispatch(); 
	const [stop,setStop] = useState(props.stop); 
	const assemblyList = useSelector( (state) => state.assemblylist); 
	const [modal, setModal] = useState(false)
	const [modalTestReport, setModalTestReport] = useState(false); 
	const [modalType, setModalType] = useState(""); 
	const [currentAssembly, setCurrentAssembly] = useState(null); 
	const [currentReportID, setCurrentReportID] = useState(null); 
	const [modalBody, setModalBody] = useState(<AssemblyReport />)
	const [loadedTestReport, setLoadedTestReport] = useState(true)
	const [testModal, setTestModal] = useState(false);
	const [showTestReport, setShowTestReport] = useState(false)


	function loadInvoice(){
		console.log("Requesting a new invoice...", stop); 
		dispatch( getInvoiceInformation(stop.stopID) ).then((data, err) =>{
			console.log("Done!")
			setModalBody(
				<Invoice 
					stop = {stop} 
						closeModal = { 
							() => {
								setModal(false); 
								props.refreshInvoiceStatus()
						}
					}  
				/>
			) 
			setModal(true)
		})
		
	}
	/*useEffect( ()=>{
		if(currentReportID != null){
			setModalBody(<AssemblyReport assembly = { currentAssembly } closeModal = {() => setModal(false)}/>)
			setModal(true)	
		}
	}, [currentReportID])*/

	function newConfinedEntry(){
		dispatch( createConfinedEntry(stop.serviceAddressID)).then( (data, err) =>{
			console.log("done creating a new confined entry..."); 
			setModalType(""); 
			
		})

	}
	function NewAssembly(){
		console.log("This is the stop:  ", stop)
		createAssembly(stop.addressId, stop.stopID).then((data, err)=>{
			console.log("Created a new assembly for this address and stop...")
			dispatch( getServices(stop.stopID)) .then((data, err) =>{
				console.log("Got services again")
			})
		})
	}


	function loadTestReport(){
		console.log(`Requesting this test report:  ${currentReportID}`)
		setLoadedTestReport(false)
		dispatch( getTestReport( currentReportID) ).then((data, err) =>{
			if(err){
				console.log("error...at getting test report"); 
			}
			setLoadedTestReport(true)
		}) 	
	}

	useEffect(()=>{
			switch(modalType.toUpperCase()){
				case "INVOICE": 
						loadInvoice(); 
						break; 
				case "TESTREPORT": 
						loadTestReport(); 
						break ; 
				case "NEWASSEMBLY": 
						break;
				case "CONFINED": 
						newConfinedEntry(); 
						break; 
				case "CANCEL": 
						break; 
				default: 
					console.log("nothing to show...");
					break; 
			}
	}, [modalType])

	const renderAssemblies = assemblyList.map((service, index) =>{
		let reportID = service.testReportID
		let assemblyID = service.assemblyID
		
		return(
			<tr onClick={()=>{
				let p1 =  dispatch( updateReportID(reportID) )
				let p2 = dispatch( updateAssemblyID(assemblyID))
				Promise.all([p1, p2]).then((data, err) =>{
					setShowTestReport(true)	
				})
			}}>
				
				<td>{service.serial_number}</td>
				<td>{service.location}</td>
				<td>{service.serviceType}</td>
				<td>{service.state} </td>
			</tr>
		)
	})

	function regularClosing (){
		
		dispatch( nullifyReportID() )
		dispatch( nullafyAssembly() )
		setShowTestReport(false); 
		setModal(false)
		setModalType("")
		props.refreshInvoiceStatus()
	}

	return(

		<Container>
			<Modal show = {testModal} fullscreen>
				
				{
					loadedTestReport ?
						<AssemblyReport 
							stop = { currentAssembly } 
							closeModal = { () => { setTestModal(false)}  } 
							refreshServiceList = { () => props.refreshServiceList() } 
						/>
					: 
						<Spinner />
				}
				
			</Modal>
			<Modal show={modal} fullscreen >
				<Modal.Header closeButton onHide={ ()=> {
					regularClosing() 
				}}>
				</Modal.Header>
				<Modal.Body>
					<Container>
						<>
							{modalBody}

						</>	
					</Container>
				</Modal.Body>
				<Modal.Footer>
				</Modal.Footer>
			</Modal>

			{
				showTestReport ?
					<SimpledDown  closeRegular = { ()=> regularClosing() } refreshServiceList = { () => props.refreshServiceList() } />
				:
					<>
					
					</>

			}
			<Tabs
		      defaultActiveKey="assemblies"
		      id="uncontrolled-tab-example"
		      className="mb-3"
		      mountOnEnter
		      unmountOnExit
		    >
		      <Tab eventKey="assemblies" title="Assemblies">
		        <Row>
					<Col>
						<Table responsive size="sm">
							<thead>
								<tr>
									<th>
										Serial Number
									</th>
									<th>
										Location
									</th>
									<th>
										Service Type
									</th>
									<th>
										Status
									</th>
								</tr>
							</thead>
							<tbody>
								{renderAssemblies}
							</tbody>
						</Table>
					</Col>
				</Row>
		      </Tab>
		     {/* <Tab eventKey="ConfinedEntries" title="Confined Entries">
		       		<ConfinedEntryTable stop = {stop}/>
		      </Tab>*/}
		      </Tabs>
			
			<Row>
				<Col>
				<ButtonGroup>
					<Button variant="success" onClick ={ ()=> setModalType("invoice") }> Invoice </Button>

					<Button variant="secondary" onClick = { () => NewAssembly()  }> New Backflow Device </Button>
					{/*<Button size="sm" variant="secondary" onClick={()=>{
						setModalType("confined")
					}}>New Confined Entry</Button>*/}
				</ButtonGroup>
				</Col>
			</Row>
		</Container>
	)

}