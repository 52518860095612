import {
  SET_DMR, CLEAR_VALUES
} from "../../actions/report.js";

const _nullDMR = null
                
export default (state = _nullDMR, { type, dmr}) => {
  Object.freeze(state);
  switch (type) {
    case  SET_DMR:
      return dmr;
    case CLEAR_VALUES:
      return _nullDMR;
    case "":
      return _nullDMR; 
    default:
      return state;
  }
};