import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import Accordion from 'react-bootstrap/Accordion'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown';  
import { useState, useEffect, useRef, useCallback} from "react";
import {useSelector, useDispatch} from "react-redux";
import {format} from "date-fns"
import StopCard from "./StopCard"
import { requestPlumbingList } from "../../actions/testList"



export default function Landing(){
	const list = useSelector((state) => state.testlist)
	const dispatch = useDispatch(); 
	//request the list of stops for the givenndate
	const [dateSelected, setDateSelected] = useState(format( new Date(), "yyyy-MM-dd"))
	const [loading, setLoading]  = useState(false)
	
	useEffect(()=>{
		setLoading(true)
		dispatch( requestPlumbingList(dateSelected) ).then( (data, err) =>{
			console.log("We got an updated list!")
			setLoading(false)
		})  
	}, [dateSelected])


	let renderstops = list.map((stop, index)=>{
		return(
			<StopCard index= {index} stop ={stop} date = {dateSelected}/>
		)
	})


	return(
		<>
			<Form.Group>
				<Form.Label>Select a date: </Form.Label>
				<Form.Control type="date" 
				value={dateSelected}
				onChange = {(event) => setDateSelected(event.target.value)}
				/>
			</Form.Group>		
				{
					loading ?
						<Spinner />
					: 
						<> 
							{
								list.length ?
									<>
										<Accordion>
											{renderstops} 
										</Accordion>	
									</>
								: 
									<> No plumbing jobs listed </>
							}
										
						</>
				}
		</>
	)
}