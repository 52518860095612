import {
  TESTERS_LIST,
} from "../../actions/company";

const _nullTesterslist = null
                 
export default (state = _nullTesterslist, { type, list}) => {
  Object.freeze(state);
  switch (type) {
    case TESTERS_LIST:
      return list;
    default:
      return state;
  }
};