import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { useState, useEffect, useRef, useCallback} from "react";
import Badge from 'react-bootstrap/Badge';
import { updateTargetElement, resetUpdatedValue }  from "../../actions/NumberPad"
import {useSelector, useDispatch} from "react-redux";
import {
		updateAssembly, 
		updateTestType, 
		updateSystem, 
		updateApproved, 
		updateDoubleCheck, 
		updateReducedPressure, 
		updateXVBA, 
		updateAG, 
		updateFDoubleCheck,
		updateFRP, 
		updateFXVBA, 
		updateRemarks, 
		updateRepairs,
		sendTestReportToWaterDistrict
} from "../../actions/report"

export default function FinalXVBA(){
		const [systemPass, setSystemPass] = useState(null)
	const [systemFail, setSystemFail] = useState(null); 

	const report = useSelector((state)=> state.report)
	/*variables to redirect to the number pad*/
	const dispatch = useDispatch()
	const currentPadTarget = useSelector((state) => state.numberPad)
	const updatedValue = useSelector((state) => state.updatedVal)

	const [final_openedFully, setFinalOpenedFully] = useState(false)
	const [final_openedAt, setFinalOpenedAt] = useState(0.0);
	const [final_checkPass, setFinalCheckPass] = useState(false)
	const [final_pressdrop, setFinalPressdrop] = useState(0.0);

	/*validation checker*/
	const [hasFinalOpenedAt, setHasFinalOpenedAt] = useState(false); 
	const [hasFinalPressdrop, setHasFinalPressdrop] = useState(false)
	useEffect(()=>{
		setSystemPass(report.repair_pass)
		setSystemFail(report.repair_fail)
		setFinalOpenedAt(report.repair_vb_air_opened_at); 
		setFinalPressdrop(report.repair_vb_check_pressdrop); 
		document.querySelector("#final_openedAt").value = report.repair_vb_air_opened_at; 
		document.querySelector("#final_pressdrop").value = report.repair_vb_check_pressdrop; 
		document.querySelector("#finalPass").checked = report.repair_pass
	}, [report])



	useEffect(()=>{
		if(final_openedAt >= 1.0){
			setFinalOpenedFully(true)
		}else{
			setFinalOpenedFully(false)
		}

		if(final_pressdrop >= 1.0){
			setFinalCheckPass(true)
		}else{
			setFinalCheckPass(false)
		}
	}, [final_openedAt, final_pressdrop])

	/*check and set validation*/
	useEffect(()=>{

		if(final_openedAt == null || final_openedAt == 0){
			setHasFinalOpenedAt(false)
		}else{
			setHasFinalOpenedAt(true)
		}

		if(final_pressdrop == null || final_pressdrop == 0 ){
			setHasFinalPressdrop(false)
		}else{
			setHasFinalPressdrop(true)
		}
	}, [final_openedAt, final_pressdrop])

	function update(){
		let myobj = {}; 
		myobj.pass = document.querySelector("#finalPass").checked;
		myobj.fail = document.querySelector("#finalFail").checked;  
		
		myobj.pressdrop = document.querySelector("#final_pressdrop").value; 
		myobj.openedAt = document.querySelector("#final_openedAt").value
		dispatch( updateFXVBA(report.id, myobj) ).then( (data, err)=>{
			console.log("updated final xvba")
		})
	}

	/*redirects values to html elements*/
	useEffect(()=>{
		if(updatedValue){
			setFinalOpenedAt(document.querySelector("#final_openedAt").value)
			setFinalPressdrop(document.querySelector("#final_pressdrop").value)
			dispatch( resetUpdatedValue())
		}
	}, [updatedValue])

	/*checks if both inputs are valid to be sent to the DB*/
	useEffect( ()=>{
		if(hasFinalPressdrop && hasFinalOpenedAt){
			update()
		}
	}, [currentPadTarget])

	
	const [watch, setWatch] = useState(false)
	useEffect(()=>{
		if(currentPadTarget == "#final_check1_psid" || currentPadTarget == "#final_openedAt"){
			setWatch(true)
		}else{
			setWatch(false)
		}
		
		if(watch){
			update()
		}
	}, [currentPadTarget])

	const redirectKeyboard = (event)=>{
		dispatch( updateTargetElement(event.target.id) ).then((data, err) =>{
			if(err){
				console.log(err)
			}
		})
	}

	return(
		<Container>
			<Row>
				<Col>
					<h6>Results </h6>
				</Col>
				<Col>
					<Form.Check 
						id ="finalPass"  
						label="Pass" 
						checked = {systemPass} 
						onChange={ 
							(event) => {
								if(event.target.checked){
									setSystemFail(false)

								}
								setSystemPass(event.target.checked)
							}
						}

						onBlur = {
							() => update()
						}
					/>
					</Col>
					<Col>
					<Form.Check id = "finalFail" label="Fail"   checked = {systemFail} 
					onChange={ 
						(event) => {
							if(event.target.checked){
								setSystemPass(false)
							}
							setSystemFail(event.target.checked)
						}
					}
					onBlur = {
							() => update()
						}
				/>
				</Col>
				<hr/>
			</Row>
			<Row>
				<Col>
					<strong>Air Inlet</strong>
				</Col>
				<Col>
					{
						final_openedFully ?
							<Badge bg="primary"> Opened Fully </Badge>
						:
							<Badge bg="secondary"> Did Not Open </Badge>
					}
				</Col>
			</Row>	
			<Row>
				<Col>
					<Form.Label>Opened At </Form.Label> 
					<InputGroup>
						<Form.Control 
							id ="final_openedAt"
							required
							isInvalid = {!hasFinalOpenedAt}
							isValid = { hasFinalOpenedAt}
							readOnly
							onFocus = { redirectKeyboard}
						/>
					</InputGroup>
						
				</Col>
			</Row>
			<Row>
				<Col>
					<strong>Check Valve</strong>
				</Col>

				<Col>
					{
						final_checkPass ?
							<Badge bg="primary"> Pass </Badge>
						:
							<Badge bg="secondary"> Failed </Badge>
					}
						
				</Col>
				<br/>
			</Row>
			<Row>
				<Col>
					<Form.Label> Pressdrop </Form.Label>
					<InputGroup>
						<Form.Control 
							id = "final_pressdrop"
							required
							isInvalid = {!hasFinalPressdrop}
							isValid = { hasFinalPressdrop}
							readOnly
							onFocus = { redirectKeyboard }
						/>
						
					</InputGroup>
				</Col>
			</Row>
		</Container>
	)
}