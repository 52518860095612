import * as server from "../util/alternativeServer";

export const RECEIVE_ASSEMBLY = "RECEIVE_ASSEMBLY"; 
export const CLEAR_ASSEMBLY = "CLEAR_ASSEMBLY"; 
export const SET_ASSEMBLY_ID = "SET_ASSEMBLY_ID"
export const  SET_ASSEMBLY_TYPE = " SET_ASSEMBLY_TYPE"

const receiveAssembly = (assembly) => ({
	type: RECEIVE_ASSEMBLY, 
	assembly
})


const receiveAssemblyID = (id) => ({
	type: SET_ASSEMBLY_ID, 
	id
})


const clearAssembly = () =>({
	type: CLEAR_ASSEMBLY
})

const setAssemblyType = (ass_type) => ({
	type: SET_ASSEMBLY_TYPE, 
	ass_type
})

export const nullafyAssembly = () => dispatch => {
	return new Promise( async( resolve, reject)=>{
		dispatch( clearAssembly() )
		resolve(true)
	})
}

export const updateAssemblyID = (id) => dispatch => {
	return new Promise( async( resolve, reject)=>{
		dispatch( receiveAssemblyID(id) )
		resolve(true)
	})
}


export const reduceAssemblyType = (assType) => dispatch => {
	return new Promise( async( resolve, reject)=>{
		dispatch( setAssemblyType(assType) )
		resolve(true)
	})
}



export const createAssembly = (assemblyObj) => dispatch =>{
	return new Promise( async( resolve, reject)=>{
		const response = await server.getAssembly(assemblyObj)
		const data = await response.json(); 
		if(response.ok){
			console.log("Data: ", data)
			//update assembly redux slice
			dispatch( receiveAssembly( JSON.parse(data).assembly ))
			resolve(true)
		}else{
			//log error
			resolve(false)
		}
	})

}

export const deleteAssembly = (assemblyID) => dispatch =>{
	return new Promise( async( resolve, reject)=>{
		const response = await server.deleteAssembly(assemblyID)
		const data = await response.json(); 
		if(response.ok){
			console.log("Data: ", data)
			//update assembly redux slice
			dispatch( clearAssembly() )
			resolve(true)
		}else{
			//log error
			resolve(false)
		}
	})
}

export const getAssembly = (assemblyID) => dispatch =>{
	return new Promise( async( resolve, reject)=>{
		const response = await server.getAssembly(assemblyID)
		const data = await response.json(); 
		if(response.ok){
			
			//update assembly redux slice
			dispatch( receiveAssembly( JSON.parse(data).assembly ))
			resolve(true)
		}else{
			//log error
			resolve(false)
		}
	})
}

export const updateAssembly = (assemblyID, obj) => dispatch =>{
	return new Promise ( async(resolve, reject) =>{
		const response = await server.updateAssembly(assemblyID, obj)
		const data = await response.json(); 
		if(response.ok){
			//update assembly redux slice
			dispatch( receiveAssembly( JSON.parse(data).assembly ))
			resolve(true)
		}else{
			//log error
			resolve(false)
		}

	})
}

