import {
  SET_CHECK1_PSID, CLEAR_VALUES
} from "../../actions/report.js";

const _nullCheck1_psid = null
                
export default (state = _nullCheck1_psid, { type, psid}) => {
  Object.freeze(state);
  switch (type) {
    case SET_CHECK1_PSID:
      return psid;
    case CLEAR_VALUES: 
      return _nullCheck1_psid; 
  
    case "":
      return _nullCheck1_psid; 
    default:
      return state;
  }
};