import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { useState, useEffect, useRef, useCallback} from "react";
import Badge from 'react-bootstrap/Badge';
import {useSelector, useDispatch} from "react-redux";
import {
		updateRepairs
} from "../../actions/report"

export default function Repairs(){
	const [disc, setDisc] = useState(false); 
	const [spring, setSpring] = useState(false); 
	const [guide, setGuide] = useState(false); 
	const [diagh, setDiagh] = useState(false); 
	const [seat, setSeat] = useState(false); 
	const [o_ring, setO_ring]= useState(false); 
	const [modules, setModules] = useState(false); 
	const [rubber_kit, setRubberKit] = useState(false); 
	const [air_inlet_disc, setAirInletDisc] = useState(false); 
	const [air_inlet_spring, setAirInletSpring] = useState(false); 
	const [check_disc, setCheckDisc] = useState(false); 
	const [check_spring, setCheckSpring] = useState(false); 
	const [float, setFloat] = useState(false); 
	const report = useSelector((state) => state.report)
	const [comment, setComment] = useState(null)
	const [repaired, setRepaired] = useState(false); 
	const [cleaned, setCleaned] = useState(false) ;
	const [na, setNa] = useState(false);
	const dispatch = useDispatch()    
	useEffect( ()=>{
		if(report != null){
			/**/
			setDisc(report.disc); 
			setSpring(report.spring); 
			setGuide(report.guide); 
			setDiagh(report.diaphragm); 
			setSeat(report.seat); 
			setO_ring(report.o_ring); 
			setModules(report.module); 
			setRubberKit(report.rubber_kit); 
			setAirInletDisc(report.air_disc); 
			setAirInletSpring(report.air_spring); 
			setCheckDisc(report.check_disc); 
			setCheckSpring(report.check_spring); 
			setFloat(report.ff);
			setComment(report.comments) 
			setCleaned(report.cleaned)
			setRepaired(report.repaired); 
			document.querySelector("#cleaned").checked = report.cleaned; 
			document.querySelector("#repaired").checked = report.repaired; 
			document.querySelector("#comment").value = report.comments; 
			document.querySelector("#disc").checked = report.disc; 
			document.querySelector("#spring").checked = report.spring; 
			document.querySelector("#guide").checked = report.guide; 
			document.querySelector("#diagh").checked = report.diaphragm; 
			document.querySelector("#seat").checked = report.seat; 
			document.querySelector("#o_ring").checked = report.o_ring; 
			document.querySelector("#module").checked = report.module; 
			document.querySelector("#rubber_kit").checked = report.rubber_kit; 
			document.querySelector("#air_disc").checked = report.air_disc; 
			document.querySelector("#air_spring").checked = report.air_spring; 
			document.querySelector("#check_disc").checked = report.check_disc; 
			document.querySelector("#check_spring").checked = report.check_spring; 
			document.querySelector("#float").checked = report.ff; 
		}
	}, [report])

	const [pass, setPass] = useState(false)
	const [passClass, setPassClass] = useState("secondary")
	const [failClass, setFailClass] = useState("secondary")

	useEffect(()=>{
		if(pass){

		}else{
		}
	}, [pass])

	useEffect(()=>{
		if(!cleaned && !repaired){
			setNa(true)
		}

	}, [repaired, cleaned])

	const update = () => {
		let obj = {}; 
		obj.comment = document.querySelector("#comment").value; 
		obj.cleaned = document.querySelector("#cleaned").checked; 
		obj.repaired = document.querySelector("#repaired").checked; 
		obj.disc =	document.querySelector("#disc").checked 
		obj.spring = document.querySelector("#spring").checked
		obj.guide = document.querySelector("#guide").checked  
		obj.diaphragm = document.querySelector("#diagh").checked 
		obj.seat =	document.querySelector("#seat").checked 
		obj.o_ring = document.querySelector("#o_ring").checked  
		obj.module = document.querySelector("#module").checked 
		obj.rubber_kit = document.querySelector("#rubber_kit").checked  
		obj.air_disc = document.querySelector("#air_disc").checked 
		obj.air_spring = document.querySelector("#air_spring").checked 
		obj.check_disc = document.querySelector("#check_disc").checked 
		obj.check_spring = document.querySelector("#check_spring").checked 
		obj.float = document.querySelector("#float").checked 

		dispatch( updateRepairs( report.id, obj) ).then(( data, err) =>{
			console.log("Updated repair items")
		})

	}

	useEffect(()=>{
		update()
	}, [cleaned, 
		repaired,
		 comment, 
		 float, 
		 check_spring, 
		 check_disc, 
		 air_inlet_spring, 
		 air_inlet_disc, 
		 rubber_kit, 
		 modules, 
		 o_ring, 
		 seat, 
		 diagh, 
		 guide, 
		 spring, 
		 disc])

	return(

		<Container>
			<Row>
				<Col>
						<Form.Label> The assembly was: </Form.Label>
						<Form.Check id = "cleaned"  label ="Cleaned" checked = {cleaned} onChange = { (event)=> setCleaned(event.target.checked)}/>
						<Form.Check id = "repaired" label ="Repaired" checked = {repaired} onChange = { (event)=> setRepaired(event.target.checked)}/>
				</Col>
			</Row>
			<Row>
				<Col>
					<hr/>
					<Form.Label>Notes, Repairs & Parts</Form.Label>
				</Col>
			</Row>	
			<Row>
				<Col>
					<Form.Control type="text" id="comment" value = { comment} onChange= {(event) => setComment(event.target.value)}/>
				</Col>
			</Row>
				<hr/>
				<Row>

					<Col>
						<Form.Check id="disc" label ="Disc" checked = {disc} onChange= { (event)=>{ setDisc(event.target.checked)}}/>
						<Form.Check id ="spring" label ="Spring" checked = {spring} onChange= { (event)=>{ setSpring(event.target.checked)}}/>
						<Form.Check id ="guide" label ="Guide" checked = {guide} onChange= { (event)=>{ setGuide(event.target.checked)}}/>
						<Form.Check id ="diagh" label ="Diaphragm" checked = {diagh} onChange= { (event)=>{ setDiagh(event.target.checked)}}/>
					</Col>
					<Col>
						<Form.Check  id ="seat" label ="Seat" checked = {seat} onChange= { (event)=>{ setSeat(event.target.checked)}}/>
						<Form.Check id ="o_ring" label ="O-Ring(s)" checked = { o_ring} onChange= { (event)=>{ setO_ring(event.target.checked)}}/>
						<Form.Check id ="module" label ="Module" checked = {modules} onChange= { (event)=>{ setModules(event.target.checked)}}/>
						<Form.Check id ="rubber_kit" label ="Rubber Kit" checked={rubber_kit} onChange= { (event)=>{ setRubberKit(event.target.checked)}}/>
					</Col>
					<Col>
						
						<Form.Check id ="air_disc" label ="Air Inlet Disc" checked = { air_inlet_disc} onChange= { (event)=>{ setAirInletDisc(event.target.checked)}}/>
						<Form.Check id ="air_spring" label ="Air Inlet Spring" checked={air_inlet_spring} onChange= { (event)=>{ setAirInletSpring(event.target.checked)}}/>
						<Form.Check id ="check_disc" label ="Check Disc" checked = {check_disc} onChange= { (event)=>{ setCheckDisc(event.target.checked)}}/>
						<Form.Check id ="check_spring" label ="Check Spring" checked = { check_spring} onChange= { (event)=>{ setCheckSpring(event.target.checked)}}/>
					</Col>
					<Col>
						
						<Form.Check  id ="float" label ="Float" checked = {float} onChange= { (event)=>{ setFloat(event.target.checked)}}/>
					
					</Col>
				</Row>
		
		</Container>
	)

}