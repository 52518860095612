
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Accordion from 'react-bootstrap/Accordion';
import Container from 'react-bootstrap/Container';
import Badge from 'react-bootstrap/Badge';


export default function Footer(){
	return(
		<Container fluid>
			<Row bsPrefix="footer-row">

			</Row>
		</Container>
	)
}