import * as server from "../util/alternativeServer";


import { TEST_REPORT}  from "./Address"
import { RECEIVE_ASSEMBLY } from "./AlternativeDetails.js"; 

export const SET_REPORT_ID = "SET_REPORT_ID"; 
export const CLEAR_REPORT_ID = "CLEAR_REPORT_ID"


export const CLEAR_VALUES  = "CLEAR_VALUES"

/*Double checks redux slices*/
export const SET_CHECK1_PSID = "SET_CHECK1_PSID"; 

export const SET_CHECK2_PSID = "SET_CHECK2_PSID"; 

export const SET_FINAL_CHECK1_PSID = "SET_FINAL_CHECK1_PSID"

export const SET_FINAL_CHECK2_PSID = "SET_FINAL_CHECK2_PSID"


/*Relief Valve redux slices*/
export const SET_RV_PRESSDROP = "SET_RV_PRESSDROP"

export const SET_RV_OPENED = "SET_RV_OPENED"
export const SET_FINAL_RV_PRESSDROP = "SET_FINAL_RV_PRESSDROP"
export const SET_FINAL_RV_OPENED = "SET_FINAL_RV_OPENED"

/*xvba redux slices*/
export const SET_AIR_OPENED = "SET_AIR_PRESSDROP"; 
export const SET_CHECK_PRESSDROP = "SET_CHECK_PRESSDROP"
export const SET_FINAL_AIR_OPENED = "SET_FINAL_AIR_OPENED"
export const SET_FINAL_CHECK_PRESSDROP = "SET_FINAL_CHECK_PRESSDROP"

/*AG*/
export const SET_PIPESIZE = "SET_PIPESIZE"
export const SET_PHYS_SEP = "SET_PHYS_SEP"
export const SET_FINAL_PIPESIZE = "SET_FINAL_PIPESIZE" 
export const SET_FINAL_PHYS_SEP = "SET_FINAL_PHYS_SEP"

/*system redux slices*/
export const SET_SYSTEM_PSI = "SET_SYSTEM_PSI";
export const SET_DMR = "SET_DMR"




const receiveClearer = () => ({
	type: CLEAR_VALUES
})

const receiveReportID = (id) => ({
	type: SET_REPORT_ID, 
	id
})

const clearReportID = () => ({
	type: CLEAR_REPORT_ID
})

const receiveTestReport = (report) => ({
	type: TEST_REPORT, 
	report
})


const receiveAssembly = (assembly) => ({
	type: RECEIVE_ASSEMBLY, 
	assembly
})

const receiveCheck1 = (psid) =>({
	type: SET_CHECK1_PSID, 
	psid
})


const receiveCheck2 = (psid) =>({
	type: SET_CHECK2_PSID, 
	psid
})

const receivePipesize = (psid) =>({
	type: SET_PIPESIZE, 
	psid
})
const receiveFinalPipesize = (psid) =>({
	type: SET_FINAL_PIPESIZE, 
	psid
})
const receivePhysicalSep = (psid) =>({
	type: SET_PHYS_SEP, 
	psid
})

const receiveFinalPhysicalSep = (psid) =>({
	type: SET_FINAL_PHYS_SEP, 
	psid
})

const receiveSystemPSI = (psi) => ({
	type: SET_SYSTEM_PSI, 
	psi
})
const receiveDMR = (dmr) => ({
	type: SET_DMR, 
	dmr
})

const receiveFinalCheck1 = (psid) =>({
	type: SET_FINAL_CHECK1_PSID, 
	psid
})

const receiveFinalCheck2 = (psid) =>({
	type: SET_FINAL_CHECK2_PSID, 
	psid
})

const receive_rvPressdrop = (psid) =>({
	type: SET_RV_PRESSDROP, 
	psid
})
const receive_rvOpenedAt = (psid) =>({
	type: SET_RV_OPENED, 
	psid
})

const receive_final_rvPressdrop = (psid) =>({
	type: SET_FINAL_RV_PRESSDROP, 
	psid
})
const receive_final_rvOpenedAt = (psid) =>({
	type: SET_FINAL_RV_OPENED, 
	psid
})


const receive_air_opened = (psid) =>({
	type: SET_AIR_OPENED, 
	psid
})

const receive_check_pressdrop = (psid) =>({
	type: SET_CHECK_PRESSDROP, 
	psid
})

const receive_final_air_opened = (psid) =>({
	type: SET_FINAL_AIR_OPENED, 
	psid
})
const receive_final_check_pressdrop = (psid) =>({
	type: SET_FINAL_CHECK_PRESSDROP, 
	psid
})

export const clearMeUp = () => dispatch =>{
	return new Promise( async(resolve, reject) =>{
		dispatch( receiveClearer() )
		resolve()
	})
}

export const nullifyReportID = () => dispatch =>{
	return new Promise( async(resolve, reject) =>{
		dispatch( clearReportID() )
		resolve()
	})
}

export const updateReportID = (id) => dispatch =>{
	return new Promise( async(resolve, reject) =>{
		dispatch( receiveReportID(id) )
		resolve()
	})
}

export const updatePipesize = (psid) => dispatch =>{
	return new Promise( async(resolve, reject) =>{
		dispatch( receivePipesize(psid) )
		resolve()
	})
}
export const updatePhysicalSeparation= (psid) => dispatch =>{
	return new Promise( async(resolve, reject) =>{
		dispatch( receivePhysicalSep(psid) )
		resolve()
	})
}
export const updateFinalPipesize = (psid) => dispatch =>{
	return new Promise( async(resolve, reject) =>{
		dispatch( receiveFinalPipesize(psid) )
		resolve()
	})
}
export const updateFinalPhysicalSeparation = (psid) => dispatch =>{
	return new Promise( async(resolve, reject) =>{
		dispatch( receiveFinalPhysicalSep(psid) )
		resolve()
	})
}



export const updateAirOpened= (psid) => dispatch =>{
	return new Promise( async(resolve, reject) =>{
		dispatch( receive_air_opened(psid) )
		resolve()
	})
}

export const updateCheckPressdrop= (psid) => dispatch =>{
	return new Promise( async(resolve, reject) =>{
		dispatch( receive_check_pressdrop(psid) )
		resolve()
	})
}
export const updateFinalAirOpened= (psid) => dispatch =>{
	return new Promise( async(resolve, reject) =>{
		dispatch( receive_final_air_opened(psid) )
		resolve()
	})
}
export const updateFinalCheckPressdrop= (psid) => dispatch =>{
	return new Promise( async(resolve, reject) =>{
		dispatch( receive_final_check_pressdrop(psid) )
		resolve()
	})
}



export const updateRvOpenedAt= (psid) => dispatch =>{
	return new Promise( async(resolve, reject) =>{
		dispatch( receive_rvOpenedAt(psid) )
		resolve()
	})
}

export const updateRvPressdrop= (psid) => dispatch =>{
	return new Promise( async(resolve, reject) =>{
		dispatch( receive_rvPressdrop(psid) )
		resolve()
	})
}

export const updateFinalRvPressdrop = (psid) => dispatch =>{
	return new Promise( async(resolve, reject) =>{
		dispatch( receive_final_rvPressdrop(psid) )
		resolve()
	})
}

export const updateFinalRvOpenedAt = (psid) => dispatch =>{
	return new Promise( async(resolve, reject) =>{
		dispatch( receive_final_rvOpenedAt(psid) )
		resolve()
	})
}


export const updateFinalCheck1 = (psid) => dispatch =>{
	return new Promise( async(resolve, reject) =>{
		dispatch( receiveFinalCheck1(psid) )
		resolve()
	})
}

export const updateFinalCheck2 = (psid) => dispatch =>{
	return new Promise( async(resolve, reject) =>{
		dispatch( receiveFinalCheck2(psid) )
		resolve()
	})
}

export const updateDMR = (dmr) => dispatch =>{
	return new Promise( async(resolve, reject) =>{
		dispatch( receiveDMR(dmr) )
		resolve()
	})
}

export const updateSystemPSI = (psi) => dispatch =>{
	return new Promise( async(resolve, reject) =>{
		dispatch( receiveSystemPSI(psi) )
		resolve()
	})
}


export const updateCheck1 = (psid) => dispatch =>{
	return new Promise( async(resolve, reject) =>{
		dispatch( receiveCheck1(psid) )
		resolve()
	})
}
 export const updateCheck2 = (psid) => dispatch =>{
 	return new Promise( async(resolve, reject) =>{
 		dispatch( receiveCheck2(psid) )
 		resolve()
	})
 }


export const getTestReport = (reportID) => dispatch =>{
	return new Promise( async(resolve, reject) =>{
		const response = await server.requestReport(reportID); 
		const data = await response.json(); 
		if(response.ok){
				dispatch( receiveTestReport(JSON.parse(data).report) ); 
				
				resolve()
		}
	})
}

export const updateTestType = (reportID, obj) => dispatch =>{
	return new Promise( async( resolve, reject) =>{
		let myObj = {id: reportID, obj: obj}

		const response = await  server.updateTestType(myObj); 
		const data = await response.json(); 
		if(response.ok){
			dispatch( receiveTestReport( JSON.parse(data).report) ); 
			resolve(true)
		}
	})
}


export const notTested = (reportID, reason) => dispatch =>{
	return new Promise( async( resolve, reject) =>{
		let myObj = {reportID: reportID, reason: reason}
		const response = await  server.notTested(myObj); 
		const data = await response.json(); 
		if(response.ok){
			/*dispatch( receiveTestReport( JSON.parse(data).report) )*/
			resolve(true)
		}
	})
}
export const sendTestReportToWaterDistrict = (reportID, selectedDate) => dispatch =>{
	return new Promise( async( resolve, reject) =>{
		let myObj = {id: reportID, selectedDate: selectedDate}
		const response = await  server.sendToWaterDistrict(myObj); 
		const data = await response.json(); 
		if(response.ok){
			/*dispatch( receiveTestReport( JSON.parse(data).report) )*/
			resolve(true)
		}
	})
}
export const updateAssembly = (reportID, obj) => dispatch => {
	return new Promise( async( resolve, reject) =>{
		let myObj = {id: reportID, obj: obj}

		const response = await  server.updateAssembly(myObj); 
		const data = await response.json(); 
		if(response.ok){
			/*dispatch( receiveTestReport( JSON.parse(data).report) ); */
			dispatch( receiveAssembly( JSON.parse(data).assembly) ); 
			resolve(true)
		}
	})

}

export const updateAG = (reportID, obj) => dispatch => {
	return new Promise( async( resolve, reject) =>{
		let myObj = {id: reportID, obj: obj}
		const response = await  server.updateAG(myObj); 
		const data = await response.json(); 
		if(response.ok){
			/*dispatch( receiveTestReport( JSON.parse(data).report) )*/
			resolve(true)
		}
	})
}
export const updateDoubleCheck = (reportID, obj) => dispatch => {
	return new Promise( async( resolve, reject) =>{
		let myObj = {id: reportID, obj: obj}
		const response = await  server.updateDoubleCheck(myObj); 
		const data = await response.json(); 
		if(response.ok){
			/*dispatch( receiveTestReport( JSON.parse(data).report) )*/
			resolve(true)
		}
	})
}

export const updateReducedPressure = (reportID, obj) => dispatch => {
	return new Promise( async( resolve, reject) =>{
		let myObj = {id: reportID, obj: obj}
		const response = await  server.updateReducedPressure(myObj); 
		const data = await response.json(); 
		if(response.ok){
			/*dispatch( receiveTestReport( JSON.parse(data).report) )*/
			resolve()
		}
		
	})
}

export const updateXVBA = (reportID, obj) => dispatch => {
	return new Promise( async( resolve, reject) =>{
		let myObj = {id: reportID, obj: obj}
		const response = await  server.updateXVBA(myObj); 
		const data = await response.json(); 
		if(response.ok){
			/*dispatch( receiveTestReport( JSON.parse(data).report) )*/
			resolve(true)
		}
		
	})
}

export const updateFDoubleCheck = (reportID, obj) => dispatch => {
	return new Promise( async( resolve, reject) =>{
		let myObj = {id: reportID, obj: obj}
		console.log("This is my object... ", myObj, JSON.stringify(myObj)); 
		const response = await  server.updateFinalDC(myObj); 
		const data = await response.json(); 
		if(response.ok){
			resolve(true)
		}
	})
}
export const updateFRP = (reportID, obj) => dispatch => {
	return new Promise( async( resolve, reject) =>{
		let myObj = {id: reportID, obj: obj}
		const response = await  server.updateFinalRP(myObj); 
		const data = await response.json(); 
		if(response.ok){
			/*dispatch( receiveTestReport( JSON.parse(data).report) )*/
			resolve(true)
		}
	})
}
export const updateFXVBA = (reportID, obj) => dispatch => {
	return new Promise( async( resolve, reject) =>{
		let myObj = {id: reportID, obj: obj}
		const response = await  server.updateFinalXVBA(myObj); 
		const data = await response.json(); 
		if(response.ok){
			/*dispatch( receiveTestReport( JSON.parse(data).report) )*/
			resolve(true)
		}
	})
}

export const updateSystem = (reportID, obj) => dispatch => {
	return new Promise( async( resolve, reject) =>{
		let myObj = {id: reportID, obj: obj}
		const response = await  server.updateSystem(myObj); 
		const data = await response.json(); 
		if(response.ok){
			/*dispatch( receiveTestReport( JSON.parse(data).report) )*/
			resolve(true)
		}
	})
}
export const updateApproved = (reportID, obj) => dispatch => {
	return new Promise( async( resolve, reject) =>{
		let myObj = {id: reportID, obj: obj}
		const response = await  server.updateApproved(myObj); 
		const data = await response.json(); 
		if(response.ok){
			/*dispatch( receiveTestReport( JSON.parse(data).report) )*/
			resolve(true)
		}
	})
}

export const updateRemarks = (reportID, obj) => dispatch =>{
	return new Promise( async( resolve, reject) =>{
		let myObj = {id: reportID, obj: obj}
		const response = await  server.updateRemarks(myObj); 
		const data = await response.json(); 
		if(response.ok){
			/*dispatch( receiveTestReport( JSON.parse(data).report) )*/
			resolve(true)
		}
	})
}
export const updateRepairs = (reportID, obj) => dispatch =>{
	return new Promise( async( resolve, reject) =>{
		let myObj = {id: reportID, obj: obj}
		const response = await  server.updateRepairs(myObj); 
		const data = await response.json(); 
		if(response.ok){
			/*dispatch( receiveTestReport( JSON.parse(data).report) )*/
			resolve(true)
		}
	})
}

