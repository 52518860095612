import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useState, useEffect, useRef, useCallback} from "react";
import {updateSerial, updateLocation, updateMake, updateModel, 
		updateSize, updateHazard, updateType, updateStatus, getAssembly} from "../actions/AlternativeDetails"
import {useSelector, useDispatch} from "react-redux";

import {
		updateAssembly
} from "../actions/report"

export default function AssemblyDetails(props){	
	const assembly= useSelector( (state) => state.assembly)
	const [spinner, setSpinner] = useState(false); 
	const dispatch = useDispatch(); 
	const report = useSelector( (state) => state.report)
	const [modalSpinner, setModalSpinner] = useState(false)
	/*value variables*/
	const [serial, setSerial] = useState(null); 
	const [location, setLocation] = useState(null); 
	const [size, setSize] = useState(null);
	const [make, setMake] = useState(null); 
	const [model, setModel] = useState(null); 
	const [hazard, setHazard] = useState(null); 
	const [type, setType]  = useState(null); 
	const [status, setStatus] = useState(null); 

	function strSize(size){
		switch (size){
			case "0.50": 
					setSize("1/2"); 
				break; 
			case "0.75":
				setSize("3/4"); 
				break; 
			default: 
				setSize("1") 
				break; 
		}
	}

	useEffect( () =>{
		if(assembly != null){
			setSerial(assembly.serial_number)
			setLocation(assembly.location)
			setSize(assembly.size)
			setMake(assembly.make)
			setModel(assembly.model)
			setHazard(assembly.hazard_type)
			setType(assembly.type)
			setStatus(assembly.state)	
		}
	}, [assembly])

	function updateValue(){
		let obj = {}; 
		obj.id = assembly.id; 
		obj.type = document.querySelector("#type").value; 
		obj.size= document.querySelector("#size").value; 
		obj.make = document.querySelector("#make").value; 
		obj.model = document.querySelector("#model").value;
		obj.serial =  document.querySelector("#serial").value; 
		obj.hazard = document.querySelector("#hazard").value
		obj.status = "E"; 
		obj.location = document.querySelector("#location").value; 
		dispatch(updateAssembly(report.id, obj) ).then((data, err) =>{	
			console.log("updated assembly!")
		})
	}

	/*validation variable*/
	const [hasSerial, setHasSerial] = useState(false); 
	const [hasLocation, setHasLocation] = useState(false); 
	const [hasSize, setHasSize] = useState(false); 
	const [hasMake, setHasMake] = useState(false); 
	const [hasModel, setHasModel] = useState(false); 
	const [hasHazard, setHasHazard] = useState(false); 
	const [hasType, setHasType] = useState(false); 
	const [hasStatus, setHasStatus] = useState(false); 

	useEffect(()=>{
		if(serial == null || serial == ""){
			setHasSerial(false); 
		}else{
			setHasSerial(true)
		}
		if(location == null || location == ""){
			setHasLocation(false); 
		}else{
			setHasLocation(true)
		}
		if(size== null || size == 0) {
			setSize("______")
			setHasSize(false)
		}else{
			setHasSize(true)
		}
		if(make == null || make == ""){
			setHasMake(false); 
		}else{
			setHasMake(true)
		}
		if(model ==null || model == "" ){
			setHasModel(false)
		}else{
			setHasModel(true)
		}
		if(hazard == null || hazard == ""){
			setHasHazard(false)
		}else{
			setHasHazard(true)
		}
		if(type == null || type == ""){
			setHasType(false)
		}else{
			setHasType(true)
		}
	}, [serial, location, size, make, model, hazard, type, status])

	return(
		<>
			<Modal show={modalSpinner} centered static>
				<Modal.Body>
					<Spinner />
				</Modal.Body>
			</Modal>

			{
				spinner ? 
					<Spinner />
				:
					<Form>
					<Row xs = {1}>
						<Col>
							<Form.Group>
								<Form.Label>Serial Number</Form.Label>
								<InputGroup  hasValidation>
									<Form.Control 
										type="text"
										id="serial"
										value = {serial}
										onChange = { (event) => setSerial(event.target.value.toUpperCase()) }
										onBlur = { (event) => updateValue(event.target.id) }
										required
										isInvalid={!hasSerial} 
										isValid ={hasSerial}
										size="sm"
									/>
								</InputGroup>
							</Form.Group>
							<br/>
						</Col>
						<Col>
							<Form.Group>
								<Form.Label>Location</Form.Label>
								<InputGroup hasValidation>
									<Form.Control 
										type="text"
										id="location"
										value = {location}
										onChange = { (event) => setLocation(event.target.value) }
										onBlur = { (event) => updateValue(event.target.id) }
										required
										isInvalid={!hasLocation}
										isValid  = { hasLocation }
										size="sm"
									/>
								</InputGroup>
							</Form.Group>
							<br/>
						</Col>
					
						<Col>	
							<Form.Group>
								<Form.Label>Make</Form.Label>
								<Form.Control
									type="text"
									id="make"
									value = {make}
									onChange = { (event) => setMake(event.target.value) }
									onBlur = { (event) => updateValue(event.target.id) }
									required
									isInvalid={!hasMake}
									isValid  = { hasMake }
									size="sm"
								/>
							</Form.Group>
							<br/>
						</Col>

						<Col>
							<Form.Group>
								<Form.Label>Model</Form.Label>
								<Form.Control
									type="text"
									id="model"
									value = {model}
									onChange = { (event) => setModel(event.target.value) }
									onBlur = { (event) => updateValue(event.target.id) }
									required
									isInvalid={!hasModel}
									isValid  = { hasModel }
									size="sm"
								/>
							</Form.Group>
							<br/>
						</Col>
						<Col>
							<Form.Group>
								<Form.Label>Size</Form.Label>
								<Form.Select size="sm" id="size"value = { size } onChange= { (event) => setSize(event.target.value) } >
									<option value="3/8">3/8"</option>
									<option value="1/2">1/2"</option>
									<option value="3/4">3/4"</option>
									<option value="1">1"</option>
									<option value= "1 1/4"> 1 1/4"</option>
									<option value="1.5">1 1/2"</option>
									<option value="2">2"</option>
									<option value="2.5">2 1/2"</option>
									<option value="3">3"</option>
									<option value="4">4"</option>
									<option value="6">6"</option>
									<option value="8">8"</option>
									<option value="10">10"</option>
									<option value="12">12"</option>
								</Form.Select>
							</Form.Group>
							<br/>
						</Col>
						<Col>
							<Form.Group>
								<Form.Label>Hazard</Form.Label>
								<Form.Control
									type="text"
									id="hazard"
									value = {hazard}
									onChange = { (event) => setHazard(event.target.value) }
									onBlur = { (event) => updateValue(event.target.id) }
									required
									isInvalid={!hasHazard}
									isValid  = { hasHazard }
									size="sm"
								/>
							</Form.Group>
							<br/>
						</Col>
						
						<Col>
							<Form.Group>
								<Form.Label>Type</Form.Label>
								<Form.Select

									type="text"
									id="type"
									value = {type}
									onChange = { (event) => 
										{
											setType(event.target.value) }
										}
									onBlur = { (event) => updateValue(event.target.id) }
							
									required
									isInvalid={!hasType}
									isValid  = { hasType }
									size="sm"
								>
									<option value =""> Please choose one </option>
									<option value = "DC"> DC</option>
									<option value = "DCDA"> DCDA </option>
									<option value = "DCDAII"> DCDA-II </option>
									<option value = "RP"> RP </option>
									<option value = "RPDA"> RPDA </option>
									<option value = "RPDAII"> RPDA-II</option>
									<option value="PVB"> PVB </option>
									<option value="SVB"> SVB </option>
									<option value="AVB"> AVB </option>
									<option value = "AG"> AG </option>
								</Form.Select>
							</Form.Group>
							<br/>
						</Col>
						
					</Row>
				

				</Form>
			}
			
						
				
			
		</>
		)
	
}