import {
  ASSEMBLY_LIST,
} from "../../actions/Address";

const _nullAssemblylist = []
                 
export default (state = _nullAssemblylist, { type, list}) => {
  Object.freeze(state);
  switch (type) {
    case ASSEMBLY_LIST:
      return list;
    default:
      return state;
  }
};