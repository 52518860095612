import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import Card from 'react-bootstrap/Card';
import { useState, useEffect, useRef, useCallback} from "react";
import Badge from 'react-bootstrap/Badge';
import { updateTargetElement, resetUpdatedValue }  from "../../actions/NumberPad"
import {useSelector, useDispatch} from "react-redux";

import {
		updateAssembly, 
		updateTestType, 
		updateSystem, 
		updateApproved, 
		updateDoubleCheck, 
		updateReducedPressure, 
		updateXVBA, 
		updateAG, 
		updateFDoubleCheck,
		updateFRP, 
		updateFXVBA, 
		updateRemarks, 
		updateRepairs,
		sendTestReportToWaterDistrict
} from "../../actions/report"


export default function FinalDoubleCheck(){
	const dispatch = useDispatch()
	const [modalSpinner, setModalSpinner] = useState(false);
	const report = useSelector((state) => state.report)

	const currentPadTarget = useSelector((state) => state.numberPad)
	const updatedValue = useSelector((state) => state.updatedVal)
 

	/*value variables*/
	const [final_check1, setFinalCheck1] = useState(null); 
	const [final_check2, setFinalCheck2] = useState(null); 

	const [final_check1Tight, setFinalCheck1Tight] = useState(false); 
	const [final_check2Tight, setFinalCheck2Tight] = useState(false); 

	/*validation variables*/
	const [hasFinalCheck1, setHasFinalCheck1] = useState(false); 
	const [hasFinalCheck2, setHasFinalCheck2] = useState(false); 


	const [systemPass, setSystemPass] = useState(null)
	const [systemFail, setSystemFail] = useState(null); 



	/*determine if leaked/pass*/
	useEffect(()=>{
		if(final_check1 >= 1.0 ){
			setFinalCheck1Tight(true)
		}else{
			setFinalCheck1Tight(false)
		}

		if(final_check2 >= 1.0){
			setFinalCheck2Tight(true)
		}else{
			setFinalCheck2Tight(false)
		}
	}, [final_check1, final_check2])

	/*validation variables*/
	useEffect(()=>{
		if(final_check1 == null || final_check1 == 0){
			setHasFinalCheck1(false)
		}else{
			setHasFinalCheck1(true)
		}

		if(final_check2 == null || final_check2 == 0){
			setHasFinalCheck2(false)
		}else{
			setHasFinalCheck2(true)
		}
	}, [final_check1, final_check2])

	/*get initial values*/
	useEffect(()=>{
		if(report != null){
			setSystemPass(report.repair_pass)
			setSystemFail(report.repair_fail)
			setFinalCheck1(report.repair_rpa_double_c1_psid); 
			setFinalCheck2(report.repair_rpa_double_c2_psid); 
			document.querySelector("#final_check1_psid").value = report.repair_rpa_double_c1_psid; 
			document.querySelector("#final_check2_psid").value = report.repair_rpa_double_c2_psid; 
			document.querySelector("#finalPass").checked = report.repair_pass
		}
	},[report] )

	function update(){
		let myobj = {}
		myobj.pass = document.querySelector("#finalPass").checked;
		myobj.fail = document.querySelector("#finalFail").checked;  
		myobj.check1 = {}; 
		myobj.check2 = {}; 
		myobj.check1.psid = document.querySelector("#final_check1_psid").value; 
		if(document.querySelector("#final_check1_psid").value == null){
			myobj.check1.tight = false; 
			myobj.check1.leaked = false; 
		}else if(document.querySelector("#final_check1_psid").value >= 1){
			myobj.check1.tight = true; 
			myobj.check1.leaked = false; 
		}else{	
			myobj.check1.tight = false; 
			myobj.check1.leaked = true; 
		}

		myobj.check2.psid = document.querySelector("#final_check2_psid").value; 
		if(document.querySelector("#final_check2_psid").value  == null){
			myobj.check2.tight = false; 
			myobj.check2.leaked = false; 
		}else if(document.querySelector("#final_check2_psid").value >= 1){
			myobj.check2.tight = true; 
			myobj.check2.leaked = false; 
		}else{	
			myobj.check2.tight = false; 
			myobj.check2.leaked = true; 
		}
		dispatch( updateFDoubleCheck(report.id, myobj) ).then((data, err)=>{
			console.log("updated final double check")
		})
	}	
	
	/*redirects values to html elements*/
	useEffect(()=>{
		if(updatedValue){
			setFinalCheck1(document.querySelector("#final_check1_psid").value)
			setFinalCheck2(document.querySelector("#final_check2_psid").value)
			dispatch( resetUpdatedValue())
		}
	}, [updatedValue])

	/*checks if both inputs are valid to be sent to the DB*/
	const [watch, setWatch] = useState(false)
	useEffect(()=>{
		if(currentPadTarget == "#final_check1_psid" || currentPadTarget == "#final_check2_psid"){
			setWatch(true)
		}else{
			setWatch(false)
		}
		
		if(watch){
			update()
		}
	}, [currentPadTarget])

	const redirectKeyboard = (event)=>{
		console.log("redirecting: ", event.target.id)
		dispatch( updateTargetElement(event.target.id) ).then((data, err) =>{
			if(err){
				console.log(err)
			}
		})
	}
	return(
		<>
			<Modal show={modalSpinner}>
				<Modal.Body>
					<Spinner />
				</Modal.Body>
			</Modal>
			
			<Row>
				<Col>
					<h6> Results </h6>
				</Col>
				<Col>
					<Form.Check 
						id ="finalPass"  
						label="Pass" 
						checked = {systemPass} 
						onChange={ 
							(event) => {
								if(event.target.checked){
									setSystemFail(false)
								}
								setSystemPass(event.target.checked)
							}
						}
						onBlur = {
							() => update()
						}
					/>
					</Col>
					<Col>
					<Form.Check id = "finalFail" label="Fail"   checked = {systemFail} 
					onChange={ 
						(event) => {
							if(event.target.checked){
								setSystemPass(false)
							}
							setSystemFail(event.target.checked)
						}
					}
					onBlur = {
							() => update()
						}
				/>
				</Col>
			</Row>
			
			<Row>
				<Col>
					<h6> Double Check </h6>	
				</Col>
			</Row>
			<Row>
				
				<Col>
					<Form.Label> Check #1 </Form.Label>
					<InputGroup>
						<Form.Control 
							id="final_check1_psid"
							required 
							isInvalid = { !hasFinalCheck1}
							isValid = { hasFinalCheck1 }
							readOnly 
							onFocus = { redirectKeyboard}
						/>
						
					
						{
									
							final_check1Tight ? 
								<Badge bg="primary">Tight</Badge>
							:
								<Badge bg="secondary"> Leaked </Badge>

						}
					
				</InputGroup>
				<br/>
			<Form.Label> Check #2</Form.Label>
			<InputGroup>
				
				<Form.Control 
					id = "final_check2_psid"
					required 
					isInvalid = { !hasFinalCheck2}
					isValid = { hasFinalCheck2 }
					readOnly 
					onFocus = { redirectKeyboard }
				/>
				
				{
							
					final_check2Tight ? 
						<Badge bg="primary">Tight</Badge>
					:
						<Badge bg="secondary"> Leaked </Badge>

				}
			</InputGroup>
				</Col>
			</Row>
		</>
	)
}