import {
  RECEIVE_ASSEMBLY,  CLEAR_ASSEMBLY
} from "../../actions/AlternativeDetails.js";

const _nullAssembly= null
                
export default (state = _nullAssembly, { type, assembly }) => {
  Object.freeze(state);
  switch (type) {
    case RECEIVE_ASSEMBLY:
      return assembly;
    case CLEAR_ASSEMBLY: 
      return _nullAssembly; 
    case "":
      return _nullAssembly; 
    default:
      return state;
  }
};