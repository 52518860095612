import Accordion from 'react-bootstrap/Accordion';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Spinner from 'react-bootstrap/Spinner';
import { useState, useEffect, useRef, useCallback} from "react";
import {useSelector, useDispatch} from "react-redux";
import Invoice from "../Invoice.js"

import ServiceAddressCard from "../ServiceAddressCard"
import { getAddressDetails, getCustomerDetails, getServices, getTestReport, createConfinedEntry } from "../../actions/Address"
import { getInvoiceInformation } from "../../actions/invoice"

export default function StopCard(props){
	const [modalShow, setModalShow] = useState(false)
	const [modalBody, setModalBody] = useState(<Invoice />)
	const [modalType, setModalType] = useState(""); 
	const dispatch = useDispatch();

	function loadInvoice(){
		console.log("Requesting a new invoice...", props.stop.invoiceID); 
		dispatch( getInvoiceInformation(props.stop.invoiceID) ).then((data, err) =>{
			console.log("Done!")
			setModalBody(
				<Invoice stop = {props.stop} closeModal = { () => {
					setModalShow(false); 
					setModalType("")
				}
			} type="PLUMBING" />) 

			setModalShow(true)
		})
		
	}
	function newConfinedEntry(){
		dispatch( createConfinedEntry(props.stop.serviceAddressID)).then( (data, err) =>{
			console.log("done creating a new confined entry..."); 
			setModalType(""); 
			
		})
	}

	useEffect(()=>{
			switch(modalType.toUpperCase()){
				case "INVOICE": 
						loadInvoice(); 
						break; 
				case "NEWASSEMBLY": 
						break;
				case "CONFINED": 
						newConfinedEntry(); 
						break; 
				default: 
					console.log("nothing to show...");
					break; 
			}
	}, [modalType])

	return(
		<>
			<Modal show= {modalShow} fullscreen>
				<Modal.Header closeButton onHide = { () => {
					setModalShow(false)
					setModalType("")
				} }>
				</Modal.Header>
				<Modal.Body>
					{modalBody}
				</Modal.Body>
			</Modal>
			<Accordion.Item eventKey = {props.index} >
				<Accordion.Header>
					<Row>
						<Col>
							<ServiceAddressCard  stop={props.stop}/>
						</Col>
						<Col>
							<strong>
								{props.stop.service_type}
							</strong>
						</Col>
						<Col>
							<strong>{props.stop.state}</strong>
						</Col>
					</Row>
				</Accordion.Header>
				<Accordion.Body >
					<ButtonGroup>
						<Button size="sm" variant="success" onClick ={ ()=> setModalType("invoice") }> Invoice </Button>
					</ButtonGroup>
				</Accordion.Body>
			</Accordion.Item>
		</>
	)
}