import {
  RECEIVE_CURRENT_USER,
  UPDATE_SESSION, 
  LOGOUT_CURRENT_USER, 
  FAILED_LOGIN
} from "../../actions/session";

const _nullSession = { username: null, testList: [], success: null}
const _failed = { username: null, testList: [], success:false }

                 
export default (state = _nullSession, { type, user }) => {
  Object.freeze(state);
  switch (type) {
    case RECEIVE_CURRENT_USER:
      window.localStorage.setItem("session", JSON.stringify(user)); 
      return user;
    case FAILED_LOGIN: 
      return _failed; 
    case LOGOUT_CURRENT_USER:
      window.localStorage.clear(); 
      return _nullSession;
    case UPDATE_SESSION:
      return user; 
    default:
      return state;
  }
};