import Accordion from 'react-bootstrap/Accordion';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import Col from 'react-bootstrap/Col';
import Tabs from 'react-bootstrap/Tabs'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Tab from 'react-bootstrap/Tab'
import Spinner from 'react-bootstrap/Spinner';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import { useState, useEffect, useRef, useCallback} from "react";
import StopDetails from "./StopDetails"
import AssemblyDetails from "./AssemblyDetails"
import InitialTestReport from "./InitialTestReport"; 
import RepairsTestReport from "./RepairsTestReport"
import FinalTestReport from "./FinalTestReport"
import ReviewTestReport from "./ReviewTestReport"
import NumberPad from "./NumberPad"

import { updateTargetElement, resetUpdatedValue }  from "../actions/NumberPad"
import {useSelector, useDispatch} from "react-redux";
import {
		updateAssembly, 
		updateTestType, 
		updateSystem, 
		updateApproved, 
		updateDoubleCheck, 
		updateReducedPressure, 
		updateXVBA, 
		updateAG, 
		updateFDoubleCheck,
		updateFRP, 
		updateFXVBA, 
		updateRemarks, 
		updateRepairs,
		sendTestReportToWaterDistrict, 
		notTested
} from "../actions/report"

import { getAssembly} from "../actions/AlternativeDetails"

export default function AssemblyReport(props){
	const assembly = useSelector( (state)=> state.assembly)
	const report = useSelector( (state) => state.report)
	const dispatch = useDispatch(); 
	const [key, setKey] = useState('details');
	const [loader, setLoader] = useState(false)
	const [assemblyLoader, setAssemblyLoader] = useState(true);
	const selectedDate = useSelector( (state) => state.selectedDate )
	const [reason, setReason] = useState(null)
	console.log(props )
	useEffect(()=>{
		setAssemblyLoader(true)
		dispatch( getAssembly(props.stop.assemblyID) ).then((data, err)=>{
			setAssemblyLoader(false)

		})
	}, [])
	const [validated, setValidated] = useState(false)

	 const markAsNotTested = (event) => {
	 	
	 		console.log("Trying this form...")
	 		const form = event.currentTarget; 
	 		if(form.checkValidity() === false){
	 			event.stopPropagation();
	 			event.preventDefault(); 
	 		}else{
	 			dispatch( notTested(report.id, reason, props.stop.serviceID) ).then(() =>{
		 			props.refreshServiceList(); 
					props.closeModal()
				})
	 		}
	 	
	 		
	 	
	}


	function saveAssemblyDetails(){
		return new Promise((resolve, reject) =>{
			setLoader(true)
			let obj = {}; 
			obj.id = assembly.id; 
			obj.type = document.querySelector("#type").value; 
			obj.size= document.querySelector("#size").value; 
			obj.make = document.querySelector("#make").value; 
			obj.model = document.querySelector("#model").value;
			obj.serial =  document.querySelector("#serial").value; 
			obj.status = "E"; 
			obj.location = document.querySelector("#location").value; 
			dispatch(updateAssembly(report.id, obj) ).then((data, err) =>{
				setLoader(false)
				resolve()
			})
		})	
	}

	function getFinalInputs(){
		return new Promise( async (resolve, reject) =>{
			setLoader(true)
			let list = document.querySelectorAll('input[id]'); 
			console.log(list)
			let myobj = {}; 
			let resultsWrite = null; 
			console.log("Assembly type: ", assembly.assembly_type)

			myobj.pass = document.querySelector("#finalPass").checked;
			myobj.fail = document.querySelector("#finalFail").checked;  
			switch(assembly.assembly_type){
				case "DCDAII":
				case "DCDA": 
				case "DC": 
					myobj.check1 = {}; 
					myobj.check2 = {}; 
					myobj.check1.psid = document.querySelector("#final_check1_psid").value; 
					if(document.querySelector("#final_check1_psid").value == null){
						myobj.check1.tight = false; 
						myobj.check1.leaked = false; 
					}else if(document.querySelector("#final_check1_psid").value >= 1){
						myobj.check1.tight = true; 
						myobj.check1.leaked = false; 
					}else{	
						myobj.check1.tight = false; 
						myobj.check1.leaked = true; 
					}

					myobj.check2.psid = document.querySelector("#final_check2_psid").value; 
					if(document.querySelector("#final_check2_psid").value  == null){
						myobj.check2.tight = false; 
						myobj.check2.leaked = false; 
					}else if(document.querySelector("#final_check2_psid").value >= 1){
						myobj.check2.tight = true; 
						myobj.check2.leaked = false; 
					}else{	
						myobj.check2.tight = false; 
						myobj.check2.leaked = true; 
					}
					resultsWrite = await dispatch( updateFDoubleCheck(report.id, myobj) )
					break 
				case "RPDA":
				case "RP":
					myobj.opened_at = document.querySelector("#final_openedAt").value; 
					myobj.pressdrop = document.querySelector("#final_check1_psid").value; 
					if(document.querySelector("#final_openedAt").value == null || document.querySelector("#final_check1_psid").value == null){
						myobj.relief_pass = false; 
						myobj.relief_fail = false; 
					}else if(document.querySelector("#final_openedAt").value >= 2 && document.querySelector("#final_check1_psid").value >= 5) {
						myobj.relief_pass = true; 
						myobj.relief_fail = false; 
					}else{	
						myobj.relief_pass = false; 
						myobj.relief_fail = true; 
					}

					resultsWrite = await dispatch( updateFRP(report.id, myobj) )
					break 
				case "RPDAII":
					myobj.check1 = {}; 
					myobj.check2 = {}; 
					myobj.check1.psid = document.querySelector("#final_check1_psid").value; 
					if(document.querySelector("#final_check1_psid").value == null){
						myobj.check1.tight = false; 
						myobj.check1.leaked = false; 
					}else if(document.querySelector("#final_check1_psid").value >= 1){
						myobj.check1.tight = true; 
						myobj.check1.leaked = false; 
					}else{	
						myobj.check1.tight = false; 
						myobj.check1.leaked = true; 
					}

					myobj.check2.psid = document.querySelector("#final_check2_psid").value; 
					if(document.querySelector("#final_check2_psid").value){
						myobj.check2.tight = false; 
						myobj.check2.leaked = false; 
					}else if(document.querySelector("#final_check2_psid").value >= 1){
						myobj.check2.tight = true; 
						myobj.check2.leaked = false; 
					}else{	
						myobj.check2.tight = false; 
						myobj.check2.leaked = true; 
					}
					myobj.opened_at = document.querySelector("#final_openedAt").value; 
					myobj.pressdrop = document.querySelector("#final_check1_psid").value; 
					if(document.querySelector("#final_openedAt").value == null || document.querySelector("#final_check1_psid").value == null){
						myobj.relief_pass = false; 
						myobj.relief_fail = false; 
					}else if(document.querySelector("#final_openedAt").value >= 2 && document.querySelector("#final_check1_psid").value >= 5) {
						myobj.relief_pass = true; 
						myobj.relief_fail = false; 
					}else{	
						myobj.relief_pass = false; 
						myobj.relief_fail = true; 
					}


					resultsWrite =  Promise.all([
							await dispatch (updateFRP(report.id, myobj)  ), 
							await dispatch (updateFDoubleCheck(report.id, myobj) )
					])
					break;
				case "AVB": 
				case "SVB": 
				case "PVB": 
					myobj.pressdrop = document.querySelector("#final_pressdrop").value; 
					myobj.openedAt = document.querySelector("#final_openedAt").value
					resultsWrite = await dispatch( updateFXVBA(report.id, myobj) )
					break 

				default: 
				 	break; 
			}

			Promise.all( [resultsWrite] ).then((data, err) =>{
				setLoader(false)
				resolve()
			})
		})
	}

	function getInitialInput(){
		return new Promise ( async(resolve, reject) =>{
			setLoader(true)
			//testType 
			let testType = {}; 
			testType.type = document.querySelector("#testType").value; 

			//approved
			let myobj = {}; 
			myobj.approved_assembly = document.querySelector("#ap_assembly").checked; 
			myobj.approved_installation = document.querySelector("#ap_installation").checked; 
			myobj.approved_orientation = document.querySelector("#ap_orientation").checked; 
			myobj.approved_airgap = document.querySelector("#ap_airgap").checked; 
			myobj.approved_usc = document.querySelector("#ap_usc").checked

			//system
			let system = {}
			system.psi = document.querySelector("#system").value; 
			system.dmr = String( document.querySelector("#dmr").value ); 
			system.pass = document.querySelector("#systemPass").checked; 
			system.fail = document.querySelector("#systemFail").checked; 

			//remarks
			let robj = {}; 
			robj.so1 = document.querySelector("#so1").checked; 
			robj.so2 = document.querySelector("#so2").checked; 
			robj.upstream = document.querySelector("#upstream").checked; 
			robj.restored = document.querySelector("#restored").checked; 

			let myResults = {}; 
			let resultToWait = null; 
			//save results 
			switch(assembly.type){
				case "DCDA":
				case "DCDAII":
				case "DC": 
					myResults.check1_psid = document.querySelector("#check1_psid").value;
					if(document.querySelector("#check1_psid").value == null){
						myResults.check1_tight = false; 
						myResults.check1_leaked = false; 
					}else if(document.querySelector("#check1_psid").value >= 1){
						myResults.check1_tight = true; 
						myResults.check1_leaked = false; 
					}else{	
						myResults.check1_tight = false; 
						myResults.check1_leaked = true; 
					}
					myResults.check2_psid = document.querySelector("#check2_psid").value;
					if(document.querySelector("#check2_psid").value == null){
						myResults.check2_tight = false; 
						myResults.check2_leaked = false; 
					}else if(document.querySelector("#check2_psid").value >= 1){
						myResults.check2_tight = true; 
						myResults.check2_leaked = false; 
					}else{	
						myResults.check2_tight = false; 
						myResults.check2_leaked = true; 
					}
					myResults.check1_typeII = document.querySelector("#check1_typeII").checked; 
					resultToWait = await dispatch (updateDoubleCheck(report.id, myResults) )
					break; 
				case "RPDA":
				case "RP": 
					myResults.pressdrop = document.querySelector("#pressdrop").value; 
					myResults.openedAt = document.querySelector("#openedAt").value; 
					if(document.querySelector("#pressdrop").value == null || document.querySelector("#openedAt").value == null){
						myResults.relief_pass = false
						myResults.relief_fail = false
					}
					else if(document.querySelector("#pressdrop").value >=5 && document.querySelector("#openedAt").value >=2){
						myResults.relief_pass = true
						myResults.relief_fail = false
					}else{
						myResults.relief_pass = false
						myResults.relief_fail = true
					}
					resultToWait = await dispatch (updateReducedPressure(report.id, myResults) )
					break;  

				case "RPDAII": 
					myResults.pressdrop = document.querySelector("#check1_psid").value; 
					myResults.openedAt = document.querySelector("#openedAt").value; 
					if(document.querySelector("#check1_psid").value == null || document.querySelector("#openedAt").value == null){
						myResults.relief_pass = false
						myResults.relief_fail = false
					}
					else if(document.querySelector("#check1_psid").value >=5 && document.querySelector("#openedAt").value >=2){
						myResults.relief_pass = true
						myResults.relief_fail = false
					}else{
						myResults.relief_pass = false
						myResults.relief_fail = true
					}

					myResults.check1_psid = document.querySelector("#check1_psid").value;
					if(document.querySelector("#check1_psid").value == 0){
						myResults.check1_tight = false; 
						myResults.check1_leaked = false; 
					}else if(document.querySelector("#check1_psid").value >= 1){
						myResults.check1_tight = true; 
						myResults.check1_leaked = false; 
					}else{	
						myResults.check1_tight = false; 
						myResults.check1_leaked = true; 
					}

					myResults.check2_psid = document.querySelector("#check2_psid").value;
					if(document.querySelector("#check2_psid").value == 0){
						myResults.check2_tight = false; 
						myResults.check2_leaked = false; 
					}else if(document.querySelector("#check2_psid").value >= 1){
						myResults.check2_tight = true; 
						myResults.check2_leaked = false; 
					}else{	
						myResults.check2_tight = false; 
						myResults.check2_leaked = true; 
					}
					myResults.check1_typeII = document.querySelector("#check1_typeII").checked; 
					resultToWait =  Promise.all([
							await dispatch (updateReducedPressure(report.id, myResults) ), 
							await dispatch (updateDoubleCheck(report.id, myResults) )
						])
					break; 
				case "SVB": 
				case "PVB": 
					myResults.pressdrop = document.querySelector("#pressdrop").value; 
					myResults.openedAt = document.querySelector("#openedAt").value; 
					resultToWait = await dispatch (updateXVBA(report.id, myResults) )
					break; 
				case "AG": 
					myResults.pipesize = document.querySelector("#pipesize").value;
					myResults.physical_separation = document.querySelector("#physical").value
					resultToWait = await dispatch(updateAG(report.id, myResults) ) 
					break; 
				default:
					console.log("The type was not found...", assembly.assembly_type)
					//store all of the results 
					resultToWait = new Promise ((resolve, reject) =>
						resolve() 
					); 
			}
	
			setLoader(true)
			Promise.all([
				await dispatch ( updateTestType( report.id, testType) ),
				await dispatch( updateSystem(report.id, system) ), 
				await dispatch( updateApproved(report.id, myobj) ), 
				await dispatch( updateRemarks( report.id, robj) ), 
				resultToWait
			]).then((data, err) =>{
				setLoader(false); 
				resolve()
			})
		})
			
	}

	function recordRepairs(){
		return new Promise( (resolve, reject) =>{
			setLoader(true)
			let obj = {}; 
			obj.comment = document.querySelector("#comment").value; 
			obj.cleaned = document.querySelector("#cleaned").checked; 
			obj.repaired = document.querySelector("#repaired").checked; 
			obj.disc =	document.querySelector("#disc").checked 
			obj.spring = document.querySelector("#spring").checked
			obj.guide = document.querySelector("#guide").checked  
			obj.diaphragm = document.querySelector("#diagh").checked 
			obj.seat =	document.querySelector("#seat").checked 
			obj.o_ring = document.querySelector("#o_ring").checked  
			obj.module = document.querySelector("#module").checked 
			obj.rubber_kit = document.querySelector("#rubber_kit").checked  
			obj.air_disc = document.querySelector("#air_disc").checked 
			obj.air_spring = document.querySelector("#air_spring").checked 
			obj.check_disc = document.querySelector("#check_disc").checked 
			obj.check_spring = document.querySelector("#check_spring").checked 
			obj.float = document.querySelector("#float").checked 

			dispatch( updateRepairs( report.id, obj) ).then(( data, err) =>{
				setLoader(false)
				resolve(); 
			})

		})
			
	}

	const onHideFnc = async () => {
			return new Promise( async(resolve, reject) =>{
				console.log("This is the key: ", key)
				let towaitfor = null; 
				switch(key.toUpperCase()){
					case "DETAILS": 
						towaitfor = await saveAssemblyDetails(); 
						break; 
					case "INITIAL": 
						towaitfor = await getInitialInput(); 
						break; 
					case "REPAIRS": 
						towaitfor = await recordRepairs(); 
						break; 
					case "FINAL": 
						towaitfor = await getFinalInputs(); 
						break;
					default: 
						console.log("pppp in review..."); 	
				}
				Promise.all([
					towaitfor
				]).then( (data, err) =>
					resolve()
				)

			})
	}
	const [needPassFailAlert, setNeedPassFailAlert] = useState(false)
	function sendToWaterDistrict(){
		return new Promise( (resolve, reject) =>{
			if(!document.querySelector("#systemFail").checked && !document.querySelector("#systemPass").checked) {
				setNeedPassFailAlert(true)	
			}else{
				onHideFnc().then( (data, err) =>{
					dispatch( sendTestReportToWaterDistrict(report.id, selectedDate) ).then(
						(data, err) =>{
							props.refreshServiceList(); 
							props.closeModal()
						}
					)
				})
			}	
		})
	}
	const [whynot, setWhyNot] = useState(false)

	return(
		<>

		<Modal.Header closeButton onHide={
			()=>{
				if(key == "details"){
					saveAssemblyDetails()
				}
				if(key == "initial"){
					getInitialInput()
				}
				if(key == "Repairs"){
					//getFinalInputs()
				}
				if(key == "final"){
					getFinalInputs()
				}
				
				props.refreshServiceList(); 
				props.closeModal()
			}
		}>
			<Modal.Title>Test Report</Modal.Title>
		</Modal.Header>

		<Modal.Body >
			
				
					<>
						<Tabs defaultActiveKey="details"
			     			onSelect = { (key)=> 
			     					{
			     						dispatch( updateTargetElement(null)).then((data, err)=>{
			     							setKey(key)
			     						})
			     						
			     					}
			     			}
			     			activeKey={key}
			      			className="mb-3"
			      			fill ={true}
			      			justify = {true}>

							<Tab eventKey="details" title="DEVICE" unmountOnExit /*onExit = { ()=> saveAssemblyDetails() }*/ >
			      				{
			      					assemblyLoader ?

			      						<> </>
			      					:
			      						<AssemblyDetails />
			      				}
								
								
								
							</Tab>

							<Tab eventKey="initial" title="INITIAL" unmountOnExit  >
								{
									loader ?
										<Spinner />
									:
										<InitialTestReport />
								}
							</Tab>


							<Tab eventKey="Repairs" title="REPAIRS" unmountOnExit >
								{
									loader ?
										<Spinner />
									:
								
									<RepairsTestReport/>
								}
							</Tab>
							<Tab eventKey="final" title="FINAL" unmountOnExit>
								{
									loader ?
										<Spinner />
									:
										<FinalTestReport  />	
								}
								
							</Tab>
							{/*<Tab eventKey="REVIEW" title="Review" unmountOnExit>
								{
									loader ?
										<Spinner />
									:
										<ReviewTestReport/>
								}
									
							</Tab>*/}
						</Tabs>
					</>		
		</Modal.Body>
		<Modal.Footer>
			<Alert show={needPassFailAlert}>
				Please select a pass or fail
			</Alert>
			<Modal show = {whynot} centered backdrop static fullscreen = 'md-down'>
				<Modal.Header closeButton onHide = {
					()=>setWhyNot(false) 
				}>
					<Modal.Title> Not Tested </Modal.Title>
				</Modal.Header>
				<Form  onSubmit = {markAsNotTested}>
					<Modal.Body >
						<Form.Label> Why was this assembly unable to be tested?</Form.Label>
						<Form.Control
							type="text"
							value={reason}
							onChange = {(event)=>setReason(event.target.value) }
							required
						 />
					</Modal.Body>
					<Modal.Footer>
						<Button type="submit"> Set as not tested </Button>
					</Modal.Footer>
				</Form>
			</Modal>
			<NumberPad />
			<ButtonGroup>
				
				<Button variant = "success" onClick = { async ()=> await sendToWaterDistrict() }> Send to water district </Button>	
				<Button variant="warning" onClick = { async ()=> setWhyNot(true)}> Did not test</Button>
			</ButtonGroup>
		</Modal.Footer>	
		</>
	)
}