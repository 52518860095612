
import { combineReducers } from 'redux';
import session from './session/session';
import invoice from "./invoice/invoice"; 
import assembly from "./assembly/assembly"; 
import message from "./message/message"; 
import confinedEntry from "./confinedEntry/confinedEntry"; 
import googleRoutes from "./googleRoutes/googleRoutes"; 
import lineitems from "./lineitems/lineitems"
import numberPad from "./numberPad/numberPad"
import updatedVal from "./numberPad/updatedVal"
import testlist from "./testlist/testlist"
import assemblylist from "./assemblyList/assemblyList"
import report from "./report/report"
import invoiceItems from "./invoiceItems/invoiceItems"
import billingAddress from "./billingAddress/billingAddress"; 
import selectedDate from "./selectedDate/selectedDate"
import confinedEntryList from "./confinedEntryList/confinedEntryList"
import testerslist from "./testerlist/testerlist"
import assembly_id from "./assembly/assemblyID"
import assembly_type from "./assembly/assembly_type"
import report_id from "./testReport/report_id"
import check1_psid from "./testReport/check1_psid"
import check2_psid from "./testReport/check2_psid"
import system_psi from "./testReport/system_psi"
import dmr from "./testReport/dmr"
import final_check1 from "./testReport/final_check1"
import final_check2 from "./testReport/final_check2"

import rv_pressdrop from "./testReport/rv_pressdrop"
import rv_opened from "./testReport/rv_opened"
import final_rvPressdrop from "./testReport/final_rvPressdrop"
import final_rvOpened from "./testReport/final_rvOpened"

/*xvba*/
import air_opened from "./testReport/air_opened"
import final_air_opened from "./testReport/final_air_opened"
import check_pressdrop from "./testReport/check_pressdrop"
import final_check_pressdrop from "./testReport/final_check_pressdrop"

/*ag*/
import pipesize from "./testReport/pipesize"
import physical_separation from "./testReport/physical_separation"
import final_pipesize from "./testReport/final_pipesize"
import final_physical_separation from "./testReport/final_physical_separation"

export default combineReducers({
  session, 
  invoice, 
  assembly,
  message, 
  confinedEntry, 
  googleRoutes, 
  lineitems, 
  numberPad, 
  updatedVal,
  testlist,
  assemblylist, 
   invoiceItems, 
   billingAddress, 
   selectedDate,
   confinedEntryList, 
   testerslist, 
   report, 
   assembly_id, 
   assembly_type, 
   report_id, 
   check1_psid, 
   check2_psid, 
   system_psi, 
   dmr, 
   final_check1, 
   final_check2, 
   rv_pressdrop,
   rv_opened, 
   final_rvPressdrop, 
   final_rvOpened, 
   air_opened, 
   final_air_opened, 
   check_pressdrop, 
   final_check_pressdrop, 
   pipesize, 
   physical_separation, 
   final_pipesize, 
   final_physical_separation
})