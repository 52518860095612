import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useState, useEffect, useRef, useCallback} from "react";
import Badge from 'react-bootstrap/Badge';
import DoubleCheck from "./forms/DoubleCheck"
import ReducedPressure from "./forms/ReducedPressure"; 
import Approved from "./forms/Approved"
import XVBA from "./forms/XVBA"
import AG from "./forms/AG"
import TestOverall from "./forms/TestOverall"
import CommentsRemarks from "./forms/CommentsRemarks"
import {useSelector, useDispatch} from "react-redux";
import NumberPad from "./NumberPad"
import TestType from "./TestType"


export default function InitialTestReport(props){
	const assemblyType= useSelector((state)=>state.assembly.type); 
	const [formToShow, setFormToShow] = useState(<></>)
	const [pass, setPass] = useState(false)
	const [passClass, setPassClass] = useState("secondary")
	const [failClass, setFailClass] = useState("secondary")
	const [targetElement, setTargetElement] = useState(""); 
	
	useEffect(()=>{
		console.log("This is the target element: ", targetElement)
	}, [targetElement])

	useEffect(()=>{
		if(pass){
		}else{
		}
	}, [pass])


	useEffect(()=>{
		switch(assemblyType){
			case "DC":
				setFormToShow(<><DoubleCheck /> </>)
				break; 
			case "DCDA": 
				setFormToShow(<><DoubleCheck /> </>)
				break;  
			case "DCDAII": 
				setFormToShow(<><DoubleCheck /> </>)
				break; 
			case "RP": 
				setFormToShow(<><ReducedPressure/> </>)
				break; 
			case "RPDA": 
				setFormToShow(<>
						<ReducedPressure/>
					</>)
				break;
			case "RPDAII": 
				setFormToShow(<>
						<DoubleCheck />
						<ReducedPressure/> 
					</>)
				break;
			case "PVB": 
				setFormToShow(<><XVBA /> </>)
				break;
			case "SVB": 
				setFormToShow(<><XVBA /> </>)
				break;
			case "AVB": 
				setFormToShow(<><XVBA /> </>)
				break;
			case "AG":
				setFormToShow(<><AG /> </>)
				break;
			default:
		}
	}, [assemblyType])

	const [testPass, setTestPass] = useState(false)
	return(
		<Container>
			
			<Row>
				<Col>
					<TestType />
					
				</Col>
			</Row>
			<Row>
				<Col>
					<hr/>
					{formToShow}
				</Col>
				
			</Row>
			
			<Row>
				<Col sm md = {6}>
					<CommentsRemarks/>
				</Col>
				
				<Col>
						<Approved />
				</Col>
			</Row>
			<Row>
				
			</Row>
		</Container>

	)
}