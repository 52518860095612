import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Alert from 'react-bootstrap/Alert';
import { useState, useEffect, useRef, useCallback} from "react";
import {useSelector, useDispatch} from "react-redux";
import { getInvoiceInformation, addItem, requestLineItemList} from "../actions/invoice.js"
import InputGroup from 'react-bootstrap/InputGroup';

export default function NewItem(props){
	const [itemSKU, setItemSKU] = useState(''); 
	const [itemSelected, setItemSelected] = useState(-1); 
	const [unitPrice, setUnitPrice] = useState(57); 
	const [quantity, setQuantity] = useState(1); 
	const [validated, setValidated] = useState(false); 
	const [errorMessage, setErrorMessage] = useState(""); 
	const [showErrors, setShowErrors] = useState(""); 
	const [formSubmitted, setFormSubmitted] = useState(false); 
	const [itemValid, setItemValid] = useState(true); 
	const billingAddressID = useSelector((state)=>state.billingAddress.id); 
	const [description, setDescription] = useState(""); 
	const [descriptionClass, setDescriptionClass] = useState("hide")
	const [descriptionRequired, setDescriptionRequired]  = useState(false); 
	const itemlist = useSelector((state) => state.lineitems.list); 
	const dispatch = useDispatch();

	function buildObject(){

		let obj = {};
		obj.item = itemlist[itemSelected].id;  
		obj.quantity = quantity; 
		obj.unitPrice = unitPrice; 
		obj.description = `${itemlist[itemSelected].itemDescription}. ${description}`; 
		obj.taxable = itemlist[itemSelected].taxable; 
		return obj; 
	}
	function checkSelection(){
		if(itemSelected == -1){
			return false; 
		}else{
			return true; 
		}
	}

	useEffect(()=>{
		if(formSubmitted){
			if(itemSelected == -1){
				setErrorMessage("Please choose a line item to add"); 
				setShowErrors(true); 
				setItemValid(false); 
			}else{
				setShowErrors(false); 
				setItemValid(true); 
			}
		}else{
			setShowErrors(false); 
			setItemValid(true); 
		}
	}, [formSubmitted])


	useEffect(()=>{
		if(itemSelected != -1){
			console.log("this is the item selected: ", itemSelected); 
			setUnitPrice(itemlist[itemSelected].unitPrice)
		}
		
	}, [itemSelected, quantity])

	useEffect(()=>{
		dispatch(requestLineItemList()).then((data, err) =>{
			console.log("done  getting the items...")
		})
	}, [])

	const handleSubmit = (event)=>{
		event.preventDefault(); 
		setValidated(false); 
		setFormSubmitted(true); 
		const form = event.currentTarget;
	    if (form.checkValidity() === false || !checkSelection() ) {
	      event.stopPropagation();
	    }else{
	    	console.log("New item object: ", buildObject());
	    	setLoadingShow(true)
	    	dispatch( addItem(props.invoiceID, buildObject(), billingAddressID, props.customerID) ).then((data, err)=>{
	    		if(data){
	    			props.closeModal(); 
	    		}
	    		setLoadingShow(false); 
	    	}) 
	    }
	    setValidated(true);
	}
	const [loadingShow, setLoadingShow] = useState(false); 
	const renderItemOptions = itemlist.map((item, index)=>(
		<option value={index}> {item.itemDescription} </option>
	))

	return(
		<>
			<Modal size="xl" show={loadingShow} centered backdrop="static">
				<Modal.Body>
					<Container bsPrefix="loading-height">
						<Row>
							<Col></Col>
							<Col>
								<Spinner animation="border"  animation="border" variant="info"/>
							</Col>
							<Col></Col>
						</Row>
					</Container>
				</Modal.Body>
			</Modal>	
			<Form noValidate validated={validated} onSubmit={handleSubmit}>
				<Alert variant="danger" show={showErrors}>
					{errorMessage}
				</Alert>
				<Form.Group>
					<Form.Label>
						Item Name
					</Form.Label>
					<Form.Select  value={itemSelected} onChange={ (event) => setItemSelected(event.target.value)  } required   isInvalid={ !itemValid }>
						{/*<option value="select" > Select One </option>
						<option value="100">Backflow Test</option>
						<option value="104"> 4 in or smaller-Backflow Test</option>
						<option value="106"> 6 in or larger-Backflow Test </option>
						<option value="400"> Backflow install </option>
						<option value="300"> Confined Space Entry </option>
						<option value="310"> Additional Confined Space Entry</option>
						<option value="200"> Backflow Rebuild</option>
						<option value="320">Pump Equipment </option>
						<option value="-1"> Parts / Other</option>*/}

						{ itemlist.length ? 
								<>
									<option value={-1}>Please Select One </option>
									{renderItemOptions}
								</>
							: 
								<>
								</>
						}
					</Form.Select>

					<Form.Label> Quantity</Form.Label>
					<Form.Control type="number" min="1" step="1.0" value={quantity} onChange={ (event)=> setQuantity(event.target.value)} required />
					<Form.Label>Unit Price</Form.Label>
					<InputGroup>
					<InputGroup.Text>$</InputGroup.Text>
						<Form.Control value={unitPrice} onChange={ (event)=>setUnitPrice(event.target.value)} required/>
						
					</InputGroup>

				</Form.Group>

				<Form.Group>
					<Form.Label>Description</Form.Label>
					<Form.Control  value={description} 
					onChange = { (event)=> setDescription(event.target.value) } 
					required = { descriptionRequired}
					maxLength = {400}
					/>
				</Form.Group>

				<Modal.Footer>
				<Button type="submit"> Add </Button>
				<Button variant="secondary" onClick={ ()=> props.closeModal() }> Cancel </Button>
				</Modal.Footer>
			</Form>

		</>
	); 
}