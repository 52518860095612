import {
  CONFINED_LIST,
} from "../../actions/Address";

const _nullConfinedlist = []
                 
export default (state = _nullConfinedlist, { type, list}) => {
  Object.freeze(state);
  switch (type) {
    case CONFINED_LIST:
      return list;
    default:
      return state;
  }
};