import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner'
import Card from 'react-bootstrap/Card';
import { useState, useEffect, useRef, useCallback} from "react";
import Badge from 'react-bootstrap/Badge';
import { updateTargetElement, resetUpdatedValue }  from "../../actions/NumberPad"
import {useSelector, useDispatch} from "react-redux";
import {updateDoubleCheck} from "../../actions/report"

export default function DoubleCheck(){
	const dispatch = useDispatch()
	const report = useSelector((state) => state.report)
	const [modalSpinner, setModalSpinner] = useState(false); 
	const currentPadTarget = useSelector((state) => state.numberPad)
	const updatedValue = useSelector((state) => state.updatedVal)
	const [formFocus, setFormFocus] = useState(true)
	const [watch, setWatch] = useState(false)

	useEffect(()=>{
		console.log("This is the current pad target: ", currentPadTarget)
		if(currentPadTarget == "#check1_psid" || currentPadTarget == "#check2_psid"){
			setFormFocus(true)
		}else{
			setFormFocus(false)
		}
	}, [currentPadTarget]) 



	/*value variables*/
	const [check1, setCheck1] = useState(null); 
	const [check2, setCheck2] = useState(null); 
	const [typeII, setTypeII] = useState(null); 
	const [check2_typeII, setCheck2TypeII] = useState(null); 
	const [check1Tight, setCheck1Tight] = useState(false); 
	const [check2Tight, setCheck2Tight] = useState(false); 
const [hasSystem, setHasSystem] = useState(false); 
	const [hasDmr, setHasDmr] = useState(false)
	/*validation variables*/
	const [hasCheck1, setHasCheck1] = useState(false); 
	const [hasCheck2, setHasCheck2] = useState(false); 


	/*determine if leaked/pass*/
	useEffect(()=>{
		if(check1 >= 1.0 ){
			setCheck1Tight(true)
		}else{
			setCheck1Tight(false)
		}

		if(check2 >= 1.0){
			setCheck2Tight(true)
		}else{
			setCheck2Tight(false)
		}
	}, [check1, check2])

	/*validation variables*/
	useEffect(()=>{
		if(check1 == null || check1 == 0){
			setHasCheck1(false)
		}else{
			setHasCheck1(true)
		}

		if(check2 == null || check2 == 0){
			setHasCheck2(false)
		}else{
			setHasCheck2(true)
		}
	}, [check1, check2])



	/*get initial values*/
	useEffect(()=>{
		if(report != null){
			setTypeII(report.RPA_typeII); 
			setCheck1(report.RPA1_psid); 
			setCheck2(report.RPA2_psid); 
			document.querySelector("#check1_psid").value = report.RPA1_psid; 
			document.querySelector("#check2_psid").value = report.RPA2_psid; 
		}
	},[report] )

	const update = () => {
			let myResults = {}
			myResults.check1_psid = document.querySelector("#check1_psid").value;
			if(document.querySelector("#check1_psid").value == null){
				myResults.check1_tight = false; 
				myResults.check1_leaked = false; 
			}else if(document.querySelector("#check1_psid").value >= 1){
				myResults.check1_tight = true; 
				myResults.check1_leaked = false; 
			}else{	
				myResults.check1_tight = false; 
				myResults.check1_leaked = true; 
			}
			myResults.check2_psid = document.querySelector("#check2_psid").value;
			if(document.querySelector("#check2_psid").value == null){
				myResults.check2_tight = false; 
				myResults.check2_leaked = false; 
			}else if(document.querySelector("#check2_psid").value >= 1){
				myResults.check2_tight = true; 
				myResults.check2_leaked = false; 
			}else{	
				myResults.check2_tight = false; 
				myResults.check2_leaked = true; 
			}
			myResults.check1_typeII = document.querySelector("#check1_typeII").checked; 
			dispatch(updateDoubleCheck(report.id, myResults) ).then( (data, err)=>{
				console.log("updated double check")
			}) 
	}	

	useEffect(()=>{
		update() 
	}, [watch])
	useEffect(()=>{
		if(currentPadTarget == "#check1_psid" || currentPadTarget == "#check2_psid"){
			setWatch(true)
		}else{
			setWatch(false)
		}
		
		/*if(watch){
			console.log("Updating!!")
			update()
		}*/
	}, [currentPadTarget])

	/*redirects values to html elements*/
	useEffect(()=>{
		if(updatedValue){
			setCheck1(document.querySelector("#check1_psid").value)
			setCheck2(document.querySelector("#check2_psid").value)
			dispatch( resetUpdatedValue())
		}
	}, [updatedValue])

	/*checks if both inputs are valid to be sent to the DB*/

	const redirectKeyboard = (event)=>{
		dispatch( updateTargetElement(event.target.id) ).then((data, err) =>{
			if(err){
				console.log(err)
			}
		})
	}

	return(
		<>
			<Modal show={modalSpinner}>
				<Modal.Body>
					<Spinner />
				</Modal.Body>
			</Modal>
			
					<Form>
						<Row>
							<Col>
								<strong> Double Check </strong>
							
							</Col>
							<Col>
								<Form.Check  
									id="check1_typeII" 
									label = "Type II" 
									checked = {typeII} 
									onChange = { (event)=>{setTypeII(event.target.checked)}} 
									onBlur = { () => update()}
								/>
							</Col>
						</Row>

						<Row  xs = {1} sm ={1} md = {2} lg = {2}>
							<Col>
								<InputGroup>
									<InputGroup.Text>
									#1
									</InputGroup.Text> 
									<Form.Control 
										id="check1_psid"
										required 
										isInvalid = { !hasCheck1}
										isValid = { hasCheck1 }
										readOnly 
										onFocus = { redirectKeyboard }
									/>
									{
												
									check1Tight ? 
										<Badge bg="primary">Tight</Badge>
									:
										<Badge bg="secondary"> Leaked </Badge>

									}		
								</InputGroup>

								<InputGroup>
									<InputGroup.Text>
										#2
									</InputGroup.Text> 

									<Form.Control 
									id = "check2_psid"
									required 
									isInvalid = { !hasCheck2}
									isValid = { hasCheck2 }
									readOnly 
									onFocus = { redirectKeyboard }
									size="sm"

									/>

									{
										
									check2Tight ? 
									<Badge bg="primary">Tight</Badge>
									:
									<Badge bg="secondary"> Leaked </Badge>

									}
								</InputGroup>
							</Col>
							<Col>
								<InputGroup>
									<InputGroup.Text> System </InputGroup.Text>
									<Form.Control  
										id= "system"
										required
										isInvalid = { !hasSystem }
										isValid = { hasSystem}
										readOnly 
										onFocus = { redirectKeyboard }
										size ="sm"
									/>
									<InputGroup.Text> psi </InputGroup.Text>
								</InputGroup>
					
								<InputGroup>
									<InputGroup.Text> DMR </InputGroup.Text>
									<Form.Control 
										size ="sm"
										id = "dmr"
										isInvalid = { !hasDmr }
										isValid = { hasDmr}
										readOnly
										onFocus={ redirectKeyboard }
									/>
								</InputGroup>
							</Col>
						</Row>
						<hr/>
						<Row>
							<InputGroup>
									<InputGroup.Text> Comment </InputGroup.Text>
									<Form.Control 
						
									/>
								</InputGroup>
						</Row>
						
		</Form>
		</>
	)
}