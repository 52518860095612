import {
  GET_INVOICE_ITEMS
} from "../../actions/invoice";

const _nullInvoiceItems = []; 

              
export default (state = _nullInvoiceItems, { type, list }) => {
  Object.freeze(state);
  switch (type) {
    case GET_INVOICE_ITEMS:
      return list;
  case "":
    return _nullInvoiceItems; 
  default:
      return state;
  }
};