import {
  SET_SYSTEM_PSI, CLEAR_VALUES
} from "../../actions/report.js";

const _nullSystemPSI = null
                
export default (state = _nullSystemPSI, { type, psi}) => {
  Object.freeze(state);
  switch (type) {
    case  SET_SYSTEM_PSI:
      return psi;
    case CLEAR_VALUES:
      return _nullSystemPSI; 
    case "":
      return _nullSystemPSI; 
    default:
      return state;
  }
};