import {useSelector, useDispatch} from "react-redux";
import { useState, useEffect, useRef, useCallback} from "react";
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import	Container from 'react-bootstrap/Container';
import	Row from 'react-bootstrap/Row';
import	Spinner from 'react-bootstrap/Spinner';
import	Col from 'react-bootstrap/Col';
import	Button from 'react-bootstrap/Button';
import {  resetPassword } from "../actions/session"

export default function ActivateAccount(){
	const session = useSelector((state) => state.session); 
	const dispatch = useDispatch(); 
	const [pass1, setPass1] = useState(""); 
	const [pass2, setPass2] = useState(""); 
	const [loader, setLoader] = useState(false); 
	const [pass1Valid, setPass1Valid] = useState(false); 
	const [validated, setValidated] = useState(false)
	const [message, setMessage] = useState("")
	useEffect(()=>{
		console.log("resetting password.."); 

	}, [])
	function isValid (){
		console.log(pass1, pass2)
		if(pass1 == pass2) {
			return true
		}else{
			return false
		}
	}

	function updatePassword(event) {
		const form = event.currentTarget;
		setLoader(true)
	    if (form.checkValidity() === false) {
	      event.preventDefault();
	      event.stopPropagation();
	      
	    }else{
	    	event.preventDefault(); 
	    	if(isValid()){
	    		console.log("yay!")
	    		setMessage(""); 
	    		dispatch( resetPassword(pass1) ).then((data, err)=>{
	    			setLoader(false)
	    		})
	    	}else{
	    		setMessage("Passwords don't match")
	    		setLoader(false)
	    		
	    	}
	    }

	    setValidated(true);
	}

	return(
		<>
		<Modal show={loader}>
			<Modal.Body>
					<Spinner />
			</Modal.Body>
		</Modal>
		<Container>	
			<Row>
				<Col>
						<div id="topHolder">
						</div>
				</Col>
			</Row>
			<Row sm ={1} md={1} lg={1} xl={1}>
				<Col>
						<h1> American Backflow & <br/>  Plumbing Services Inc, </h1>
				</Col>
			</Row>
			<Row sm ={1} md={1} lg={1} xl={1}>
				<Col>
						<p> Hello {session.fname} !  Let's activate your account by resetting your password :) </p>
				</Col>
				<Col>

						<p><strong>{message}</strong></p>
				</Col>
				<Col>
					<Form noValidate validated={validated} onSubmit = {(event) => updatePassword(event) }>
						<Form.Label> New Password </Form.Label>
						<Form.Control
							value = {pass1}
							onChange = {(event) => setPass1(event.target.value)} 
							type ="password" 
							isInvalid = {pass1Valid} 
							required
							/>
						<Form.Label> Type Your new password again </Form.Label>
						<Form.Control
							value = { pass2 }
							onChange = { (event)=> {setPass2(event.target.value) }}
							type="password"
							required
							/>
						
						<Button type= "submit"  size="sm"> Update Password </Button>
					</Form>
				</Col>
			</Row>

		</Container>
		</>
	)
}