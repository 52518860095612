import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useState, useEffect, useRef, useCallback} from "react";
import Badge from 'react-bootstrap/Badge';
import FinalDoubleCheck from "./forms/FinalDoubleCheck"
import FinalReducedPressure from "./forms/FinalReducedPressure"; 

import Approved from "./forms/Approved"
import FinalXVBA from "./forms/FinalXVBA"

import AG from "./forms/AG"

import TestOverall from "./forms/TestOverall"
import CommentsRemarks from "./forms/CommentsRemarks"
import {useSelector, useDispatch} from "react-redux";
import NumberPad from "./NumberPad"

import {
		updateAssembly, 
		updateTestType, 
		updateSystem, 
		updateApproved, 
		updateDoubleCheck, 
		updateReducedPressure, 
		updateXVBA, 
		updateAG, 
		updateFDoubleCheck,
		updateFRP, 
		updateFXVBA, 
		updateRemarks, 
		updateRepairs,
		sendTestReportToWaterDistrict
} from "../actions/report"

export default function InitialTestReport(){
	const assemblyType = useSelector((state)=>state.assembly.type); 
	const report = useSelector((state) => state.report);
	const [formToShow, setFormToShow] = useState(<></>)

	useEffect(()=>{
		switch(assemblyType){
			case "DC":
				setFormToShow(<><FinalDoubleCheck /> </>)
				break; 
			case "DCDA": 
				setFormToShow(<><FinalDoubleCheck /> </>)
				break;  
			case "DCDAII": 
				setFormToShow(<><FinalDoubleCheck /> </>)
				break; 
			case "RP": 
				setFormToShow(<><FinalReducedPressure/> </>)
				break; 
			case "RPDA": 
				setFormToShow(<>
						<FinalReducedPressure/>
					</>)
				break;
			case "RPDAII": 
				setFormToShow(<>
						<FinalDoubleCheck />
						<FinalReducedPressure/> 
					</>)
				break;
			case "AVB": 
				setFormToShow(<><FinalXVBA /></>)
				break; 
			case "PVB": 
				setFormToShow(<><FinalXVBA /> </>)
				break;
			case "SVB": 
				setFormToShow(<><FinalXVBA /> </>)
				break;
			case "AG":
				setFormToShow(<><AG /> </>)
				break;
			default:
		}
			

	}, [assemblyType])

	const [testPass, setTestPass] = useState(false)
	return(
		<>
			
			
		
			<Row>
				<Col sm ={5} md ={8}>
					{formToShow}
				</Col>
				<Col>
					<NumberPad />
				</Col>
			</Row>
		
				
		</>

	)
}