import {
  SET_ASSEMBLY_TYPE
} from "../../actions/AlternativeDetails.js";

const _nullAssemblyType = 'DC'
                
export default (state = _nullAssemblyType, { type, ass_type}) => {
  Object.freeze(state);
  switch (type) {
    case SET_ASSEMBLY_TYPE:
      return ass_type;

    case "":
      return _nullAssemblyType; 
    default:
      return state;
  }
};