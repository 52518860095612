import {
  TEST_REPORT
} from "../../actions/Address";

const _nullReport = null; 
             
export default (state = _nullReport, { type, report }) => {
  Object.freeze(state);
  switch (type) {
    case TEST_REPORT:
      return report;
    default:
      return state;
  }
};