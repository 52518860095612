import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { useState, useEffect, useRef, useCallback} from "react";
import Badge from 'react-bootstrap/Badge';
import {useSelector, useDispatch} from "react-redux";
import {
		updateApproved, 
} from "../../actions/report"
import { updateTargetElement, resetUpdatedValue }  from "../../actions/NumberPad"


export default function Approved(){
	const dispatch = useDispatch()
	const report = useSelector((state) => state.report)
	const [assembly, setAssembly] = useState(false); 
	const [installation, setInstallation] = useState(false); 
	const [orientation, setOrientation] = useState(false); 
	const [airgap, setAirgap] = useState(false); 
	const [usc, setUsc] = useState(false); 
	const currentPadTarget = useSelector((state) => state.numberPad)
	const [testReportID, setTestReportID] = useState(123); 

	/*get initial values*/
	useEffect(()=>{
		if(report != null){
			setAssembly(report.approved_assembly); 
			setInstallation(report.approved_installation);
			setOrientation(report.approved_orientation); 
			setAirgap( report.approved_airgap); 
			setUsc( report.approved_USC); 
			document.querySelector("#ap_assembly").checked = report.approved_assembly; 
			document.querySelector("#ap_installation").checked = report.approved_installation; 
			document.querySelector("#ap_orientation").checked = report.approved_orientation; 
			document.querySelector("#ap_airgap").checked = report.approved_airgap;
			document.querySelector("#ap_usc").checked = report.approved_USC;  
		}
	},[report] )


	function update(){
		let myobj = {}; 
		myobj.approved_assembly = document.querySelector("#ap_assembly").checked; 
		myobj.approved_installation = document.querySelector("#ap_installation").checked; 
		myobj.approved_orientation = document.querySelector("#ap_orientation").checked; 
		myobj.approved_airgap = document.querySelector("#ap_airgap").checked; 
		myobj.approved_usc = document.querySelector("#ap_usc").checked
	
		dispatch(updateApproved(report.id, myobj)).then((data, err)=>{
			console.log("Updated approved")
		})
	}

	useEffect(()=>{
		update()
	}, [assembly, installation, orientation, airgap, usc])



	return(
		<Container>
			<Row>
				<strong> Approved: </strong>	
			</Row>
			<Row xs ={1} sm={1} md={1} lg={1}>

				
					<Col>
						<Form.Check 
							id="ap_assembly"
							label ="Assembly"
							checked = {assembly}
							onChange = {(event) => setAssembly(event.target.checked)}
						/> 
					</Col>
					<Col>
						<Form.Check 
							id="ap_installation"
							label = "Installation" 
							checked = {installation}
							onChange = {(event) => { 
								setInstallation(event.target.checked)
							}}						
						/>
					</Col>

					<Col>
						<Form.Check 
							id = "ap_orientation"
							label = "Orientation" 
							checked = {orientation}
							onChange = {(event) => setOrientation(event.target.checked)}
							
							
						/>
					</Col>
					<Col>
						<Form.Check 
							id="ap_airgap"
							label = "Airgap" 
							checked = {airgap}
							onChange = {(event) => setAirgap(event.target.checked)}
							
						
						/>
					</Col>
					<Col>
						<Form.Check 
							id="ap_usc"
							label = "USC" 
							checked = {usc}
							onChange = {(event) => setUsc(event.target.checked)}
						
							
						/>
					</Col>
			</Row>
			
		</Container>
	)

}