import {
  RECEIVE_BILLING_ADDRESS
} from "../../actions/invoice";

const _nullBillingAddress = null; 
                
export default (state = _nullBillingAddress, { type, address }) => {
  Object.freeze(state);
  switch (type) {
    case RECEIVE_BILLING_ADDRESS:
      return address; 
    case "":
      return _nullBillingAddress; 
    default:
      return state;
  }
};