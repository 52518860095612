import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Table from 'react-bootstrap/Table'
import { useState, useEffect, useRef, useCallback} from "react";
import {useSelector, useDispatch} from "react-redux";

export default function BillingCard(){
	const billingAddress = useSelector((state) => state.billingAddress); 
	const [billingName, setBillingName] = useState(""); 
	const [street, setStreet] = useState(""); 
	const [city, setCity] = useState(""); 
	const [state, setState] = useState(""); 
	const [zipcode, setZipcode] = useState(""); 

	useEffect( ()=>{
		if(billingAddress != null){
			setStreet(billingAddress.street); 
			setCity(billingAddress.city); 
			setState(billingAddress.state); 
			setZipcode(billingAddress.zipcode)
			setBillingName(billingAddress.name)
		}

	}, [billingAddress])
	return(
		<> 
			<strong> BILLING TO: </strong>
			<p>
					{billingName} <br/>
					{street} <br/>
					{city}, {state}
					<br/>
					{zipcode}
			</p>
		</>
	)
}