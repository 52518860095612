import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Badge from 'react-bootstrap/Badge';
import { useState, useEffect, useRef, useCallback} from "react";
import { updateTestType } from "../actions/report"
import {useSelector, useDispatch} from "react-redux";

export default function TestType() {
	const [status, setStatus] = useState("E"); 
	const [hasStatus, setHasStatus] = useState(false)
	const dispatch = useDispatch(); 
	const report = useSelector((state) => state.report ); 

	useEffect(()=>{
		if(report != null){
			setStatus(report.serviceType)
		}
	}, [report])

	useEffect(()=>{
		update()
	}, [status])


	useEffect( () =>{
		if(status == null || status == ""){
			setHasStatus(false)
		}else{
			setHasStatus(true)
		}

	}, [status])

	function update(){
		let testType = {}; 
		testType.type = document.querySelector("#testType").value;
		dispatch(updateTestType( report.id, testType)).then((data, err)=>{
			console.log("updated test type")
		})
	}
	return(
		<Container>
			<Row>
				<Col xs sm md lg ={7} >
					<Form.Group>
						<strong>Test Type</strong>
						<Form.Select
							type="text"
							id="testType"
							value = {status}
							onChange = { (event) => setStatus(event.target.value) }
							size="sm"
							required
							isInvalid={!hasStatus}
							isValid  = { hasStatus }
						>
							<option value = "E"> EXISTING</option>
							<option value = "RM"> REMOVED </option>
							<option value = "NEW"> NEW </option>
							<option value = "REPAIR"> REPAIRED </option>
							<option value = "REPLACED"> REPLACED </option>
						</Form.Select>

				</Form.Group>
			</Col>
			<Col>
			
			</Col>
		</Row>
	</Container>
	)
}