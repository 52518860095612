export const login = async (credentials) =>(
	fetch("backflow/session", {
		credentials: 'include', 
		method: "POST", 
		body: JSON.stringify(credentials), 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
);

export const logout = async () =>(
	fetch("backflow/session", {
		method: "DELETE"
	}) 
);

export const getTests = async (date) => (
	fetch("backflow/tests?" + new URLSearchParams({
    	date: date
	}), {
		method: "GET"
	})
); 


export const resetActivatePassword = (password) => (
	fetch("backflow/session/updatePassword" , {
		method: "POST", 
		body: JSON.stringify({ password: password} ), 
		headers: {
            "Content-Type" : "application/json"
        }
	})
)




export const getInvoice = async (invoiceID) => (
	fetch("backflow/invoice?" + new URLSearchParams({
		invoiceID:  invoiceID
	}), {
		method: "GET"
	})
); 


export const getTestReport = async (testReportID) => (
	fetch("backflow/testReport?" + new URLSearchParams({
		testReportID:  testReportID
	}), {
		method: "GET"
	})
); 

export const getTestReportRepair = async (testReportID) => (
	fetch("backflow/testReport/repair?" + new URLSearchParams({
		testReportID:  testReportID
	}), {
		method: "GET"
	})
); 

export const updateTestReport = async (obj) =>(
	fetch("backflow/testReport", {
		credentials: 'include', 
		method: "POST", 
		body: JSON.stringify(obj), 
		headers: {
            "Content-Type" : "application/json"
        }

	})
);

export const submitTestReportRepair = async (obj) =>(
	fetch("backflow/testReport/updateRepair", {
		credentials: 'include', 
		method: "POST", 
		body: JSON.stringify(obj), 
		headers: {
            "Content-Type" : "application/json"
        }

	})
)
export const updateAssembly = async ( obj ) =>(
	fetch("backflow/assembly", {
		method: "POST", 
		body: JSON.stringify(obj), 
		headers: {
            "Content-Type" : "application/json"
        }
	})
)


export const cancelTest = (obj) =>(
	fetch("backflow/testReport/cancel", {
		method: "POST", 
		body: JSON.stringify(obj), 
		headers: {
            "Content-Type" : "application/json"
        }
	})

)




export const sendConfinedEntry = (obj) => (
	fetch("backflow/confinedEntry", {
		method: "POST", 
		body: JSON.stringify(obj), 
		headers: {
            "Content-Type" : "application/json"
        }
	})
)

export const updateConfinedEntry = (obj) => (
	fetch("backflow/confinedEntry/update" , {
		method: "POST", 
		body: JSON.stringify(obj), 
		headers:{
			"Content-Type" : "application/json"
		}
	})
)

export const addAssembly = (obj) => (
	fetch("backflow/assembly/create", {
		method: "POST", 
		body: JSON.stringify(obj), 
		headers: {
            "Content-Type" : "application/json"
        }
	})
)


export const addItem = (obj) => (
	fetch("backflow/invoice/addItem", {
		method: "POST", 
		body: JSON.stringify(obj), 
		headers: {
            "Content-Type" : "application/json"
        }
	})
)

export const removeItem = (obj) => (
	fetch("backflow/invoice/removeItem", {
		method: "POST", 
		body: JSON.stringify(obj), 
		headers: {
			"Content-Type" : "application/json"
		}
	})
)

export const removeConfinedEntry = (obj) => (
	fetch("backflow/confinedEntry/remove", {
		method: "POST", 
		body: JSON.stringify(obj), 
		headers: {
            "Content-Type" : "application/json"
        }
	})
)

export const addNote = (obj) => (
	fetch("backflow/invoice/addNote", {
		method: "POST", 
		body: JSON.stringify(obj), 
		headers: {
			"Content-Type" : "application/json"
		} 
	})
)

export const removeNote = (obj) =>(
	fetch("backflow/invoice/removeNote", {
		method: "POST", 
		body: JSON.stringify(obj), 
		headers: {
			"Content-Type" : "application/json"
		} 
	})
)
export const sendToOffice = (obj) =>(
	fetch("backflow/invoice/sendToOffice", {
		method: "POST", 
		body: JSON.stringify(obj), 
		headers: {
			"Content-Type" : "application/json"
		} 
	})
)

export const getCodedRoute = () => (
	fetch("backflow/google", {
		method: "POST", 
		headers: {
			"Content-Type" : "application/json"
		} 
	})
)


export const getCompanyItems = () => (
	fetch("backflow/invoice/companyItems", {
		method: "GET", 
		headers: {
			"Content-Type": 'application/json'
		}
	})
)


