import {
  SET_REPORT_ID, CLEAR_REPORT_ID
} from "../../actions/report.js";

const _nullReportID = null; 
                
export default (state = _nullReportID, { type, id}) => {
  Object.freeze(state);
  switch (type) {
    case SET_REPORT_ID:
      return id;
    case CLEAR_REPORT_ID: 
      return _nullReportID
    case "":
      return _nullReportID; 
    default:
      return state;
  }
};