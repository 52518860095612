import {
  GET_MESSAGE,
  CLEAR_MESSAGE
} from "../../actions/testReport";

const _nullMessage = { message: null}

                 
export default (state = _nullMessage, { type, message }) => {
  Object.freeze(state);
  switch (type) {
    case GET_MESSAGE:
      return message;
    case CLEAR_MESSAGE: 
      return _nullMessage; 
    default:
      return state;
  }
};