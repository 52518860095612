import {
  VALUE_UPDATED
} from "../../actions/NumberPad.js";

const _nullUpdated = false
                
export default (state = _nullUpdated, { type, boolVal }) => {
  Object.freeze(state);
  switch (type) {
    case VALUE_UPDATED:
      return boolVal;
    case "":
      return _nullUpdated; 
    default:
      return state;
  }
};