/*plumbing*/
export const requestPlumbingList = (dateSelected) => (
	fetch("backflow/stops/list/plumbing?" + new URLSearchParams({
    	date: dateSelected
	}), {
		method: "GET", 	
		
	})
)

/*Assembly object*/
export const getAssembly = (assemblyID) => (
	fetch("backflow/assembly?"+ new URLSearchParams({
    	id: assemblyID
	}), {
		method: "GET", 	
	
	})
); 
export const deleteAssembly = (assemblyID) => (
	fetch("backflow/assembly?"+ new URLSearchParams({
    	id: assemblyID
	}), {
		method: "DELETE"
	})
); 
export const updateSerial = (obj) => (
	fetch("backflow/assembly/serial", {
		method: "PUT", 	
		body: JSON.stringify(obj), 
		headers: {
            "Content-Type" : "application/json"
        }
	})
); 
export const updateLocation = (obj) => (
	fetch("backflow/assembly/location", {
		method: "PUT", 	
		body: JSON.stringify(obj), 
		headers: {
            "Content-Type" : "application/json"
        }
	})
); 
export const updateMake = (obj) => (
	fetch("backflow/assembly/make", {
		method: "PUT", 	
		body: JSON.stringify(obj), 
		headers: {
            "Content-Type" : "application/json"
        }
	})
); 
export const updateModel = (obj) => (
	fetch("backflow/assembly/model", {
		method: "PUT", 	
		body: JSON.stringify(obj), 
		headers: {
            "Content-Type" : "application/json"
        }
	})
); 
export const updateSize = (obj) => (
	fetch("backflow/assembly/size", {
		method: "PUT", 	
		body: JSON.stringify(obj), 
		headers: {
            "Content-Type" : "application/json"
        }
	})
); 
export const updateHazard = (obj) => (
	fetch("backflow/assembly/hazard", {
		method: "PUT", 	
		body: JSON.stringify(obj), 
		headers: {
            "Content-Type" : "application/json"
        }
	})
); 
export const updateType = (obj) => (
	fetch("backflow/assembly/type", {
		method: "PUT", 	
		body: JSON.stringify(obj), 
		headers: {
            "Content-Type" : "application/json"
        }
	})
); 
export const createAssembly = (addressID, stopID) => (
	fetch("backflow/assembly", {
		method: "POST", 	
		body: JSON.stringify({addressID: addressID, stopID: stopID}), 
		headers: {
            "Content-Type" : "application/json"
        }
	})
); 

export const updateStatus = (obj) => (
	fetch("backflow/assembly/status", {
		method: "PUT", 	
		body: JSON.stringify(obj), 
		headers: {
            "Content-Type" : "application/json"
        }
	})
); 

export const updateAssembly = (assemblyID, obj) =>(
	fetch("backflow/assembly", {
		method: "PUT", 	
		body: JSON.stringify({ assemblyID: assemblyID, obj: obj}), 
		headers: {
            "Content-Type" : "application/json"
        }
	})
)


/*stops list*/
export const requestStopList = (dateSelected) => (
	fetch("backflow/stops/list?" + new URLSearchParams({
    	date: dateSelected
	}), {
		method: "GET", 	
		
	})
); 

/*Address details */
export const requestAddressDetails = (addressID) => (
	fetch("backflow/address?" + new URLSearchParams({
    	id: addressID
	}), {
		method: "GET"
	})
); 

export const requestCustomerDetails = (customerID) => (
	fetch("backflow/customer?" + new URLSearchParams({
    	id: customerID
	}), {
		method: "GET"
	})
);
export const requestAssemblies = (addressID, date) => (
	fetch("backflow/stops/assemblies?" + new URLSearchParams({
    	addressID: addressID,
    	date: date
	}), {
		method: "GET"
	})
);

export const requestServices = (stopID) => (
	fetch("backflow/stops/services?" + new URLSearchParams({
    	stopID: stopID
	}), {
		method: "GET"
	})
)

export const requestReport = (reportID)=> (
	fetch("backflow/testReport?" + new URLSearchParams({
    	id: reportID
	}), {
		method: "GET"
	})
)





/* test report object */
export const notTested = (obj) => (
	fetch("backflow/testReport/notTested", {
		method: "PUT", 	
		body: JSON.stringify(obj), 
		headers: {
            "Content-Type" : "application/json"
        }
	})
)

export const sendToWaterDistrict = (obj) =>  (
	fetch("backflow/testReport/sendToWaterDistrict", {
		method: "PUT", 	
		body: JSON.stringify(obj), 
		headers: {
            "Content-Type" : "application/json"
        }
	})
); 

export const updateTestType = (obj) => (
	fetch("backflow/testReport/testType", {
		method: "PUT", 	
		body: JSON.stringify(obj), 
		headers: {
            "Content-Type" : "application/json"
        }
	})
); 




export const updateDoubleCheck = (obj) => (
	fetch("backflow/testReport/DC", {
		method: "PUT", 	
		body: JSON.stringify(obj), 
		headers: {
            "Content-Type" : "application/json"
        }
	})
);

export const updateReducedPressure = (obj) => (
	fetch("backflow/testReport/RP", {
		method: "PUT", 	
		body: JSON.stringify(obj), 
		headers: {
            "Content-Type" : "application/json"
        }
	})
);


export const updateAG= (obj) => (
	fetch("backflow/testReport/AG", {
		method: "PUT", 	
		body: JSON.stringify(obj), 
		headers: {
            "Content-Type" : "application/json"
        }
	})
);

export const updateXVBA = (obj) => (
	fetch("backflow/testReport/XVBA", {
		method: "PUT", 	
		body: JSON.stringify(obj), 
		headers: {
            "Content-Type" : "application/json"
        }
	})
);

export const updateFinalDC = (obj) => (
	fetch("backflow/testReport/finalDC", {
		method: "PUT", 	
		body: JSON.stringify(obj), 
		headers: {
            "Content-Type" : "application/json"
        }
	})
);
export const updateFinalRP = (obj) => (
	fetch("backflow/testReport/finalRP", {
		method: "PUT", 	
		body: JSON.stringify(obj), 
		headers: {
            "Content-Type" : "application/json"
        }
	})
);

export const updateFinalXVBA = (obj) => (
	fetch("backflow/testReport/finalXVBA", {
		method: "PUT", 	
		body: JSON.stringify(obj), 
		headers: {
            "Content-Type" : "application/json"
        }
	})
);
export const updateSystem = (obj) => (
	fetch("backflow/testReport/system", {
		method: "PUT", 	
		body: JSON.stringify(obj), 
		headers: {
            "Content-Type" : "application/json"
        }
	})
);
export const updateApproved = (obj) => (
	fetch("backflow/testReport/approved", {
		method: "PUT", 	
		body: JSON.stringify(obj), 
		headers: {
            "Content-Type" : "application/json"
        }
	})
);

export const updateRemarks = (obj) => (
	fetch("backflow/testReport/remarks", {
		method: "PUT", 	
		body: JSON.stringify(obj), 
		headers: {
            "Content-Type" : "application/json"
        }
	})
);
export const updateRepairs = (obj) => (
	fetch("backflow/testReport/repairs", {
		method: "PUT", 	
		body: JSON.stringify(obj), 
		headers: {
            "Content-Type" : "application/json"
        }
	})
);
/* invoice object */
export const getInvoice = async (invoiceID) => (
	fetch("backflow/invoice?" + new URLSearchParams({
		invoiceID:  invoiceID
	}), {
		method: "GET"
	})
); 
export const requestInvoiceStatus = (invoiceID) => (
	fetch("backflow/invoice/status?" + new URLSearchParams({
		invoiceID:  invoiceID
	}), {
		method: "GET"
	})
);

export const getBillingAddress =  async (addressID) =>(
	fetch("backflow/invoice/billingAddress?" + new URLSearchParams({
		addressID:  addressID
	}), {
		method: "GET"
	})
)

export const sendToOffice = (obj) =>(
	fetch("backflow/invoice/sendToOffice", {
		method: "POST", 
		body: JSON.stringify(obj), 
		headers: {
			"Content-Type" : "application/json"
		} 
	})
)
export const voidInvoice = (obj) =>(
	fetch("backflow/invoice/void", {
		method: "POST", 
		body: JSON.stringify(obj), 
		headers: {
			"Content-Type" : "application/json"
		} 
	})
)

/* Line item object*/
export const getInvoiceItems = async (invoiceID) => (
	fetch("backflow/invoiceItems?" + new URLSearchParams({
		invoiceID:  invoiceID
	}), {
		method: "GET"
	})
); 

export const deleteInvoiceItem = async (itemID) => (
	fetch("backflow/invoiceItems?" + new URLSearchParams({
		itemID:  itemID
	}), {
		method: "DELETE"
	})
)

export const addItem = (obj) => (
	fetch("backflow/invoiceItems", {
		method: "PUT", 
		body: JSON.stringify(obj), 
		headers: {
            "Content-Type" : "application/json"
        }
	})
)
/* Confined entry object*/

export const requestConfinedEntries = async (addressID) => (
	fetch("backflow/confinedEntry/list?" + new URLSearchParams({
		addressID:  addressID
	}), {
		method: "GET"
	})
); 
export const requestConfinedEntry = async (entryID) => (
	fetch("backflow/confinedEntry?" + new URLSearchParams({
		entryID:  entryID
	}), {
		method: "GET"
	})
);

export const createConfinedEntry =  async (addressID) =>(
	fetch("backflow/confinedEntry", {
		method: "POST", 
		body: JSON.stringify( { addressID : addressID}), 
		headers: {
			"Content-Type" : "application/json"
		} 
	})
)
export const updateConfinedDetails = async (obj) =>(
	fetch("backflow/confinedEntry/details", {
		method: "PUT", 
		body: JSON.stringify(obj), 
		headers: {
            "Content-Type" : "application/json"
        }
	})
)
export const updateConfinedReadings = async (obj) =>(
	fetch("backflow/confinedEntry/readings", {
		method: "PUT", 
		body: JSON.stringify(obj), 
		headers: {
            "Content-Type" : "application/json"
        }
	})
)


export const updateConfinedHazards = async (obj) =>(
	fetch("backflow/confinedEntry/hazards", {
		method: "PUT", 
		body: JSON.stringify(obj), 
		headers: {
            "Content-Type" : "application/json"
        }
	})
)

export const deleteConfinedEntry = async( entryID, addressID) => (
	fetch("backflow/confinedEntry?" + new URLSearchParams({
		entryID : entryID,
		addressID: addressID
	}), {
		method: "DELETE"
	})

)

/*Company object*/
export const requestTesterList  = () =>(
	fetch("backflow/company/testers" , {
		method: "GET"
	})
)