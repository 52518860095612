import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {useSelector, useDispatch} from "react-redux";
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useState, useEffect, useRef, useCallback} from "react";
import DispatchApp from "../AlternativeModules/DispatchApp"; 

import { logout, getTestList } from "../actions/session"; 

export default function AlternativeLanding(){
	const [expand, setExpand] = useState(false); 
  const [isOpen, setIsOpen] = useState(false); 
	const [displayApp, setDisplayApp] = useState(<DispatchApp/>)
  const dispatch = useDispatch(); 

	function handleSelect(event){
		switch(event.toUpperCase()){
			case "HOME": 
				setDisplayApp(<DispatchApp />)
				break; 
			case "DISPATCH": 
				setDisplayApp(<DispatchApp />)
				break;
   		default: 
				setDisplayApp(<DispatchApp />)
				break;
		}
	}
	return (
    <Container fluid>
      <Row>
        <Navbar key={expand} expand={expand}  bg="dark" data-bs-theme="dark">
          <Container >
            <Navbar.Brand >American Backflow & Plumbing Services, Inc. </Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />

            <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-${expand}`}
                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  Menu
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav variant="pills" activeKey="1" onSelect={handleSelect}>
                  {/*define all the tabs available to field*/}
                  <Nav.Link eventKey="DISPATCH">Daily Dispatch</Nav.Link>
    

                  {/*Log out button*/}
                  <Button variant="secondary" onClick = {()=> dispatch( logout())  }> Log  out </Button>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      </Row>
      <Row>
        <Col>
          {displayApp}
        </Col>
      </Row>
    </Container>
  );
}