import * as apiUtil from "../util/server";

import * as server from "../util/alternativeServer"

import { RECEIVE_CURRENT_USER} from "./session.js";
import { GET_MESSAGE } from "./testReport.js"; 

export const RECEIVE_LINE_ITEMS = "RECEIVE_LINE_ITEMS"; 
export const NO_LIST_ITEMS = "NO_LIST_ITEMS"
export const RECEIVE_BILLING_ADDRESS = "RECEIVE_BILLING_ADDRESS"; 

export const RECEIVE_INVOICE = "RECEIVE_INVOICE"; 
export const FAILED_INVOICE = "FAILED_INVOICE"; 
export const CLEAR_INVOICE = "CLEAR_INVOICE";
export const GET_INVOICE_ITEMS = "GET_INVOICE_ITEMS"; 


const receiveInvoiceItems = (list) => ({
	type: GET_INVOICE_ITEMS, 
	list
})
const receiveLineItems = (itemlist) => ({
	type: RECEIVE_LINE_ITEMS,
	itemlist
})

const receiveBillingAddress = (address) => ({
	type: RECEIVE_BILLING_ADDRESS, 
	address
})

const noItemList = () => ({
	type: NO_LIST_ITEMS
})


const receiveInvoice = (invoice)=>({
	type: RECEIVE_INVOICE, 
	invoice
})

const failedInvoice = () =>({
	type: FAILED_INVOICE
}) 
const clearInvoice = () =>({
	type: CLEAR_INVOICE
})

const receiveUser = user =>({
	type: RECEIVE_CURRENT_USER, 
	user
})

const getErrorMessage= message => ({
	type: GET_MESSAGE, 
	message
})

export const getInvoiceInformation = (invoiceID) => async dispatch =>{
	return new Promise( async(resolve, reject) =>{
		const response = await server.getInvoice(invoiceID); 
		const data = await response.json(); 
		if(response.ok){
			
			dispatch(receiveInvoice(JSON.parse(data).invoice) ); 
			resolve();
			
		}else{
			dispatch(failedInvoice()); 
			resolve()
		}
	})
} 


export const requestInvoiceItems = (invoiceID) => dispatch =>{
	return new Promise ( async(resolve, reject) =>{
		const response = await server.getInvoiceItems(invoiceID); 
		const data = await response.json(); 
		if(response.ok){

			dispatch(receiveInvoiceItems( JSON.parse(data).list  ) ); 
			resolve();
		}else{
			dispatch(failedInvoice()); 
			resolve()
		}
	})	
}

export const requestBillingAddress = (addressID) => dispatch =>{
	return new Promise ( async(resolve, reject) =>{
		const response = await server.getBillingAddress(addressID); 
		const data = await response.json(); 
		if(response.ok){

			dispatch(receiveBillingAddress( JSON.parse(data).address  ) ); 
			resolve();
		}else{
			dispatch(failedInvoice()); 
			resolve()
		}
	})		
}


export const deleteInvoiceItem = (itemID) => dispatch =>{
	return new Promise ( async(resolve, reject) =>{
		const response = await server.deleteInvoiceItem(itemID); 
		const data = await response.json(); 
		if(response.ok){
			dispatch(receiveInvoiceItems( JSON.parse(data).list  ) ); 
			resolve();
		}else{
			dispatch(failedInvoice()); 
			resolve()
		}
	})	
}


export const addItem = (invoiceID, itemObj, billingID, customerID) => async dispatch =>{
	return new Promise( async(resolve, reject) =>{
		let obj = {
			invoiceID: invoiceID, 
			itemObj: itemObj, 
			billingAddressID: billingID, 
			customerID: customerID
		}
		const response = await server.addItem(obj); 
		const data = await response.json(); 

		if(response.ok){
		
			dispatch( receiveInvoiceItems( JSON.parse(data).list  ) )
			resolve(true)
			
		}
	})
}

export const sendInvoiceToOffice = (invoiceID) => async dispatch =>{
	return new Promise ( async (resolve, reject) =>{
		let obj = {}; 
		obj.invoiceID = invoiceID;  
		const response = await server.sendToOffice(obj); 
		const data = await response.json(); 

		if(response.ok){
			dispatch( receiveInvoice(JSON.parse(data).invoice)); 
			resolve()
		}	
	})
}

export const voidInvoice = ( invoiceID) => async dispatch => {
	return new Promise ( async (resolve, reject) =>{
		let obj = {}; 
		obj.invoiceID = invoiceID;  
		const response = await server.voidInvoice(obj); 
		const data = await response.json(); 
		if(response.ok){
			dispatch( receiveInvoice(JSON.parse(data).invoice)); 
			resolve()
		}	
	})
}

















export const clearInvoiceInformation = () => async dispatch =>{
	return dispatch(clearInvoice()); 
}



export const removeItem = (itemID)=> async dispatch => {
	return new Promise( async (resolve, reject) =>{
		let obj = { itemID: itemID}
		const response = await apiUtil.removeItem(obj); 
		const data = await response.json(); 

		if(response.ok){
			if(JSON.parse(data).success){
				dispatch( receiveInvoice(JSON.parse(data).invoice)); 
				dispatch(receiveUser(JSON.parse(data).session)); 
				resolve(true)
			}else{
				let errObj = { message:JSON.parse(data).error.message }
				dispatch( getErrorMessage(errObj )); 
				resolve(false)
			}
		}else{
			let errObj = { message:"Something wrong. Please try again later" }
			dispatch( getErrorMessage(errObj)); 	
			resolve(false); 
		}
	})
}


export const addNote = (obj) => async dispatch =>{
	return new Promise ( async (resolve, reject) =>{
		const response = await apiUtil.addNote(obj); 
		const data = await response.json(); 

		if(response.ok){
			if(JSON.parse(data).success){
				dispatch( receiveInvoice(JSON.parse(data).invoice)); 
				dispatch(receiveUser(JSON.parse(data).session)); 
				resolve(true)
			}else{
				let errObj = { message:JSON.parse(data).error.message }
				dispatch( getErrorMessage(errObj )); 
				resolve(false)
			}
		}else{
			let errObj = { message:"Something wrong. Please try again later" }
			dispatch( getErrorMessage(errObj)); 	
			resolve(false); 
		}	
	})
}

export const removeInvoiceNote = (noteID, invoiceID) => async dispatch =>{
	return new Promise ( async (resolve, reject) =>{
		let obj = {}; 
		obj.noteID = noteID;
		obj.invoiceID = invoiceID;  
		const response = await apiUtil.removeNote(obj); 
		const data = await response.json(); 

		if(response.ok){
			if(JSON.parse(data).success){
				dispatch( receiveInvoice(JSON.parse(data).invoice)); 
				dispatch(receiveUser(JSON.parse(data).session)); 
				resolve(true)
			}else{
				let errObj = { message:JSON.parse(data).error.message }
				dispatch( getErrorMessage(errObj )); 
				resolve(false)
			}
		}else{
			let errObj = { message:"Something wrong. Please try again later" }
			dispatch( getErrorMessage(errObj)); 	
			resolve(false); 
		}	
	})
}

/*export const sendInvoiceToOffice = (invoiceID, total) => async dispatch =>{
	return new Promise ( async (resolve, reject) =>{
		let obj = {}; 
		obj.invoiceID = invoiceID;  
		obj.total = total; 
		const response = await apiUtil.sendToOffice(obj); 
		const data = await response.json(); 

		if(response.ok){
			if(JSON.parse(data).success){
				dispatch( receiveInvoice(JSON.parse(data).invoice)); 
				dispatch(receiveUser(JSON.parse(data).session)); 
				resolve(true)
			}else{
				let errObj = { message:JSON.parse(data).error.message }
				dispatch( getErrorMessage(errObj )); 
				resolve(false)
			}
		}else{
			let errObj = { message:"Something wrong. Please try again later" }
			dispatch( getErrorMessage(errObj)); 	
			resolve(false); 
		}	
	})
}
*/


export const requestLineItemList = ()=> dispatch =>{
	return new  Promise( async(resolve, reject) =>{
		console.log("Requesting the line items for this company..."); 
		const response = await apiUtil.getCompanyItems(); 
		const data  = await response.json(); 

		if(response.ok){
			let obj = {list: JSON.parse(data).itemlist }; 
			dispatch( receiveLineItems(obj) ); 
			resolve(); 
		}else{
			dispatch( noItemList() ); 
			resolve(); 
		}

	})

}
