import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Table from 'react-bootstrap/Table'
import { useState, useEffect, useRef, useCallback} from "react";
import {useSelector, useDispatch} from "react-redux";
import { format } from 'date-fns'

export default function InvoiceInformation(){
	const invoiceID = useSelector((state) =>state.invoice); 
	const [currentDate, setCurrentDate] = useState(new Date())
	useEffect( ()=>{
		console.log("getting a new invoice information... ", )
	}, [invoiceID])
	return(
			<>
				<p>	
					<strong> Service Date </strong> <br/>
					 { format( currentDate, "MM/dd/yyyy" ) }<br/>
				
				</p>	
			</>
	)
}