import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useState, useEffect, useRef, useCallback} from "react";
import Badge from 'react-bootstrap/Badge';
import { updateTargetElement, resetUpdatedValue }  from "../../actions/NumberPad"
import {useSelector, useDispatch} from "react-redux";
import {
		updateAssembly, 
		updateTestType, 
		updateSystem, 
		updateApproved, 
		updateDoubleCheck, 
		updateReducedPressure, 
		updateXVBA, 
		updateAG, 
		updateFDoubleCheck,
		updateFRP, 
		updateFXVBA, 
		updateRemarks, 
		updateRepairs,
		sendTestReportToWaterDistrict
} from "../../actions/report"


export default function FinalReducedPressure(){
	const [systemPass, setSystemPass] = useState(null)
	const [systemFail, setSystemFail] = useState(null); 


	/*variables to redirect to the number pad*/
	const dispatch = useDispatch()
	const currentPadTarget = useSelector((state) => state.numberPad)
	const updatedValue = useSelector((state) => state.updatedVal)
	const report = useSelector( (state) => state.report); 
	/*value variables*/
	const [final_check1, setFinalCheck1] = useState(null); 
	const [final_openedAt, setFinalOpenedAt]=useState(null); 
	const [final_pass, setFinalPass] = useState(false)

	/*validation variables*/
	const [hasFinalCheck1, setHasFinalCheck1] = useState(false); 
	const [hasFinalOpenedAt, setHasFinalOpenedAt] = useState(false)

	/*check for pass or fail */
	useEffect(()=>{
		if(final_check1 >=5 && final_openedAt >=2){
			setFinalPass(true)
		}else{
			setFinalPass(false)
		}
	}, [final_check1, final_openedAt])

	/*checks if values are valid*/
	useEffect(()=>{
		if(final_check1 == null || final_check1==0){
			setHasFinalCheck1(false)
		}else{
			setHasFinalCheck1(true)
		}

		if(final_openedAt == null || final_openedAt==0){
			setHasFinalOpenedAt(false)
		}else{
			setHasFinalOpenedAt(true)
		}
	}, [final_check1, final_openedAt])

	/*initial values*/
	useEffect(()=>{
		if(report != null){
			setSystemPass(report.repair_pass)
			setSystemFail(report.repair_fail)
			setFinalCheck1(report.repair_rpa_pressdrop); 
			setFinalOpenedAt(report.repair_rpa_openedat); 
			document.querySelector("#final_check1_psid").value = report.repair_rpa_pressdrop
			document.querySelector("#final_openedAt").value = report.repair_rpa_openedat
			document.querySelector("#finalPass").checked = report.repair_pass
		
		}
	},[report] )

	const update = () => {
		let myobj = {}
		myobj.pass = document.querySelector("#finalPass").checked;
		myobj.fail = document.querySelector("#finalFail").checked;  
		myobj.opened_at = document.querySelector("#final_openedAt").value; 
		myobj.pressdrop = document.querySelector("#final_check1_psid").value; 
		if(document.querySelector("#final_openedAt").value == null || document.querySelector("#final_check1_psid").value == null){
			myobj.relief_pass = false; 
			myobj.relief_fail = false; 
		}else if(document.querySelector("#final_openedAt").value >= 2 && document.querySelector("#final_check1_psid").value >= 5) {
			myobj.relief_pass = true; 
			myobj.relief_fail = false; 
		}else{	
			myobj.relief_pass = false; 
			myobj.relief_fail = true; 
		}

		dispatch( updateFRP(report.id, myobj) ).then((data, err)=>{
			console.log("updated final reduced pressure")
		})
	}	

	/*redirects values to html elements*/
	useEffect(()=>{
		if(updatedValue){
			setFinalCheck1(document.querySelector("#final_check1_psid").value)
			setFinalOpenedAt(document.querySelector("#final_openedAt").value)
			dispatch( resetUpdatedValue())
		}
	}, [updatedValue])

	const [watch, setWatch] = useState(false)
	useEffect(()=>{
		if(currentPadTarget == "#final_check1_psid" || currentPadTarget == "#final_openedAt"){
			setWatch(true)
		}else{
			setWatch(false)
		}
		
		if(watch){
			update()
		}
	}, [currentPadTarget])


	const redirectKeyboard = (event)=>{
		dispatch( updateTargetElement(event.target.id) ).then((data, err) =>{
			if(err){
				console.log(err)
			}
		})
	}

	return(
		<>
			<Row>
				<Col>
					<Form.Check 
						id ="finalPass"  
						label="Pass" 
						checked = {systemPass} 
						onChange={ 
							(event) => {
								if(event.target.checked){
									setSystemFail(false)

								}
								setSystemPass(event.target.checked)
							}
						}

						onBlur = {
							() => update()
						}
					/>
					</Col>
					<Col>
					<Form.Check id = "finalFail" label="Fail"   checked = {systemFail} 
					onChange={ 
						(event) => {
							if(event.target.checked){
								setSystemPass(false)
							}
							setSystemFail(event.target.checked)
						}
					}
					onBlur = {
							() => update()
						}
				/>
				</Col>
			</Row>

			<Row>
				<Col>
					<strong>Relief Valve <br/></strong>
				</Col>
				<Col>		
					{
						
					final_pass ? 
						<Badge bg="primary">Passed</Badge>
					:
						<Badge bg="secondary"> Failed </Badge>

					}
			
				</Col>
			</Row>
			<Row>
				<Form.Label>Check #1 psdi/ Pressdrop </Form.Label>
				<Col>
					<InputGroup>
					
					<Form.Control 
						id="final_check1_psid"
						required
						isInvalid = { ! hasFinalCheck1} 
						isValid = { hasFinalCheck1 }
						readOnly 
						onFocus = { redirectKeyboard}
					/>
					
				</InputGroup>
				<Form.Label>Opened At: </Form.Label>
				<InputGroup>
					
					<Form.Control 
						id="final_openedAt"
						required
						isInvalid = { !hasFinalOpenedAt}
						isValid= { hasFinalOpenedAt}
						readOnly 
						onFocus = { redirectKeyboard}
					/>
					
				</InputGroup>
				</Col>
			</Row>
		</>
	)
}